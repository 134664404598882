
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ActivityFeed', function () {

		/**
		 * Returns an event prepared for the "Activity Feed"
		 * @param {Object} event 
		 */
		this.parseEvent = event => {

			// this.$log.debug(event.toJSON());

			let product = event.product;
			let group = event.group;
			let document = event.document;
			let document_key = event.document_key;
			let document_request = event.document_request;

			if (event.type === 'product-scan') {

				event.icon = 'fa-qrcode';
				event.body = `<b class="text-truncate">Scanned</b> a code for <b>"${product ? product.get('name') : '[Deleted product]'}"</b>`;

			} else if (event.type === 'document-dl') {

				event.icon = 'fa-file-text-o';
				event.body = `<b class="text-truncate">Opened "${document ? document.get('label') : '[Deleted document]'}"</b>`;

			} else if (event.type === 'document-req') {

				event.icon = 'fa-file-text-o';
				event.body = `<b  class="text-truncate">Requested access</b> to a document for <b>"${product ? product.get('name') : '[Deleted product]'}"</b>`;
				// event.action = {
				// 	text: 'Review'
				// }

			} else if (event.type === 'document-req-approved') {

				event.icon = 'fa-thumbs-o-up';
				event.body = `<b  class="text-truncate">Request approved</b> to a document for <b>"${product ? product.get('name') : '[Deleted product]'}"</b>`;

			} else if (event.type === 'document-req-denied') {

				event.icon = 'fa-ban';
				event.body = `<b  class="text-truncate">Request denied</b> to a document for <b>"${product ? product.get('name') : '[Deleted product]'}"</b>`;

			} else if (event.type === 'field-log-submitted') {
				
				event.icon = 'fa-book';
				event.body = `<b class="text-truncate">Field Log Submitted</b> for <b>"${product ? product.get('name') : '[Deleted product]'}"</b>`;

			} else if (event.type === 'service-req') {

				event.icon = 'fa-wrench';
				event.body = `<b class="text-truncate">Requested service</b> for <b>"${product ? product.get('name') : '[Deleted product]'}"</b>`;
				// event.action = {
				// 	text: 'Review'
				// }

			} else if (event.type === 'product-view') {
				
				event.icon = 'fa-link';
				// event.body = `<b class="text-truncate">Viewed</b> product <b>"${product ? product.get('name') : '[Deleted product]'}"</b>`;
				event.body = `Viewed company page`;

			} else if (event.type === 'group-view') {
				
				event.icon = 'fa-link';
				event.body = `<b class="text-truncate">Viewed</b> group <b>"${group ? group.get('name') : '[Deleted group]'}"</b>`;

			} else {
				// Throw away unknown event type
			}

			return event;

		}

	});
}

