
/* injects from baggage-loader */

'use strict';

import SignupComponent from './signup.component';

const SignupPageModule = angular.module('signup-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('signup', {
        url: '/signup',
        component: 'signup',
        data: {
          title: 'Signup'
        }
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('signup', new SignupComponent());

export default SignupPageModule;
