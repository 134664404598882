
/* injects from baggage-loader */

'use strict';

import SelectUserModalComponent from './selectusermodal.component';
import './selectusermodal.scss';

const SelectUserModalModule = angular.module('select-user-modal-module', []);

SelectUserModalModule
    .component('selectUserModal', new SelectUserModalComponent());

export default SelectUserModalModule;

