
/* injects from baggage-loader */

'use strict';
import "./circular-progress.css";

export default function (app) {

	app.directive('circularProgress', CircularProgressDirective);

	function CircularProgressDirective($log) {
		'ngInject';

		return {
			restrict: 'AE',
			scope: {
				progress: '<',
				radius: '<',
				stroke: '<',
				foregroundColor: '@',
				backgroundColor: '@'
			},
			link: linkFn
		};

		function linkFn(scope, elem, attrs) {

			const stroke = scope.stroke || 4;
			const radius = scope.radius || 60;
			const normalizedRadius = radius - stroke * 2;
			const circumference = normalizedRadius * 2 * Math.PI;

			// Style
			const backgroundColor = scope.backgroundColor || 'eaeaea';
			const foregroundColor = scope.foregroundColor || '333333';

			function setProgress(percent) {
				const offset = circumference - percent / 100 * circumference;
				let circle = document.querySelector('circle#progress');
				circle.style.strokeDashoffset = offset;
			}

			// Draw the circle
			elem.html(`
			<svg class="progress-ring-circle"
      height="${radius * 2}"
      width="${radius * 2}"
     >
       
	  <circle
         stroke="#${backgroundColor}"
         stroke-dasharray="${circumference} ${circumference}"
         stroke-width="${stroke}"
         fill="transparent"
         r="${normalizedRadius}"
         cx="${radius}"
         cy="${radius}"
	  />
	  <circle id="progress"
         stroke="#${foregroundColor}"
         stroke-dasharray="${circumference} ${circumference}"
         style="stroke-dashoffset: ${circumference}"
         stroke-width="${stroke}"
         fill="transparent"
         r="${normalizedRadius}"
         cx="${radius}"
         cy="${radius}"
	  />
	</svg><strong>${scope.progress}%</strong>`);

			// Update the progress
			setProgress(scope.progress);

		}

	}
}