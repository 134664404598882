
/* injects from baggage-loader */

'use strict';

import ProductComponent from './product.component';

// Child states
import ProductOverviewPage from './overview/product.overview.module';
import ProductDocumentsPage from './documents/product.documents.module';
import ProductActivityPage from './activity/product.activity.module';
import ProductCodePage from './code/product.code.module';
import ProductInfoPage from './info/product.info.module';
import ProductUsersPage from './users/product.users.module';

const ProductPageModule = angular.module('product-module', [
    'ui.router',

    // Child states
    ProductOverviewPage.name,
    ProductDocumentsPage.name,
    ProductActivityPage.name,
    ProductCodePage.name,
    ProductInfoPage.name,
    ProductUsersPage.name,
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/overview');

    $stateProvider
      .state('product', {
        url: '/company/{id}',
        component: 'product',
        abstract: true,
        data: {
          title: 'Company',
          backRoute: 'products',
          backText: ''
        },
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          product: function (Product, $transition$) {
            // if ($transition$.params('to').id === 'new') {
            //   let product = new Product();
            //   return product.save();
            // } else {
            //   return Product.get($transition$.params('to').id);
            // }
            return Product.get($transition$.params('to').id, {
              include: ['company']
            });
          },
          company: function(product) {
            return product.get('company');
          }
        },
        parentNavBar: [
          {
            title: 'Overview',
            icon: 'fa-th-large',
            state: 'product.overview'
          },
          // {
          //   title: 'Info',
          //   icon: 'fa-info-circle',
          //   state: 'product.info'
          // },
          {
            title: 'Documents',
            icon: 'fa-file-text',
            state: 'product.documents'
          },
          {
            title: 'Activity',
            icon: 'fa-th-list',
            state: 'product.activity'
          },
          // {
          //   title: 'Field Logs',
          //   icon: 'fa-book',
          //   state: 'product.fieldlogs'
          // },
          {
            title: 'QR Code',
            icon: 'fa-qrcode',
            state: 'product.code'
          },
          {
            title: 'Users',
            icon: 'fa-user',
            state: 'product.users'
          }
        ]
      });
  })
  .component('product', new ProductComponent());

export default ProductPageModule;
