
/* injects from baggage-loader */

'use strict';

import ProductCreatorController from './product-creator.controller';
import ProductCreatorTemplate from './product-creator.html';

export default class ProductCreatorComponent {
		constructor() {
				this.templateUrl = ProductCreatorTemplate;
				this.controller = ProductCreatorController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					product: '<'
				};
		}
}
