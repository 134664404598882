
/* injects from baggage-loader */

'use strict';

import ProductCreatorInfoController from './product-creator.info.controller';
import ProductCreatorInfoTemplate from './product-creator.info.html';

export default class ProductCreatorInfoComponent {
		constructor() {
				this.templateUrl = ProductCreatorInfoTemplate;
				this.controller = ProductCreatorInfoController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					product: '<'
				};
		}
}
