
/* injects from baggage-loader */

'use strict';

import AddProductVideoModalController from './addproductvideomodal.controller';
import AddProductVideoModalTemplate from './addproductvideomodal.html';

export default class AddProductVideoModalComponent {
		constructor() {
				this.templateUrl = AddProductVideoModalTemplate;
				this.controller = AddProductVideoModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
