
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Company', function (Parse) {

		// Properties
		let schema = [
			'name',
			'address_line_1',
			'address_line_2',
			'city',
			'state', 'region',
			'zip', 'postal_code',
			'company_id',
			'products',
			'service_email',
			'service_phone',
			'website',
			'facebook',
			'linkedin',
			'instagram',
			'twitter',
			'youtube'
		];

		let Company = Parse.Object.extend('Company', {
			// Instance methods

		}, {
				// Class methods

				/**
					 * Retrieve a company by ID
					 */
				get(id, options = {}) {
					let query = new Parse.Query(this);
					if (typeof options.include !== 'undefined') {
						query.include(options.include);
					}
					return query.get(id);
				},


			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(Company.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return Company;

	});
}

