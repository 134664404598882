
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Alerts', function ($timeout) {

    this.collection = [];
    this.local = {};


    /**
     * Add an alert to the collection, will be displayed for 5 seconds before disappearing
     * @param {Object} alert 
     */
    this.add = function (alert) {
      $timeout(() => {
        this.collection.push(alert);
      }, 10);
    }


    /**
     * Remove an alert from the collection
     * @param {Integer} index 
     */
    this.close = function (index) {
      this.collection.splice(index, 1);
    };

    /**
     * Clear the global alert
     */
    this.clearGlobal = function() {
      $timeout(() => {
        this.collection = [];
      }, 10);
		};


    /**
     * Set the local alert
     * @param {Object} alert 
     */
    this.setLocal = function (alert) {
      $timeout(() => {
        this.local = alert;
      }, 10);
    };


    /**
     * Clear the local alert
     */
    this.clearLocal = function() {
      $timeout(() => {
        this.local = {};
      }, 10);
		};
		
	});
}

