
/* injects from baggage-loader */

'use strict';

import ProductUsersController from './product.users.controller';
import ProductUsersTemplate from './product.users.html';

export default class ProductUsersComponent {
		constructor() {
				this.templateUrl = ProductUsersTemplate;
				this.controller = ProductUsersController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					users: '<',
					invitations: '<',
					product: '<',
					company: '<'
				};
		}
}
