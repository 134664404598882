
/* injects from baggage-loader */

'use strict';




import AddDocumentByUrlModalComponent from './adddocumentbyurlmodal.component';
import './adddocumentbyurlmodal.scss';

const AddDocumentByUrlModalModule = angular.module('add-document-by-url-modal-module', []);

AddDocumentByUrlModalModule
    .component('addDocumentByUrlModal', new AddDocumentByUrlModalComponent());

export default AddDocumentByUrlModalModule;

