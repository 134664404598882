
/* injects from baggage-loader */

'use strict';

import ProductGroupController from './product-group.controller';
import ProductGroupTemplate from './product-group.html';

export default class ProductGroupComponent {
		constructor() {
				this.templateUrl = ProductGroupTemplate;
				this.controller = ProductGroupController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					group: '<',
					products: '<',
					users: '<'
				};
		}
}
