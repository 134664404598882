
/* injects from baggage-loader */

'use strict';

import ProductsController from './products.controller';
import ProductsTemplate from './products.html';

export default class ProductsComponent {
		constructor() {
				this.templateUrl = ProductsTemplate;
				this.controller = ProductsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					products: '<'
				};
		}
}
