
/* injects from baggage-loader */

'use strict';

import ProductEditComponent from './product.edit.component';

const ProductEditPageModule = angular.module('product-edit-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      // .state('product-creator', {
      .state('product-editor', {
        url: '/company/create',
        component: 'productEdit',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          product: function (Product, $transition$) {
            // let product = new Product();
            // return product.save();
            return new Product();
          }
        }
      });
  })
  .component('productEdit', new ProductEditComponent());

export default ProductEditPageModule;
