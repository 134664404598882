
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.constant('APP_VERSION', '1.2.4');

	// Default page name displayed in the browser tab
	app.constant('DEFAULT_PAGE_TITLE', 'HRSC');

	app.constant('MAPBOX_ACCESS_TOKEN', 'pk.eyJ1IjoibWl0Y2hza29tcmEiLCJhIjoiY2pqOG53Ymk1MG5mZjNwcDJzN3Y4ZHAwdSJ9.0pCByOO8hndAByxj94LuIg');

	app.constant('FILE_UPLOAD_URL', 'https://storage.hrsc.us/upload');

	app.constant('ROOT_URL', 'hrsc.us');

	app.constant('ENV', 'production');
	
}