
/* injects from baggage-loader */

'use strict';

import ProductGroupCreatorController from './product-group-creator.controller';
import ProductGroupCreatorTemplate from './product-group-creator.html';

export default class ProductGroupCreatorComponent {
		constructor() {
				this.templateUrl = ProductGroupCreatorTemplate;
				this.controller = ProductGroupCreatorController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
