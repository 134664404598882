
/* injects from baggage-loader */

'use strict';

export default class ProductGroupsController {

	constructor($log, $timeout, $uibModal, Alerts, ROOT_URL) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.modalService = $uibModal;
		this.alerts = Alerts;
		this.rootUrl = ROOT_URL;

		this.searchTerm;

	}

	$onInit() {

	}

	getScope(group) {
		return group.scope === 'all' ? 'All Products' : 'Limited';
	}

	getSecurity(group) {
		return group.security === 'public' ? 'Open' : 'Protected';
	}

	getPermalink(group) {
		let route = group.get('security') === 'public' ? 'group' : 'securegroup';
		return `https://scan.${this.rootUrl}/${route}/${group.id}`;
	}

	openDeleteGroupModal(group, index) {
		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: () => ({
					title: 'Delete group?',
					body: 'Are you sure you want to remove this Product Group? Users with access to this group will no longer be able to view it.',
					list: [group.name],
					confirmBtnText: 'Delete',
					cancelBtnText: 'Cancel',
					confirmBtnClass: 'btn-danger'
				})
			}
		});

		modalInstance.result.then(async () => {

			group.deleting = true;

			// Destory the group
			await group.destroy();
			this.groups.splice(index, 1);
			this.alerts.add({ type: 'success', msg: 'Product Group deleted successfully' });

		}, angular.noop);
	}

}
