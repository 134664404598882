
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProductDocument', function (Parse) {

		// Properties
		let schema = [
			'file',
			'label',
			'original_name',
			'size',
			'type',
			'keys',
			'protected',
			'active',
			'stats',
			'url',
			'isGroup',
			'documents',
			'task',
			'isExternalUrl',
			'isCertificate'
		];

		let ProductDocument = Parse.Object.extend('Document', {
			// Instance methods

		}, {
				// Class methods

				fetchSome(options = {}) {
					let query = new Parse.Query(this);
					query.descending('createdAt');
					query.limit(10);
					if (options.limit) {
						query.limit(options.limit);
					}
					if (options.skip) {
						query.skip(options.skip);
					}
					if (options.equalTo) {
						for (let constraint of options.equalTo) {
							query.equalTo(constraint.key, constraint.value);
						}
					}
					return query.find();
				},


				/**
				 * Count number of matched objects
				 */
				count(options = {}) {
					let query = new Parse.Query(this);
					if (options.equalTo) {
						for (let constraint of options.equalTo) {
							query.equalTo(constraint.key, constraint.value);
						}
					}
					return query.count();
				},

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(ProductDocument.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProductDocument;

	});

}

