
/* injects from baggage-loader */

'use strict';

// Import pages
import loginModule from './pages/login/login.module';
import logoutModule from './pages/logout/logout.module';
import signupModule from './pages/signup/signup.module';
import accountModule from './pages/account/account.module';
import productsModule from './pages/products/products.module';
import productPageModule from './pages/product/product.module';
import productEditorPageModule from './pages/product-editor/product.edit.module';
import actionsAcceptInvitationModule from './pages/actions/accept-invite/actions.accept-invite.module';
import passwordResetPageModule from './pages/password-reset/password-reset.module';
import productGroupsPageModule from './pages/product-groups/product-groups.module';
import productGroupPageModule from './pages/product-group/product-group.module';
import productGroupCreatorPageModule from './pages/product-group-creator/product-group-creator.module';
import productCreatorPageModule from './pages/product-creator/product-creator.module';
import serviceUpdatesPageModule from './pages/service-updates/service-updates.module';
import productFieldLogsPageModule from './pages/product/fieldLogs/product.fieldlogs.module';

// Application pages
const pages = [
	loginModule,
	logoutModule,
	signupModule,
	accountModule,
	productsModule,
	productPageModule,
	// productEditorPageModule,
	actionsAcceptInvitationModule,
	passwordResetPageModule,
	productGroupsPageModule,
	productGroupPageModule,
	productGroupCreatorPageModule,
	productCreatorPageModule,
	serviceUpdatesPageModule,
	productFieldLogsPageModule
];

export default angular.module('index.pages', pages.map(p => p.name));
