
/* injects from baggage-loader */

'use strict';

import GenerateButtonCodeModalController from './generatebuttoncodemodal.controller';
import GenerateButtonCodeModalTemplate from './generatebuttoncodemodal.html';

export default class GenerateButtonCodeModalComponent {
		constructor() {
				this.templateUrl = GenerateButtonCodeModalTemplate;
				this.controller = GenerateButtonCodeModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
