
/* injects from baggage-loader */

'use strict';

import StaticMapController from './staticmap.controller';
import StaticMapTemplate from './staticmap.html';

export default class StaticMapComponent {
		constructor() {
				this.templateUrl = StaticMapTemplate;
				this.controller = StaticMapController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					options: '<'
				};
		}
}
