
/* injects from baggage-loader */

'use strict';

export default class ProductCreatorVideosController {

	constructor($log, $state, $uibModal, ProductCreator) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.modalService = $uibModal;
		this.productCreator = ProductCreator;

		this.savingProduct = false;

	}

	$onInit() {

		// Return to first step if product is new
		if (this.product.isNew()) {
			this.$state.go('product-creator.info');
		}

		this.product.videos = this.product.videos || [];
		
	}

	openAddVideoModal() {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'addProductVideoModal'
		});

		modalInstance.result.then(async res => {
			this.product.videos.push(res.video);
		}, angular.noop);

	}

}
