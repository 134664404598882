
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ServiceUpdate', function ($q, Parse) {

		// Properties
		let schema = [
			'text',
			'issuedAt',
			'bulk',
			'products',
			'name',
			'productCount',
			'document'
		];

		let ServiceUpdate = Parse.Object.extend('ProductServiceUpdates', {
			// Instance methods

		}, {
				// Class methods

				/**
				 * Query for products
				 * @param {Object} options 
				 */
				query(options = {}) {
					let d = $q.defer();
					let query = new Parse.Query(this);
					query.descending('issuedAt');
					query.limit(1000);
					for (let key in options) {
						if (options[key] instanceof Array) {
							for (let item of options[key]) {
								if (typeof item.key !== 'undefined' && typeof item.value !== 'undefined') {
									query[key](item.key, item.value);
								} else {
									query[key](item);
								}
							}
						} else {
						if (typeof options[key].key !== 'undefined' && typeof options[key].value !== 'undefined') {
							query[key](options[key].key, options[key].value);
						} else {
							query[key](options[key]);
						}
					}
					}
					d.resolve(query.find());
					return d.promise;
				},


				/**
				 * Count number of matched objects
				 */
				count(options = {}) {
					let query = new Parse.Query(this);
					if (options.equalTo) {
						for (let constraint of options.equalTo) {
							query.equalTo(constraint.key, constraint.value);
						}
					}
					return query.count();
				},

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(ServiceUpdate.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ServiceUpdate;

	});

}

