
/* injects from baggage-loader */

'use strict';

export default class AddProductVideoModalController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		this.video = {};

	}

	$onInit() {

		// Set modal context
		// this.context = this.resolve.context;

	}

	isValidUrl(string) {
		// try {
		// 	new URL(string);
		// 	return true;
		// } catch (_) {
		// 	return false;
		// }
		let res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
		if (res === null)
			return false;
		else
			return true;
	}

	ok() {

		this.error_msg = '';

		// Trim both values
		['label', 'video'].forEach(v => {
			v = v.trim();
		})

		if (!this.video.label) {
			this.error_msg = 'Please include a label for this video';
			return false;
		}

		if (!this.video.url) {
			this.error_msg = 'Please set a URL for this video';
			return false;
		}

		// Set protocol for video url if missing
		if (this.video.url.indexOf('http') !== 0) {
			this.video.url = `http://${this.video.url}`;
		}

		if (!this.isValidUrl(this.video.url)) {
			this.error_msg = 'Please enter a valid video URL';
			return false;
		}

		this.close({
			$value: {
				video: this.video
			}
		});
	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
