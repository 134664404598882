
/* injects from baggage-loader */

'use strict';

import Parse from 'parse';

export default function (app) {
	app.service('Parse', function () {		
		return Parse;
	});
}
