
/* injects from baggage-loader */

'use strict';

/**
 * POSSIBLE USER ROLES
 * 
 * owner - Account creator and owner, can perform all account actions.
 * manager - Can create and edit all objects. Can manage users.
 * reviewer - Can view all objects and can approve or deny requests.
 * viewer - Can view all objects.
 * 
 */

export default class ProductUsersController {

	constructor($log, $document, $uibModal, $timeout, Parse, Alerts, AccountInvitation, User) {
		'ngInject';

		this.$log = $log;
		this.$document = $document;
		this.$timeout = $timeout;
		this.modalService = $uibModal;
		this.Parse = Parse;
		this.alerts = Alerts;
		this.AccountInvitation = AccountInvitation;
		this.User = User;

		// Types of account roles
		this.roles = [
			'owner', 'manager', 'reviewer', 'viewer'
		];

		// This invite page is used to invite users to a managed sub account
		this.isSubAccountInvite = true;

	}

	$onInit() {
		this.$log.debug(this.users)
		this.$log.debug(this.invitations)

		this._users = [...this.users, ...this.invitations];
	}


	async loadUsers() {
		let users = this.User.fetchAuthorized();
		let invitations = this.AccountInvitation.findPending({ isSubAccount: true });
		this._users = [...await users, ...await invitations];
		this.$timeout();
	}

	async openInviteUserModal() {

		let modalInstance = this.modalService.open({
			animation: true,
			backdrop: 'static',
			component: 'inviteUserModal',
			resolve: {
				context: () => ({
					roles: this.roles,
					product: this.product,
					company: this.company,
					isSubAccount: this.isSubAccountInvite
				})
			}
		});

		modalInstance.result.then(async res => {

			// Reload invitations
			this._users = [...this.users, ...await this.AccountInvitation.findPending()];

			this.alerts.setLocal({ type: 'success', msg: `User invited: ${res.user.email}` });
			this.$document.scrollTopAnimated(0, 500)

		}, angular.noop);

	}

	async revokeInvitation(invitation) {

		// Delete invitation
		await invitation.destroy();

		// Reload invitations
		this._users = [...this.users, ...await this.AccountInvitation.findPending()];

		this.alerts.setLocal({ type: 'success', msg: 'Invitation revoked' });
		this.$document.scrollTopAnimated(0, 250);
		this.$timeout();

	}

	removeUser(user) {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: () => ({
					title: 'Remove user?',
					body: 'Are you sure you want to remove this user from you company? This user will no longer have access after you remove them.',
					list: [user.username || user.email],
					confirmBtnText: 'Remove',
					cancelBtnText: 'Cancel',
					confirmBtnClass: 'btn-danger'
				})
			}
		});

		modalInstance.result.then(async () => {
			user.removing = true;
			await this.Parse.Cloud.run('removeAuthorizedUser', { userId: user.id });
			this.loadUsers();
			this.alerts.setLocal({ type: 'success', msg: 'User removed successfully' });
		}, angular.noop);

	}

}
