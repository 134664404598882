
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('FieldLog', function (Parse, QueryOverride, $q) {

		// Properties
		let schema = [
			'name',
			'company_name',
			'email',
			'note',
			'product',
			'company'
		];

		let FieldLog = Parse.Object.extend('ProductFieldLog', {
			// Instance methods

		}, {
			// Class methods

			/**
			 * Query for products
			 * @param {Object} options 
			 */
			query(options = {}, queryOverrideFn) {
				options = options || {};
				let d = $q.defer();
				let query = new Parse.Query(this);
				query.limit(10);
				query.descending('createdAt');

				if (options.skip) {
					query.skip(options.skip);
				}
				if (options.equalTo) {
					for (let constraint of options.equalTo) {
						query.equalTo(constraint.key, constraint.value);
					}
				}
				
				// Handler optional query override
        query = QueryOverride(this, query, queryOverrideFn);

				d.resolve(query.find());
				return d.promise;
			},


			/**
			 * Query for products
			 * @param {Object} options 
			 */
			count(options = {}) {
				let d = $q.defer();
				let query = new Parse.Query(this);
				if (options.equalTo) {
					for (let constraint of options.equalTo) {
						query.equalTo(constraint.key, constraint.value);
					}
				}
				d.resolve(query.count());
				return d.promise;
			},


			/**
			 * Retrieve a product by ID
			 */
			get(id, options = {}) {
				let query = new Parse.Query(this);
				if (typeof options.include !== 'undefined') {
					query.include(options.include);
				}
				return query.get(id);
			},

		});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(FieldLog.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return FieldLog;

	});
}

