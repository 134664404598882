
/* injects from baggage-loader */

'use strict';

import ProductFieldLogsComponent from './product.fieldlogs.component';

const ProductFieldLogsPageModule = angular.module('product-field-logs-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product.fieldlogs', {
        data: {
          title: 'Field Logs'
        },
        url: '/fieldlogs?selected?page',
        component: 'productFieldLogs',
        // Do not reload the page when updating query string
        reloadOnSearch: false,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          config: function() {
            return {
              pagination: {
                currentPage: 1,
                pageSize: 10,
                maxSize: 10
              }
            }
          },
          fieldLogResponse: function(FieldLog, product, config, $transition$) {
            return FieldLog.query(null, (query, getNewQuery, P) => {
              query.withCount(true);
              query.equalTo('product', product);
              const currentPage = Number($transition$.params('to').page);
              if (currentPage) {
                config.pagination.currentPage = Number(currentPage);
                const skip = (currentPage * config.pagination.pageSize) - config.pagination.pageSize
                query.skip(skip);
              }
            });
          },
          fieldLogs: function(fieldLogResponse) {
            return fieldLogResponse.results;
          },
          totalItemCount: function(fieldLogResponse) {
            return fieldLogResponse.count;
          },
          selectedFieldLog: function(FieldLog, fieldLogs, $transition$) {
            const selectedLogId = $transition$.params('to').selected;

            // Attempt to find the log in the current page results
            if (selectedLogId) {
              const cachedLog = fieldLogs.find(log => log.id === selectedLogId);
              return cachedLog || FieldLog.get(selectedLogId).catch(err => null);
            }

          }
        }
      });
  })
  .component('productFieldLogs', new ProductFieldLogsComponent());

export default ProductFieldLogsPageModule;
