
/* injects from baggage-loader */

'use strict';

export default class DynamicFormController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {

		// Setup the component model
		this.model = this.ngModel;
		
	}

}
