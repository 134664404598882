
/* injects from baggage-loader */

'use strict';

export default class DocumentUploadModalController {

	constructor($log, $timeout, Parse, ProductDocument, Upload, User, FILE_UPLOAD_URL) {
		'ngInject';

		this.FILE_UPLOAD_URL = FILE_UPLOAD_URL;
		this.$log = $log;
		this.$timeout = $timeout;
		this.Parse = Parse;
		this.ProductDocument = ProductDocument;
		this.Upload = Upload;
		this.User = User;

		this.documents = [];
		this.pendingFiles = [];
		this.invalidFiles = [];
		this.unsavedDocuments = [];

		this.errorMsg = '';

	}

	$onInit() {

		this.context = this.resolve.context;
		this.product = this.context.product;
		this.documentGroup = this.context.documentGroup;

	}

	// $onDestroy() {

	// 	// Revert any changes
	// 	this.product.revert();

	// 	// Revert all document label changes
	// 	for (let doc of this.documents) {
	// 		doc.revert();
	// 	}

	// 	// Delete any unlinked documents
	// 	this.Parse.Object.destroyAll(this.unsavedDocuments);

	// }


	async ok() {

		// Verify each document has a label set
		for (let doc of this.documents) {
			if (!doc.label) {
				alert('Missing label!');
				return false;
			}
		}

		// // Clear unrelated documents
		// this.unsavedDocuments = [];

		// // Save each document
		for (let doc of this.documents) {
			await doc.save();
		}

		// // Save the product
		// this.$log.debug('Saving product:', this.product.name);
		// await this.product.save();

		this.close({
			$value: {
				documents: this.documents
			}
		});
	}

	cancel() {

		// Delete any unlinked documents
		// this.Parse.Object.destroyAll(this.unsavedDocuments);
		this.Parse.Object.destroyAll(this.documents);

		// Close the modal
		this.dismiss({ $value: 'cancel' });

	}


	async processFiles() {

		this.errorMsg = '';

		while (this.pendingFiles.length > 0) {

			try {

				// Get a file
				let file = this.pendingFiles.shift();

				// Sanitize the filename
				let filename = file.name.split('.');
				let ext = filename.pop();
				filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
				filename = `${filename}.${ext}`;

				// Create a new "Document"
				let document = new this.ProductDocument({
					label: filename,
					original_name: file.name,
					size: file.size,
					type: file.type,
					product: this.product
				});
				await document.save();

				document.uploaded = false;
				document.uploading = true;
				document.animateUpload = false;
				document.progressPercentage = 0;

				// Push the document to the documents array
				this.documents.push(document);

				// Push the document to the "unsavedDocuments" array
				// this.unsavedDocuments.push(document);

				this.uploadFile(file, document);

			} catch (err) {
				this.errorMsg = err.toString().replace('ParseError: 141', '').trim();
				this.$timeout();
			}

		}

	}


	/**
	 * Removes a document from the tracked documents array and 
	 * adds it to the array of documents to delete
	 * @param {Object} doc 
	 */
	removeDocument(doc) {

		// Remove the document from the "documents" array
		this.documents.splice(this.documents.indexOf(doc), 1);

		// Add the document to the array of documents to remove
		// this.removeDocuments.push(doc);

		let docRelation = this.product.relation("documents");

		// Remove document relation
		docRelation.remove(doc);

		// Delete the document
		doc.destroy();

	}


	uploadFile(file, document) {
		this.Upload.upload({
			url: this.FILE_UPLOAD_URL,
			data: {
				files: [file],
				documentId: document.id,
				productId: this.product.id
			},
			headers: {
				'X-Parse-Session-Token': this.User.getSessionToken()
			}
		}).then(async resp => {
			this.$log.debug('Success ' + resp.config.data.files[0].name + 'uploaded. Response: ' + resp.data);
			// Uploading complete
			document.uploading = false;
			document.uploaded = true;
			document.animateUpload = false;
			this.$timeout();

		}, resp => {
			this.$log.debug('Error status: ' + resp.status);
			document.error = 'An error occured';
			document.progressPercentage = 100;
			document.animateUpload = false;
			this.$timeout()
		}, evt => {
			if (evt.config.data.files) {
				let progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				document.progressPercentage = progressPercentage;
				if (progressPercentage === 100) {
					document.uploading = false;
					document.animateUpload = true;
					this.$timeout()
				}
			}
		});
	}

}
