
/* injects from baggage-loader */

'use strict';

export default class ProductCodeController {

	constructor($log, Parse) {
		'ngInject';

		this.$log = $log;
		this.serverUrl = Parse.serverURL.replace('/parse', '');

		this.text_color = '3D46CD';
		this.transparent_bg = false;
		this.hide_text = true;
		this.size = 300;
		this.code_style = 'v1';

		this.code_sizes = [{
			label: 'Small',
			size: 175
		}, {
			label: 'Medium',
			size: 300
		}, {
			label: 'Large',
			size: 500
		}, {
			label: 'Extra large',
			size: 800
		}];

		this.code_styles = ['v1', 'v2'];

	}

	$onInit() {

	}

	getCodeURL() {
		if (!this.product.code) return '#';
		if (this.code_style === 'v1') {
			return `${this.serverUrl}/qr?ec_level=Q&text_color=${this.text_color}&no_text=${this.hide_text & 1}&transparent_bg=${this.transparent_bg & 1}&size=${this.size}&code_id=${this.product.code.id}`;
		} else if (this.code_style === 'v2') {
			return `${this.serverUrl}/qr/${this.product.code.id}`;
		} else {
			return `${this.serverUrl}/qr?ec_level=Q&text_color=${this.text_color}&no_text=${this.hide_text & 1}&transparent_bg=${this.transparent_bg & 1}&size=${this.size}&code_id=${this.product.code.id}`;
		}
	}

	getDownloadLink() {
		if (!this.product.code) return '#';
		let codeUrl = this.getCodeURL()
		return codeUrl.includes('?') ? `${codeUrl}&download=1` : `${codeUrl}?download=1`;
	}

	getCodeName() {
		return `${this.product.id}-${this.size}px`;
	}

	setSize(size) {
		this.size = size;
	}

	setBackgroundTransparent(v) {
		this.transparent_bg = v;
	}

	setText(v) {
		this.hide_text = v;
	}

	print() {

			let divName = 'scanCode';

			let printContents = document.getElementById(divName).innerHTML; 
			// this.$log.debug(printContents)
	
			let popupWin;
			if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
					popupWin = window.open('', '_blank', 'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
					popupWin.window.focus();
					popupWin.document.write('<!DOCTYPE html><html><head>' +
							'<link rel="stylesheet" type="text/css" href="style.css" />' +
							'</head><body onload="window.print()"><div class="reward-body">' + printContents + '</div></body></html>');
					popupWin.onbeforeunload = function (event) {
							popupWin.close();
							return '.\n';
					};
					popupWin.onabort = function (event) {
							popupWin.document.close();
							popupWin.close();
					}
			} else {
					popupWin = window.open('', '_blank', 'width=800,height=600');
					popupWin.document.open();
					popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
					popupWin.document.close();
			}
			popupWin.document.close();
	
			return true;
	}

}
