
/* injects from baggage-loader */

'use strict';

import ProductInfoController from './product.info.controller';
import ProductInfoTemplate from './product.info.html';

export default class ProductInfoComponent {
		constructor() {
				this.templateUrl = ProductInfoTemplate;
				this.controller = ProductInfoController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					product: '<',
					company: '<',
					documents: '<',
					serviceUpdates: '<'
				};
		}
}
