
/* injects from baggage-loader */

'use strict';

import ProductInfoComponent from './product.info.component';

const ProductInfoPageModule = angular.module('product-info-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product.info', {
        data: {
          title: 'Info'
        }, 
        url: '/info?action',
        component: 'productInfo',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          product: function(Product, $transition$) {
            return Product.get($transition$.params('to').id);
          },
          company: function (product, Company) {
            return product.get('company').fetch();
          },
          serviceUpdates: function(product) {
            return product.relation('service_updates').query().find();
          }
        }
      });
  })
  .run(function ($transitions, Navigation) {
    // Setup navigation guard
    $transitions.onStart({ from: 'product.info' }, () => Navigation.checkGuard());
  })
  .component('productInfo', new ProductInfoComponent());

export default ProductInfoPageModule;
