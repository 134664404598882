
/* injects from baggage-loader */

'use strict';

import ProductSelectorModalComponent from './productselectormodal.component';
import './productselectormodal.scss';

const ProductSelectorModalModule = angular.module('product-selector-modal-module', []);

ProductSelectorModalModule
    .component('productSelectorModal', new ProductSelectorModalComponent());

export default ProductSelectorModalModule;

