
/* injects from baggage-loader */

'use strict';

export default class EditDocumentModalController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		this.isGroup = false;

		this.filterKeys = ['label', 'active', 'protected', 'isCertificate'];
		this.trimKeys = ['label'];

	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;
		this.document = this.context.document.toJSON();
		this.isGroup = this.document.isGroup;
		this.isExternalUrl = this.document.isExternalUrl;

		if (this.isExternalUrl) {
			this.filterKeys.push('url');
			this.trimKeys.push('url');
		}

		// Filter keys needed to edit
		this.document = Object.keys(this.document).reduce((obj, key) => {
			if (this.filterKeys.includes(key)) {
				obj[key] = this.document[key];
			}
			return obj;
		}, {})
		this.$log.debug(this.document);

	}

	setProtected(value) {
		this.document.protected = value;
	}

	setDocumentCertification(value) {
		this.document.isCertificate = value;
	}

	ok() {

		this.error_msg = '';

		// Trim values
		this.trimKeys.forEach(v => {
			this.document[v] = this.document[v].trim();
		})

		if (!this.document.label) {
			this.error_msg = 'Document label is required';
			return false;
		}

		if (!this.document.url && this.isExternalUrl) {
			this.error_msg = 'Document URL is required';
			return false;
		}

		// Return the document
		this.close({
			$value: this.document
		});

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
