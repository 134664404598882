
/* injects from baggage-loader */

'use strict';

import LoadingCoverComponent from './loadingcover.component';
import './loadingcover.scss';

const LoadingCoverModule = angular.module('loading-cover-module', []);

LoadingCoverModule
    .component('loadingCover', new LoadingCoverComponent());

export default LoadingCoverModule;

