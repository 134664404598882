
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.filter('filesize', function () {
		// args: (input, param)

		/**
		 * An array of units, starting at bytes and ending with yottabytes.
		 */
		let units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

		return function (bytes, precision) {

			// validate 'bytes'
			if (isNaN(parseFloat(bytes))) {
				return "-";
			}
			if (bytes < 1) {
				return "0 B";
			}

			// validate 'precision'
			if (isNaN(precision)) {
				precision = 1;
			}

			let unitIndex = Math.floor(Math.log(bytes) / Math.log(1000));
			let value = bytes / Math.pow(1000, unitIndex);

			return value.toFixed(precision) + " " + units[unitIndex];

		};

	});
}