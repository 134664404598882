
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Product', function (Parse, $q) {

		let schema = [
			'name',
			'sku',
			'model_num',
			'image',
			'documents',
			'status',
			'service_email',
			'product_page',
			'code',
			'company',
			'videos',
			'stats',
			'service_updates',
			'document_order',
			'description'
		];

		let Product = Parse.Object.extend('Product', {
			// Instance methods

		}, {
				// Class methods

				/**
				 * Query for products
				 * @param {Object} options 
				 */
				query(options = {}) {
					let d = $q.defer();
					let query = new Parse.Query(this);
					query.descending('createdAt');
					for (let key in options) {
						if (typeof options[key].key !== 'undefined' && typeof options[key].value !== 'undefined') {
							query[key](options[key].key, options[key].value);
						} else {
							query[key](options[key]);
						}
					}
					d.resolve(query.find());
					return d.promise;
				},


				/**
				 * Query for products
				 * @param {Object} options 
				 */
				count(options = {}) {
					let d = $q.defer();
					let query = new Parse.Query(this);
					if (options.equalTo) {
						for (let constraint of options.equalTo) {
							query.equalTo(constraint.key, constraint.value);
						}
					}
					d.resolve(query.count());
					return d.promise;
				},


				/**
				 * Retrieve a product by ID
				 */
				get(id, options = {}) {
					let query = new Parse.Query(this);
					if (typeof options.include !== 'undefined') {
						query.include(options.include);
					}
					return query.get(id);
				},


				/**
				 * Returns the only product/company the sub account has access to
				 */
				only() {
					let query = new Parse.Query(this);
					return query.first(); 
				},


				/**
				 * Create a new product
				 * @param {Object} product 
				 */
				create(product = {}) {

				},


				/**
				 * Return a list of all product names
				 */
				getProductNamesList() {
					let query = new Parse.Query(this);
					query.limit(1000);
					query.select(['name']);
					return query.find();
				}

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(Product.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return Product;

	});
}

