
/* injects from baggage-loader */

'use strict';

import AccountSettingsPasswordComponent from './account.settings.password.component';

const AccountSettingsPasswordPageModule = angular.module('account-settings-password-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.password', {
        url: '/password',
        component: 'accountSettingsPassword',
        protected: true,
				data: {
          title: 'My Account Settings: Password',
          navTitle: 'Change password'
        },
        // Resolve promises into controller bindings, see component for bindings
				resolve: {
					user: function (User) {
						return User.current();
					}
				}
      });
  })
  .component('accountSettingsPassword', new AccountSettingsPasswordComponent());

export default AccountSettingsPasswordPageModule;
