
/* injects from baggage-loader */

'use strict';

import ActivityFeedItemController from './activityfeeditem.controller';
import ActivityFeedItemTemplate from './activityfeeditem.html';

export default class ActivityFeedItemComponent {
		constructor() {
				this.templateUrl = ActivityFeedItemTemplate;
				this.controller = ActivityFeedItemController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					event: '<',
					$last: '<',
					link: '<'
				};
		}
}
