
/* injects from baggage-loader */

'use strict';

import AccountSettingsComponent from './account.settings.component';

// Child state modules
import AccountProfileSettingsModule from './profile/account.settings.profile.module';
import AccountPasswordSetttingsModule from './password/account.settings.password.module';
// import AccountSecuritySettingsModule from './security/account.settings.security.module';
import AccountCompanySettingsModule from './company/account.settings.company.module';
import AccountUsersSettingsModule from './users/account.settings.users.module';
import AccountNotificationSettingsModule from './notifications/account.settings.notifications.module';

const AccountSettingsPageModule = angular.module('account-settings-module', [
    'ui.router',

    // Child states
    AccountProfileSettingsModule.name,
    AccountPasswordSetttingsModule.name,
    // AccountSecuritySettingsModule.name,
    AccountCompanySettingsModule.name,
    AccountUsersSettingsModule.name,
    AccountNotificationSettingsModule.name,

  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings', {
        name: 'Settings',
        url: '/settings',
        component: 'accountSettings',
        protected: true,
				abstract: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('accountSettings', new AccountSettingsComponent());

export default AccountSettingsPageModule;
