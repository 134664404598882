
/* injects from baggage-loader */

'use strict';

import ProductDocumentsComponent from './product.documents.component';

const ProductDocumentsPageModule = angular.module('product-documents-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product.documents', {
        data: {
          title: 'Documents'
        },
        url: '/documents',
        component: 'productDocuments',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          documents: function (product, ProductDocument) {
            if (!product.get('documents')) {
              return [];
            }
            return product.get('documents').query()
              .limit(1000).find();
          }
        }
      });
  })
  .component('productDocuments', new ProductDocumentsComponent());

export default ProductDocumentsPageModule;
