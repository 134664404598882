
/* injects from baggage-loader */

'use strict';

import ProductGroupSettingsController from './product-group.settings.controller';
import ProductGroupSettingsTemplate from './product-group.settings.html';

export default class ProductGroupSettingsComponent {
		constructor() {
				this.templateUrl = ProductGroupSettingsTemplate;
				this.controller = ProductGroupSettingsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					group: '<',
					products: '<',
					users: '<'
				};
		}
}
