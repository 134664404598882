
/* injects from baggage-loader */

'use strict';

import LogoutComponent from './logout.component';

const LogoutPageModule = angular.module('logout-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('logout', {
        url: '/logout',
        component: 'logout',
        protected: true,
        data: {
					title: 'Account logout'
				},
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('logout', new LogoutComponent());

export default LogoutPageModule;
