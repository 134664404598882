
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsPasswordController {

	constructor($log, $state, Alerts, Parse, User) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.Parse = Parse;
		this.User = User;

		// Title displayed on the simple form template
		this.formTitle = 'Change password';

		this.password = {};
		this.enableSaveButton = false;

		// Assign alerts service
		this.alerts = Alerts;

	}

	$onInit() {

		// Password Form
    this.formTemplate = {

      model: this.password,

      // Form input groups
      // Currently only simple fields are supported
      // ex. text, email, number
      fields: [
        {
          type: 'password',
          label: 'Current password',
          model: 'current',
					autocomplete: 'password',
					autofocus: true,
          describedby: 'currentPassword',
          onChange: () => {
            this.checkPasswordFields();
          }
        },
        {
          type: 'password',
          label: 'New password',
          model: 'new',
          autocomplete: 'new-password',
          describedby: 'newPassword',
          onChange: () => {
            this.checkPasswordFields();
          }
        },
        {
          type: 'password',
          label: 'Confirm password',
          model: 'confirm',
          autocomplete: 'new-password',
          describedby: 'confirmPassword',
          onChange: () => {
            this.checkPasswordFields();
          }
        }
      ],

      // Action button at the end of the form
      submit: {
        text: 'Update password',
        action: () => {
          this.updatePassword();
        },
        disabled: () => !this.enableSaveButton
      }

    };

	}


	/**
     * Function called each time the password inputs are modified.
     * Checks for the following cases to evaluate as true
     *  - All fields are filled out
     */
	checkPasswordFields() {
		if (this.password.current && this.password.new && this.password.confirm) {
			this.enableSaveButton = true;
		} else {
			this.enableSaveButton = false;
		}
	}


	/**
	 * Calls the cloudcode function to save the users new password
	 */
	updatePassword() {

		// Clear error message
		this.alerts.clearLocal();

		// Check that new passwords are matching
		if (this.password.new !== this.password.confirm) {
			this.alerts.setLocal({
				type: 'danger',
				msg: 'New passwords do not match.'
			});
			this.enableSaveButton = false;
			return false;
		}

		// Run cloud code function to save new password
		this.Parse.Cloud.run('changePassword', {
			p: this.password.current,
			np: this.password.new
		}).then(() => {

			// Password updated successfully
			this.alerts.setLocal({
				type: 'success',
				msg: 'Password updated successfully'
			});

			// Reauthenticate the user with new password
			this.User.authenticate({
				email: this.user.getUsername(),
				password: this.password.new
			})
				.then(() => angular.noop)
				.catch(err => {
					this.$log.debug(err);
					// Something went wrong, logout user to manaully reauthenticate
					this.$state.go('logout');
				});

			// Clear fields
			this.password = {};

		})
			.catch(err => {

				// Something went wrong
				this.$log.error(err);

				if (err.code === 101) {
					this.alerts.setLocal({
						type: 'danger',
						msg: 'Failed to update password, the current password was entered incorrectly.'
					});
				} else {
					this.alerts.setLocal({
						type: 'danger',
						msg: 'Failed to update password! Please contact support if the problem persists.'
					});
				}

			});

	}
}
