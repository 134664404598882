
/* injects from baggage-loader */

'use strict';

export default class ProductDocumentsController {

	constructor($log, $timeout, $interval, $window, $uibModal, $location, $state, ProductDocument, Alerts, Parse, Snackbar, ProductCreator, ROOT_URL) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$interval = $interval;
		this.$window = $window;
		this.$uibModal = $uibModal;
		this.ProductDocument = ProductDocument;
		this.alerts = Alerts;
		this.Parse = Parse;
		this.snackbar = Snackbar;
		this.$state = $state;
		this.productCreator = ProductCreator;
		this.rootUrl = ROOT_URL;

		this.documentOrder = [];
		this.selectedDocumentGroup = null;

		this.liveQuerySubscription;
		this.liveQueryDocumentIds = [];

		this.isCreatingProject = $location.path().includes('product-creator');

	}

	$onInit() {

		// Return to first step if product is new
		if (this.product.isNew() && this.isCreatingProject) {
			this.$state.go('product-creator.info');
			return false;
		}

		if (this.isCreatingProject) {
			this.documents = this.productCreator.documents;
		}

		// this.$log.debug(this.product)
		// this.$log.debug(this.documents)

		// Display documents in order
		this.loadDocumentTable(this.product.document_order);

	}

	$onDestroy() {
		this.unsubscribeLiveQuery();
		if (this.isCreatingProject) {
			this.productCreator.documents = this.documents;
		}
	}

	loadDocumentTable(order) {
		this.documentOrder = order
			.map(id => {
				let item = this.documents.find(doc => doc.id === id);
				if (item) {
					if (item.isGroup) {
						// Setup document group
						item._documents = item.documents.map(_id => this.documents.find(doc => doc.id === _id)).filter(doc => doc !== undefined);
						item.size = item._documents.reduce((size, doc) => size + doc.size, 0);
					}
					if (item.url) {
						// Create download link
						item.dl_url = this.getDownloadLink(item);
					}
					if (!item.isGroup && !item.url && !item.uploading && !item.uploaded) {
						// this.$log.debug(item)
						// Failed download, disable
						item.uploadFailed = true;
						item.active = false;
					}
				}
				return item;
			})
			// Remove undefined items
			.filter(doc => doc !== undefined);

		// Scan and update document tasks
		this.updateDocumentTasks();

		// TODO: Find abandoned/unordered documents, append to main list
		// if (!this.selectedDocumentGroup) {

		// }

	}

	openLiveQuery(id) {

		if (!id) {
			return false;
		}

		// Update document ids to watch for
		if (!this.liveQueryDocumentIds.includes(id)) {
			this.liveQueryDocumentIds.push(id);
		}

		// Update or start a live query subscription
		this.updateLiveQuery();

	}

	async updateLiveQuery() {

		// Close any existing live query subscriptions before opening a new one
		if (this.liveQuerySubscription) {
			this.liveQuerySubscription.unsubscribe();
		}

		if (!this.liveQueryDocumentIds.length) {
			return false;
		}

		let query = new this.Parse.Query('Document');
		query.containedIn('objectId', this.liveQueryDocumentIds);
		this.liveQuerySubscription = await query.subscribe();

		this.liveQuerySubscription.on('open', () => {
			this.$log.debug('subscription to "Documents" opened', this.liveQueryDocumentIds);
		});

		this.liveQuerySubscription.on('update', async doc => {
			// Refresh documents
			this.documents = await this.product.relation('documents').query().find();
			// Reload document order list
			if (this.selectedDocumentGroup) {
				this.loadDocumentTable(this.selectedDocumentGroup.documents);
			} else {
				this.loadDocumentTable(this.product.document_order);
			}
			this.$timeout();
		});

	}

	unsubscribeLiveQuery() {
		if (this.liveQuerySubscription) {
			this.liveQuerySubscription.unsubscribe();
			this.$log.debug('Live query unsubscribed');
		}
	}

	async updateDocumentTasks() {
		let pendingTasks = [];
		for (let document of this.documents) {

			if (document.task) {
				pendingTasks.push(document);
			} else continue;

			// Task failed, alert and remove from document
			if (document.task.error) {
				this.snackbar.open(document.task.error, {
					addClass: 'bg-danger'
				});
				document.unset('task');
				document.save();
			}

			// Task completed, remove from document
			else if (document.task.endedAt || !document.task.active) {
				this.snackbar.open(document.task.msg);
				document.unset('task');
				document.save();
			}

		}
		if (pendingTasks.length) {
			this.liveQueryDocumentIds = pendingTasks.map(d => d.id);
			this.updateLiveQuery();
		} else {
			this.liveQueryDocumentIds = [];
			this.unsubscribeLiveQuery();
		}
	}

	async toggleDocumentActiveStatus(doc) {
		doc.isUpdating = true;
		await doc.save();
		this.$timeout(() => { doc.isUpdating = false; }, 500);
	}

	async makeDocumentProtected(doc) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: () => ({
					title: 'Protect document?',
					body: 'Are you sure you want to make this document protected? Approved users will be required to enter a "document key" for access.',
					confirmBtnText: 'Yes, Make protected',
					cancelBtnText: 'No, Cancel',
					confirmBtnClass: 'btn-primary'
				})
			}
		});

		modalInstance.result.then(async () => {
			try {
				doc.isUpdating = true;
				await doc.save({ protected: true });
				doc.isUpdating = false;
				this.$timeout(() => { }, 500);
			} catch (err) {
				// Display error alert
				this.alerts.setLocal({
					type: 'danger',
					msg: err.toString().replace('ParseError: 141', '').trim()
				});
				doc.protected = false;
				doc.isUpdating = false;
				this.$timeout();
			}
		}, angular.noop);

	}

	async openEditDocumentModal(doc) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'editDocumentModal',
			resolve: {
				context: () => ({ document: doc })
			}
		});

		modalInstance.result.then(async doc_edits => {
			try {
				doc.isUpdating = true;
				await doc.save(doc_edits);
				doc.isUpdating = false;
				this.$timeout(() => { }, 500);
			} catch (err) {
				// Display error alert
				this.alerts.setLocal({
					type: 'danger',
					msg: err.toString().replace('ParseError: 141', '').trim()
				});
				doc.protected = false;
				doc.isUpdating = false;
				this.$timeout();
			}
		}, angular.noop);

	}

	async removeDocument(doc) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: () => ({
					title: doc.isGroup ? 'Delete document group?' : 'Delete document?',
					body: doc.isGroup ? 'Are you sure you want to delete this document group? The following documents will be deleted as a result.' : 'Are you sure you want to delete this document?',
					list: doc.isGroup ? doc._documents.map(d => d.label) : [doc.label],
					confirmBtnText: 'Delete',
					cancelBtnText: 'Cancel'
				})
			}
		});

		modalInstance.result.then(async () => {

			// Find and remove document from full documents list
			this.documents.splice(this.documents.indexOf(doc), 1);

			// Find and remove document from document order list
			this.documentOrder.splice(this.documentOrder.indexOf(doc), 1);

			// Find and remove the document from the order			
			if (this.selectedDocumentGroup) {
				this.selectedDocumentGroup.remove('documents', doc.id);
				this.selectedDocumentGroup.save();
			} else {
				this.product.remove('document_order', doc.id);
			}

			// Remove from the products document relation
			let relation = this.product.relation('documents');
			relation.remove(doc);

			// Save the product
			await this.product.save();

			// Destory the document
			await doc.destroy();

		}, angular.noop);

	}

	async openDocumentUploadModal() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			size: 'xlg',
			backdrop: 'static',
			component: 'documentUploadModal',
			resolve: {
				context: () => ({
					product: this.product,
					documentGroup: this.selectedDocumentGroup
				})
			}
		});

		modalInstance.result.then(async res => {

			// Concatenate new with existing documents
			this.documents = [...this.documents, ...res.documents];

			// Count documents that are still uploading
			let documentsUploading = this.documents.reduce((count, doc) => doc.uploading ? count + 1 : count, 0);
			if (documentsUploading > 0) {
				this.snackbar.open('Files are still uploading. Please do not refresh or leave this page', {
					addClass: 'bg-primary',
					duration: 10000,
					icon: 'fa-info-circle'
				});
			}

			// Update documents relation
			let docRelation = this.product.relation("documents");
			res.documents.forEach(doc => docRelation.add(doc));

			// Update document order and save
			if (this.selectedDocumentGroup) {
				this.selectedDocumentGroup.documents = [...this.selectedDocumentGroup.documents, ...res.documents.map(d => d.id)];
				this.loadDocumentTable(this.selectedDocumentGroup.documents);
				await this.selectedDocumentGroup.save();
				await this.product.save();
			} else {
				this.product.document_order = [...this.product.document_order, ...res.documents.map(d => d.id)];
				this.loadDocumentTable(this.product.document_order);
				await this.product.save();
			}

		}, angular.noop);

	}

	onDrop(srcList, srcIndex, targetList, targetIndex, doc) {

		// Copy the item from source to target.
		targetList.splice(targetIndex, 0, srcList[srcIndex]);

		// Remove the item from the source, possibly correcting the index first.
		// We must do this immediately, otherwise ng-repeat complains about duplicates.
		if (srcList === targetList && targetIndex <= srcIndex) {
			srcIndex++;
		}
		srcList.splice(srcIndex, 1);

		// Update document order
		if (this.selectedDocumentGroup) {
			this.selectedDocumentGroup.documents = this.documentOrder.map(d => d.id);
			this.selectedDocumentGroup.save();
		} else {
			this.product.document_order = this.documentOrder.map(d => d.id);
			this.product.save();
		}

		// By returning true from dnd-drop we signalize we already inserted the item.
		return true;
	}

	createDocumentGroup() {

		// Name must start with an alphanumeric character and must not contain special characters

		let modalInstance = this.$uibModal.open({
			animation: true,
			backdrop: 'static',
			component: 'createDocumentGroupModal',
			resolve: {
				context: () => ({
					product: this.product
				})
			}
		});

		modalInstance.result.then(async res => {

			// Concatenate new with existing documents
			this.documents = [...this.documents, res.documentGroup];

			// Concatenate new with current order
			this.documentOrder = [...this.documentOrder, res.documentGroup];

			// Update document order
			this.product.add('document_order', res.documentGroup.id);

			// Add to product documents relation
			let relation = this.product.relation('documents');
			relation.add(res.documentGroup);

			// Reload document order
			this.showMainDocumentOrder();

			// Update the product
			this.product.save();

		}, angular.noop);

	}

	moveDocumentToGroup(doc) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			backdrop: 'static',
			component: 'assignDocumentGroupModal',
			resolve: {
				context: () => ({
					documentGroups: this.documents.filter(d => d.isGroup),
					product: this.product,
					currentGroup: this.selectedDocumentGroup
				})
			}
		});

		modalInstance.result.then(async res => {

			// Find and remove document from the document order so it disappears from the visible list
			if (this.documentOrder.indexOf(doc) > -1) {
				this.documentOrder.splice(this.documentOrder.indexOf(doc), 1);
			}

			// Find and remove the document from the current order list
			if (this.selectedDocumentGroup) {
				this.selectedDocumentGroup.remove('documents', doc.id);
				this.selectedDocumentGroup.save();
			} else {
				this.product.remove('document_order', doc.id);
			}

			if (res.documentGroup) {

				// Add the document to the documents order on the group
				res.documentGroup.add('documents', doc.id);

				// Recompute the group size
				// res.documentGroup.size = res.documentGroup.documents.reduce((size, d) => size + d.size, 0);

				// Update the document group
				res.documentGroup.save();

			} else {

				// Add to the default group
				this.product.add('document_order', doc.id);

			}

			// Recompute document order
			if (this.selectedDocumentGroup) {
				this.loadDocumentTable(this.selectedDocumentGroup.documents);
			} else {
				this.loadDocumentTable(this.product.document_order);
			}

			// Update the product
			await this.product.save();

		}, angular.noop);

	}

	openDocumentGroup(group) {
		if (group.isGroup) {
			this.selectedDocumentGroup = group;
			this.loadDocumentTable(group._documents.map(doc => doc.id));
		}
	}

	showMainDocumentOrder() {
		this.selectedDocumentGroup = null;
		this.loadDocumentTable(this.product.document_order);
	}

	openSplitPdfModal(doc) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			backdrop: 'static',
			component: 'splitPdfModal',
			resolve: {
				context: () => ({
					document: doc
				})
			}
		});

		modalInstance.result.then(async res => {
			this.openLiveQuery(doc.id);
		}, angular.noop);

	}

	getDownloadLink(doc) {
		let url = doc.url.split('://').pop().split('/').pop();
		return `http://dl.${this.rootUrl}/${encodeURIComponent(url)}?filename=${encodeURIComponent(doc.original_name)}`;
	}

	openAddDocumentByUrlModal() {
		let modalInstance = this.$uibModal.open({
			animation: true,
			backdrop: 'static',
			component: 'addDocumentByUrlModal',
			resolve: {
				context: () => ({
					product: this.product
				})
			}
		});

		modalInstance.result.then(async ({ document }) => {
			// Concatenate new with existing documents
			this.documents = [...this.documents, document];
			this.showMainDocumentOrder();
			this.$timeout();
		}, angular.noop);
	}

}
