
/* injects from baggage-loader */

'use strict';

import ActivityFeedItemComponent from './activityfeeditem.component';
import './activityfeeditem.scss';

const ActivityFeedItemModule = angular.module('activity-feed-item-module', []);

ActivityFeedItemModule
    .component('activityFeedItem', new ActivityFeedItemComponent());

export default ActivityFeedItemModule;

