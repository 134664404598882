
/* injects from baggage-loader */

'use strict';

import AccountSettingsUsersController from './account.settings.users.controller';
import AccountSettingsUsersTemplate from './account.settings.users.html';

export default class AccountSettingsUsersComponent {
	constructor() {
		this.templateUrl = AccountSettingsUsersTemplate;
		this.controller = AccountSettingsUsersController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			users: '<',
			invitations: '<'
		};
	}
}
