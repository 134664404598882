
/* injects from baggage-loader */

'use strict';

import AccountComponent from './account.component';

// Child state modules
// import AccountDashboardModule from './dashboard/account.dashboard.module';
// import AccountActivityModule from './activity/account.activity.module';
import AccountSettingsModule from './settings/account.settings.module';
// import AccountDocumentRequestsModule from './document-requests/account.document-requests.module';
// import AccountDocumentRequestReviewModule from './document-request-review/account.document-request-review.module';

const AccountPageModule = angular.module('account-module', [
  'ui.router',

  // Child states
  // AccountDashboardModule.name,
  // AccountActivityModule.name,
  AccountSettingsModule.name,
  // AccountDocumentRequestsModule.name,
  // AccountDocumentRequestReviewModule.name

])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account', {
        name: 'Account',
        url: '/account',
        component: 'account',
        protected: true,
        abstract: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { },

        // parentNavBar: [
        //   {
        //     title: 'Dashboard',
        //     icon: 'fa-th-large',
        //     state: 'account.dashboard'
        //   },
        //   // {
        //   //   title: 'Activity',
        //   //   state: 'account.activity'
        //   // },
        //   // {
        //   //   title: 'QR Codes',
        //   //   state: 'account.codes'
        //   // },
        //   // {
        //   //   title: 'Document Requests',
        //   //   icon: 'fa-file-text',
        //   //   state: 'account.document-requests'
        //   // },
        //   {
        //     title: 'Settings',
        //     icon: 'fa-bars',
        //     state: 'account.settings.profile',
        //     active: 'account.settings.**'
        //   }
        // ]
      });
  })
  .component('account', new AccountComponent());

export default AccountPageModule;
