
/* injects from baggage-loader */

'use strict';

export default function (app) {
  app
    .factory('ListManager', listManagerFactory);

  function listManagerFactory() {
    return {

      lists: {},

      register: function(name, list) {
        this.lists[name] = list;
      }, 

      get: function(name) {
        return this.lists[name];
      }

    }
  }
}