
/* injects from baggage-loader */

'use strict';

import ProductFieldLogsController from './product.fieldlogs.controller';
import ProductFieldLogsTemplate from './product.fieldlogs.html';

export default class ProductFieldLogsComponent {
		constructor() {
				this.templateUrl = ProductFieldLogsTemplate;
				this.controller = ProductFieldLogsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					config: '<',
					product: '<',
					fieldLogs: '<',
					totalItemCount: '<',
					selectedFieldLog: '<'
				};
		}
}
