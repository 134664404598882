
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Code', function (Parse) {

		// Properties
		let schema = [
			'product',
		];

		let Code = Parse.Object.extend('Code', {
			// Instance methods

		}, {
				// Class methods

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(Code.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return Code;

	});
}

