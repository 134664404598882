
/* injects from baggage-loader */

'use strict';

export default class LoginController {

	constructor($log, $state, $location, $stateParams, $timeout, User, Navigation) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$location = $location;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.User = User;
		this.Navigation = Navigation;

		this.user = {};
		this.logging_in = false;

		this.show_password = false;
		this.password_type = "password";
		this.show_password_icon = "fa-eye";

	}

	$onInit() {

		if (this.$stateParams.email) {
			this.user.email = this.$stateParams.email;
		}

	}

	toggleShowPassword() {
		this.show_password = !this.show_password;
		if (this.show_password) {
			this.show_password_icon = "fa-eye-slash";
			this.password_type = "text";
		} else {
			this.show_password_icon = "fa-eye";
			this.password_type = "password";
		}
		this.$log.debug('Show password', this.show_password);
	}

	/**
		 * Attempts to authenticate the User and log them into the app
		 */
	login() {

		// Clear any error
		this.login_error = '';
		this.logging_in = true;

		// Show progress indicator

		this.User.authenticate(this.user)
			.then(user => {

				// this.$log.debug(user);
				if (this.Navigation.getIntendedPath()) {

					// Go to intended path post login
					this.$location.path(this.Navigation.getIntendedPath(true));

				} else {

					if (this.User.isSubAccount()) {
						const productId = this.User.current().get('product').id;
						this.$state.go('product.overview', { id: productId });
					} else {
						// Admin: Go to products manager
						this.$state.go('products');
					}

				}

			})
			.catch(err => {
				// Show login error
				this.login_error = err.message || err;
				this.logging_in = false;
				this.$timeout();
			});

	}

}
