
/* injects from baggage-loader */

'use strict';

export default class AddDocumentByUrlModalComponent {

	constructor($log, $timeout, ProductDocument) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.ProductDocument = ProductDocument;

		this.urlValidatorRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

		this.document = {
			label: '',
			url: '',
			// Documents added by URL are external URLs
			isExternalUrl: true,
			isCertificate: false
		};

		this.isSaving = false;
	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;
		this.product = this.context.product;

		this.document.product = this.product;

	}

	isValidUrl(string) {
		return string.match(this.urlValidatorRegex) === null ? false : true;
	}

	setDocumentCertification(value) {
		this.document.isCertificate = value;
	}

	ok() {

		this.error_msg = '';

		// Trim values
		['label', 'url'].forEach(key => {
			this.document[key] = this.document[key].trim();
		});

		if (!this.document.label) {
			this.error_msg = 'Please include a label for this document';
			return false;
		}

		if (!this.document.url) {
			this.error_msg = 'Please set a URL for this document';
			return false;
		}

		// Set protocol for document url if missing
		if (this.document.url.indexOf('http') !== 0) {
			this.document.url = `http://${this.document.url}`;
		}

		if (!this.isValidUrl(this.document.url)) {
			this.error_msg = 'Please enter a valid document URL';
			return false;
		}

		// Save the document
		this.isSaving = true;
		const document = new this.ProductDocument();
		document.save(this.document)
			.then(newDocument => {
				// Add relation to product documents
				const relation = this.product.relation('documents');
				relation.add(newDocument);

				// Add document ID to order
				this.product.document_order = [...this.product.document_order, newDocument.id];
				return this.product.save()
					.then(() => newDocument)
			})
			.then(newDocument => {
				// Close the modal returning the new document
				this.close({
					$value: {
						document: newDocument
					}
				});
			})
			.catch(err => {
				// Handle error
				this.$log.error(err);
				this.error_msg = 'Failed to add document';
				this.isSaving = false;
				this.$timeout();
			})
	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
