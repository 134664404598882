
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsProfileController {

  constructor($log, Alerts) {
    'ngInject';

    this.$log = $log;

    // Title displayed on the simple form template
    this.formTitle = 'Profile';

    // Assign alerts service
    this.alerts = Alerts;

  }

  $onInit() {

    // Create the profile editing form
    this.formTemplate = {

      model: this.user,

      // Form input groups
      // Currently only simple fields are supported
      // ex. text, email, number
      fields: [
        {
          type: 'text',
          label: 'Name',
          model: 'name',
          placeholder: 'Full name',
          autocomplete: 'name',
          describedby: 'fullName',
          autofocus: true
        },
        // {
        //   type: 'text',
        //   label: 'Company',
        //   model: 'company',
        //   placeholder: 'Company name',
        //   autocomplete: 'organization',
        //   describedby: 'companyName'
        // },
        {
          type: 'email',
          label: 'Email address',
          model: 'email',
          placeholder: 'Email address',
          autocomplete: 'email',
          describedby: 'emailAddress',
          disabled: true
        },
        {
          type: 'text',
          label: 'Role',
          model: 'company_role',
          placeholder: 'Ex. Brand manager',
          autocomplete: '',
          describedby: 'companyRole'
        },
      ],

      // Action button at the end of the form
      submit: {
        text: 'Save changes',
        action: () => {
          this.saveChanges();
        },
        disabled: () => !this.user.dirty()
      }

    };

  }

  $onDestroy() {

    // Clear edits
    this.user.revert();

  }

	/**
   * Saves changes to the user object
   */
  saveChanges() {

    // Clear pre-existing alerts
    this.alerts.clearLocal();

    // Save the user
    this.user.save()
      .then(_user => {
        this.alerts.setLocal({ type: 'success', msg: 'Profile updated successfully' });
      })
      .catch(err => {
        this.$log.error(err);
        this.alerts.setLocal({ type: 'danger', msg: 'Failed to update profile! Please contact support if the problem persists' });
      })
  }

}
