
/* injects from baggage-loader */

/*global NODE_ENV*/
'use strict';

function config($logProvider, $compileProvider, $urlRouterProvider, $locationProvider) {
	'ngInject';

	$logProvider.debugEnabled(true);

	if (NODE_ENV === 'production') {
		$logProvider.debugEnabled(false);
		$compileProvider.debugInfoEnabled(false);
	}

	// Default route
	$urlRouterProvider.otherwise('/companies');
	$locationProvider.html5Mode(true);

}

export default config;