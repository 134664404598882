
/* injects from baggage-loader */

'use strict';

export default class ActionsAcceptInviteController {

	constructor($log, $state, $timeout, Parse, User) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$timeout = $timeout;
		this.Parse = Parse;
		this.User = User;

		this.creating_account = false;
		this.signup_error = null;

		this.user = {};

		this.inviteMethod = "acceptAccountInvite";

	}

	$onInit() {
		this.user.email = this.invitation.email;

		if (this.invitation.get('isSubAccount')) {
			this.inviteMethod = "acceptProductInvite";
		}

		// Logout any current user
		if (this.User.current()) {
			this.Parse.User.logOut();
		}

	}

	isUserAuthenticated() {
		return this.User.isUserLoggedIn();
	}

	async signup() {

		this.signup_error = null;

		if (!this.user.email) {
			this.signup_error = 'Missing "email"';
			return;
		}
		if (!this.user.name) {
			this.signup_error = 'Missing "name"';
			return;
		}
		if (!this.user.password || !this.user.password_verify) {
			this.signup_error = 'Missing "password"';
			return;
		}
		if (this.user.password !== this.user.password_verify) {
			this.signup_error = 'The passwords do not match';
			return;
		}

		this.creating_account = true;

		try {

			// Accept account invitation and create user
			const newUser = await this.Parse.Cloud.run(this.inviteMethod, {
				user: this.user,
				invitationId: this.invitation.id
			});

			await this.User.authenticate({
				email: this.user.email,
				password: this.user.password
			});

			const productId = this.User.current().get('product').id;
			console.log('id', productId);

			if (this.invitation.get('isSubAccount')) {
				// Go to "product" page
				this.$state.go('product.overview', { id: productId });
			} else {
				// Admin, Go to products page
				this.$state.go('products');
			}

		} catch (err) {
			this.creating_account = false;
			this.signup_error = err.toString().replace('ParseError: 141', '');
			this.$timeout();
		}

	}

}
