
/* injects from baggage-loader */

'use strict';

import DynamicFormController from './dynamicform.controller';
import DynamicFormTemplate from './dynamicform.html';

export default class DynamicFormComponent {
	constructor() {
		this.templateUrl = DynamicFormTemplate;
		this.controller = DynamicFormController;
		this.controllerAs = '$dform';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			ngModel: '<',
			template: '<'
		};
	}
}
