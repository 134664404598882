
/* injects from baggage-loader */

'use strict';

import AccountSettingsNotificationsComponent from './account.settings.notifications.component';

const AccountSettingsNotificationsPageModule = angular.module('account-settings-notifications-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.notifications', {
        url: '/notifications',
        component: 'accountSettingsNotifications',
        data: {
          title: 'My Account Settings: Notifications',
          navTitle: 'Notifications'
        },
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          documentRequestUsers: function(User) {
            /**
             * @param {Parse.Query} query - Original query with default constraigns
             * @param {Function} getNewQuery - Function that returns of new instance of a query for this class
             * @param {Parse} P - The Parse class
             */
            return User.fetchAuthorized(null, (query, getNewQuery, P) => {
              return P.Query.and(
                query,
                P.Query.or(
                  getNewQuery().equalTo('sendDocumentRequestEmails', true),
                  getNewQuery().equalTo('sendFieldLogEmails', true)
                )
              );
            });
          }
        }
      });
  })
  .component('accountSettingsNotifications', new AccountSettingsNotificationsComponent());

export default AccountSettingsNotificationsPageModule;
