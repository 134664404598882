
/* injects from baggage-loader */

'use strict';

export default class SplitPdfModalController {

	constructor($log, $http, $timeout, User, ROOT_URL) {
		'ngInject';

		this.$log = $log;
		this.$http = $http;
		this.$timeout = $timeout;
		this.User = User;
		this.rootUrl = ROOT_URL;

		this.error_msg = '';

	}

	$onInit() {
		this.document = this.resolve.context.document;
	}

	async save() {

		// Setup "task" on document
		this.document.task = {
			name: "pdf-split-bookmarks",
			msg: "Processing",
			active: true,
			startedAt: new Date().toISOString()
		};

		// Save the document
		await this.document.save();

		let req = {
			method: 'POST',
			url: `https://services.${this.rootUrl}/pdf/${this.document.id}/splitbookmark`,
			headers: {
				'X-Parse-Session-Token': this.User.getSessionToken()
			},
			data: {}
		}

		// Call to the service API
		this.$http(req).then(res => {
			this.close({
				$value: {}
			});
		}, err => {
			this.$log.error(err)
			this.error_msg = 'Failed to initiate call to PDF processing service. Please contact support if this issue continues.'
			// Revert document updates
			this.document.unset('task')
			this.document.save();
			this.$timeout();

		});

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
