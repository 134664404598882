
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Validations', function () {

		let emailReg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
		let urlReg = /((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i;
		
		this.isEmail = string => emailReg.test(string);

		this.isUrl = string => urlReg.test(string);

	});
}

