
/* injects from baggage-loader */

'use strict';

import DocumentUploadModalComponent from './documentuploadmodal.component';
import './documentuploadmodal.scss';

const DocumentUploadModalModule = angular.module('document-upload-modal-module', []);

DocumentUploadModalModule
    .component('documentUploadModal', new DocumentUploadModalComponent());

export default DocumentUploadModalModule;

