
/* injects from baggage-loader */

'use strict';

export default class ProductGroupCreatorController {

	constructor($log, $state, $timeout, $document, ProductGroup, Alerts) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$timeout = $timeout;
		this.$document = $document;
		this.accessGroup = new ProductGroup();
		this.alerts = Alerts;

		this.creatingGroup = false;
		this.errorMsg = '';

		// Create the product form
		this.formTemplate = {

			model: this.accessGroup,

			// Form input groups
			fields: [
				{
					type: 'text',
					label: 'Name',
					model: 'name',
					placeholder: 'Enter a name',
					autocomplete: '',
					describedby: 'productGroupName',
					autofocus: true
				},
				{
					type: 'textarea',
					label: 'Description',
					model: 'description',
					placeholder: 'Enter a description',
					autocomplete: '',
					describedby: 'productGroupDescription'
				}
			]

		};

	}

	$onInit() {

	}

	setProductScope(scope) {
		this.accessGroup.scope = scope;
	}

	setSecurity(security) {
		this.accessGroup.security = security;
	}

	allPublic() {
		return this.accessGroup.security === 'public' && this.accessGroup.scope === 'all';
	}

	formValid() {
		return this.accessGroup.security &&
			this.accessGroup.scope &&
			this.accessGroup.name
	}

	async createGroup() {
		this.$log.debug(this.accessGroup)
		this.creatingGroup = true;
		try {
			await this.accessGroup.save();
			this.$state.go('product-groups');
		} catch (err) {
			// this.$log.error(err);
			this.alerts.setLocal({ type: 'danger', msg: err.toString().replace('ParseError: 141', '').trim() });
			this.$document.scrollTopAnimated(0, 500)
			this.creatingGroup = false;
			this.$timeout();
		}
	}

}
