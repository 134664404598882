
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('QueryOverride', function (Parse) {
		return function(_class, originalQuery, overrideFn) {
			if (typeof overrideFn === 'function') {
				const override = overrideFn(originalQuery, () => new Parse.Query(_class), Parse);
				return override || originalQuery;
			}
			return originalQuery;
		}
	});
}

