
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsNotificationsController {

	constructor($log, $timeout, $uibModal, User, Parse) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.modalService = $uibModal;
		this.User = User;
		this.Parse = Parse;

	}

	$onInit() {

	}

	async loadDocumentRequestUsers() {
		this.documentRequestUsers = await this.User.fetchAuthorized({
			equalTo: {
				key: 'sendDocumentRequestEmails',
				value: true
			}
		});
		this.$timeout();
	}

	openAddDocumentRequestUserModal() {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'selectUserModal',
			resolve: {
				context: () => ({
					message: 'Select users that should recieve an email when someone requests access to a protected document.',
					query: {
						notEqualTo: {
							key: 'sendDocumentRequestEmails',
							value: true
						}
					}
				})
			}
		});

		modalInstance.result.then(async res => {

			let selectedUsers = res.selected;
			this.$log.debug(selectedUsers)

			for (let user of selectedUsers) {
				user.set('sendDocumentRequestEmails', true)
			}

			await this.Parse.Object.saveAll(selectedUsers);

			// Reload list
			this.loadDocumentRequestUsers();
			// this.documentRequestUsers = [...this.documentRequestUsers, ...selectedUsers];

		}, angular.noop);

	}

	openRemoveDocumentRequestUserModal(user) {

		if (this.documentRequestUsers.length < 2) {
			alert('Cannot remove user! Must always have at least 1 user recieving these emails.');
			return false;
		}

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: () => ({
					title: 'Remove user notifications?',
					body: 'Are you sure you want to remove this user from email notifications for "Document Requests"?',
					list: [user.username || user.email],
					confirmBtnText: 'Remove',
					cancelBtnText: 'Cancel',
					confirmBtnClass: 'btn-danger'
				})
			}
		});

		modalInstance.result.then(async () => {
			user.removing = true;
			await user.save({
				sendDocumentRequestEmails: false
			})
			// Reload list
			this.loadDocumentRequestUsers();
		}, angular.noop);


	}

}
