
/* injects from baggage-loader */

'use strict';

export default class ProductOverviewController {

	constructor($log, $timeout, $uibModal, Activity, ActivityFeed, Product, User, ROOT_URL) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.Activity = Activity;
		this.ActivityFeed = ActivityFeed;
		this.Product = Product;
		this.User = User;
		this.modalService = $uibModal;
		this.rootUrl = ROOT_URL;

		this.events = [];
		this.activityFeed = [];
		this.loadingEvents = false;
		this.hasMoreEvents = false;

		this.scanCount = '';
		this.viewCount = '';
		this.downloadCount = '';

	}

	async $onInit() {

		// Set product "online" status
		this.product.online = this.product.status === 'active' ? true : false;

		// Should display the "load more" events button
		this.hasMoreEvents = this.events.length > 9;

		// Parse activity events and fill activity feed
		this.activityFeed = this.events.map(event => this.ActivityFeed.parseEvent(event));

		// Setup product image preview if existing project
		if (typeof this.product.image !== 'undefined') {
			this.productImage = true;
			this.productImagePreview = this.product.image.url();
		}

		// Load scan count
		if (this.product.get('code')) {
			let code = await this.product.get('code').fetch();
			let codeStats = await code.get('stats').fetch();
			this.scanCount = (codeStats.get('scans') || 0).toString();
		} else {
			this.scanCount = "0";
		}

		// Load view count
		if (this.product.get('stats')) {
			let productStats = await this.product.get('stats').fetch();
			this.viewCount = (productStats.get('views') || 0).toString();
		} else {
			this.viewCount = "0";
		}

		let product = new this.Product({ id: this.product.id });

		// Download downloads count
		this.downloadCount = (await this.Activity.count({
			equalTo: [{
				key: 'product', value: product.toPointer()
			}, {
				key: 'type', value: 'document-dl'
			}]
		})).toString();


		// Refresh view
		this.$timeout();

	}

	previewURL() {
		return `https://scan.${this.rootUrl}/company/${this.product.id}`;
	}

	getPermalink() {
		return `https://scan.${this.rootUrl}/company/${this.product.id}`;
	}

	getIsSubAccount() {
		return this.User.isSubAccount();
	}

	toggleProductStatus($event) {
		$event.preventDefault();

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: () => ({
					title: 'Toggle Product State',
					body: this.product.online ?
						'Are you sure you want to take this product offline? Doing so will make it unavailable to users who scan the QR code or follow the link to this product. This decision can be reversed any time in the future to bring this product back online.'
						: 'Are you sure you want to bring this product online? Doing so will make it available to users who scan the QR code or follow the link to this product.',
					confirmBtnText: this.product.online ? 'Take Offline' : 'Bring Online',
					cancelBtnText: 'Cancel',
					confirmBtnClass: this.product.online ? 'btn-danger' : 'btn-success'
				})
			}
		});

		modalInstance.result.then(async () => {
			try {
				// Save product
				await this.product.save({
					status: this.product.online ? 'offline' : 'active'
				});
				// Update "online" state
				this.product.online = this.product.status === 'active' ? true : false;
				this.$timeout(() => { }, 500);
			} catch (err) {
				// Display error alert
				this.alerts.setLocal({
					type: 'danger',
					msg: err.toString().replace('ParseError: 141', '').trim()
				});
				this.$timeout();
			}
		}, angular.noop);

	}


	/**
	 * Fetch some more events and append the "activityFeed" array
	 */
	loadMoreEvents() {
		this.loadingEvents = true;
		this.Activity.fetchSome({
			skip: this.activityFeed.length
		}).then(events => {
			this.hasMoreEvents = events.length ? true : false;
			this.loadingEvents = false;
			this.activityFeed = [...this.activityFeed, ...events.map(event => this.ActivityFeed.parseEvent(event))];
		});
	}


	getProductScanCount() {
		// let code = await this.product.get('code').fetch();
		// this.$log.debug(code);
		// let stats = await code.get('stats').fetch();
		// this.$log.debug(stats);
		// return stats.get('scans');
	}

	getProductViewCount() {

	}

}
