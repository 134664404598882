
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsController {

	constructor($log, $state) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;

		this.openMobileMenu = false;
		this.routes = [
			{
				title: 'Profile',
				state: 'account.settings.profile'
			},
			// {
			// 	title: 'Company',
			// 	state: 'account.settings.company'
			// },
			{
				title: 'Password',
				state: 'account.settings.password'
			},
			// {
			// 	title: 'Security',
			// 	state: 'account.settings.security'
			// },
			{
				title: 'Users',
				state: 'account.settings.users'
			},
			// {
			// 	title: 'Notifications',
			// 	state: 'account.settings.notifications'
			// }
		];

	}

	$onInit() {

	}

	selectMobileSettings(state) {
		this.openMobileMenu = false;
		this.$state.go(state);
	}

}
