
/* injects from baggage-loader */

'use strict';

export default class ProductGroupOverviewController {

	constructor($log, $uibModal, $timeout, $document, Parse, Product, AccountInvitation, Alerts, ROOT_URL) {
		'ngInject';

		this.$log = $log;
		this.modalService = $uibModal;
		this.$timeout = $timeout;
		this.$document = $document;
		this.Parse = Parse;
		this.productService = Product;
		this.AccountInvitation = AccountInvitation;
		this.alerts = Alerts;
		this.rootUrl = ROOT_URL;

	}

	$onInit() {
		this.$log.debug(this.group);
		this.$log.debug(this.users);
	}

	getScope() {
		return this.group.scope === 'all' ? 'All Products' : 'Limited';
	}

	isPublic() {
		return this.group.security === 'public' || this.group.security === 'open';
	}

	hasFullProductAccess() {
		return this.group.scope === 'all';
	}

	getPermalink() {
		let route = this.isPublic() ? 'group' : 'securegroup';
		return `https://scan.${this.rootUrl}/${route}/${this.group.id}`;
	}

	openButtonCodeModal() {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'generateButtonCodeModal',
			resolve: {
				link: () => this.getPermalink()
			}
		});
		modalInstance.result.then(() => angular.noop, () => angular.noop);
	}

	async openProductManagerModal() {

		this.loadingProductsSelector = true;
		let products = await this.productService.query({ limit: 1000 });
		this.loadingProductsSelector = false;

		// Set selected products
		for (let product of products) {
			let selectedProduct = this.products.find(p => p.id === product.id);
			if (selectedProduct) product.selected = true;
		}

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'productSelectorModal',
			size: 'lg',
			resolve: {
				group: () => this.group,
				products: () => products
			}
		});

		modalInstance.result.then(async result => {

			// let productsRelation = this.group.relation('products');

			// // Remove all products from "products" relation
			// productsRelation.remove(products.filter(p => {
			// 	let found = result.selected.find(s => s.id === p.id);
			// 	return found ? false : true;
			// }));

			// // Add selected products to relation
			// if (result.selected && result.selected.length) {
			// 	productsRelation.add(result.selected)
			// }

			// await this.group.save();
			this.products = result.selected;
			this.$timeout();

		}, angular.noop);

	}

	async loadUsers() {
		let users = this.group.relation('users').query().find();
		let invitations = this.AccountInvitation.findPending({ group: this.group });
		this.users = [...await users, ...await invitations];
		this.$timeout();
	}

	async openInviteUserModal() {

		let modalInstance = this.modalService.open({
			animation: true,
			backdrop: 'static',
			component: 'inviteUserModal',
			resolve: {
				context: () => ({
					roles: this.roles,
					group: this.group
				})
			}
		});

		modalInstance.result.then(async res => {

			// Reload invitations
			this.loadUsers()

			this.alerts.setLocal({ type: 'success', msg: `User invited: ${res.user.email}` });
			this.$document.scrollTopAnimated(0, 500)

		}, angular.noop);

	}

	async revokeInvitation(invitation) {

		// Delete invitation
		await invitation.destroy();

		// Reload invitations
		this.loadUsers()

		this.alerts.setLocal({ type: 'success', msg: 'Invitation revoked' });
		this.$document.scrollTopAnimated(0, 250);
		this.$timeout();

	}

	removeUser(user) {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: () => ({
					title: 'Remove user?',
					body: 'Are you sure you want to remove this user from this group? This user will no longer have access after you remove them.',
					list: [user.username || user.email],
					confirmBtnText: 'Remove',
					cancelBtnText: 'Cancel',
					confirmBtnClass: 'btn-danger'
				})
			}
		});

		modalInstance.result.then(async () => {
			user.removing = true;
			this.$timeout();
			await this.Parse.Cloud.run('removeGroupUser', { userId: user.id, groupId: this.group.id });
			this.loadUsers();
			this.alerts.setLocal({ type: 'success', msg: 'User removed successfully' });
		}, angular.noop);

	}

}
