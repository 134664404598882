
/* injects from baggage-loader */

'use strict';

import ProductActivityController from './product.activity.controller';
import ProductActivityTemplate from './product.activity.html';

export default class ProductActivityComponent {
		constructor() {
				this.templateUrl = ProductActivityTemplate;
				this.controller = ProductActivityController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					product: '<',
					documents: '<',
					events: '<',
					eventsTotal: '<'
				};
		}
}
