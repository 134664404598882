
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProductServiceUpdateDocument', function (Parse) {

		// Properties
		let schema = [
			'label',
			'original_name',
			'size',
			'type',
			'url',
		];

		let ProductServiceUpdateDocument = Parse.Object.extend('ProductServiceUpdateDocument', {
			// Instance methods

		}, {
			// Class methods

			/**
			 * Retrieve a productServiceUpdateDocument by ID
			 */
			get(id, options = {}) {
				let query = new Parse.Query(this);
				if (typeof options.include !== 'undefined') {
					query.include(options.include);
				}
				return query.get(id);
			},
			

			fetchSome(options = {}) {
				let query = new Parse.Query(this);
				query.descending('createdAt');
				query.limit(10);
				if (options.limit) {
					query.limit(options.limit);
				}
				if (options.skip) {
					query.skip(options.skip);
				}
				if (options.equalTo) {
					for (let constraint of options.equalTo) {
						query.equalTo(constraint.key, constraint.value);
					}
				}
				return query.find();
			},


			/**
			 * Count number of matched objects
			 */
			count(options = {}) {
				let query = new Parse.Query(this);
				if (options.equalTo) {
					for (let constraint of options.equalTo) {
						query.equalTo(constraint.key, constraint.value);
					}
				}
				return query.count();
			},

		});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(ProductServiceUpdateDocument.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProductServiceUpdateDocument;

	});
}

