
/* injects from baggage-loader */

'use strict';

export default class BulkServiceUpdateModalController {

	constructor($log, $timeout, Parse, Product, User, Upload, ProductServiceUpdateDocument, FILE_UPLOAD_URL) {
		'ngInject';

		this.FILE_UPLOAD_URL = FILE_UPLOAD_URL;
		this.$log = $log;
		this.$timeout = $timeout;
		this.Parse = Parse;
		this.productService = Product;
		this.serviceUpdate = {};
		this.existingServiceUpdate;
		this.Upload = Upload;
		this.User = User;
		this.ProductServiceUpdateDocument = ProductServiceUpdateDocument;
		this.step2 = false;
		this.error_msg = '';
		this.savingUpdate = false;
		this.loadingAttachment = false;
		this.showUploadControl = false;

		this.attachment;
		this.invalidAttachment;
		this.existingAttachment;
		this.removeAttachments = [];

		this.dateOpts = {
			altInput: true,
			altFormat: "F j, Y",
			dateFormat: "Z"
		};

	}

	async $onInit() {

		let ServiceUpdate = this.Parse.Object.extend('ProductServiceUpdates');
		this.context = this.resolve.context;

		this.loading = true;
		this.products = await this.productService.query({ limit: 1000, select: ['name', 'model_num'] });

		if (this.context.serviceUpdate) {

			// Updating an existing service update
			this.serviceUpdate = this.context.serviceUpdate.toJSON();
			this.existingServiceUpdate = this.context.serviceUpdate;
			this.$log.debug(this.existingServiceUpdate)
			if (this.existingServiceUpdate.get('document')) {
				this.existingAttachment = await this.existingServiceUpdate.get('document').fetch();
			} else {
				this.showUploadControl = true;
			}

			// Filter keys needed to edit
			this.serviceUpdate = Object.keys(this.serviceUpdate).reduce((obj, key) => {
				if (['issuedAt', 'text', 'name'].includes(key)) {
					obj[key] = this.serviceUpdate[key];
				}
				return obj;
			}, {});

			// Set selected products
			// let _products = await this.existingServiceUpdate.relation('products').query().select(['name', 'model_num']).find();
			let _products = await (new this.Parse.Query('Product'))
				.equalTo('service_updates', this.existingServiceUpdate)
				.select(['name', 'model_num']).find();

			for (let product of this.products) {
				let selectedProduct = _products.find(p => p.id === product.id);
				if (selectedProduct) {
					product.selected = true;
					product.wasSelected = true;
				}
			}

		} else {

			// Creating a new service update
			this.serviceUpdate = new ServiceUpdate({
				bulk: true
			});
			this.showUploadControl = true;

		}

		// Done loading
		this.loading = false;
		this.$timeout();

	}

	/**
	 * Called when flatpicker is initialized, sets up the date according to ng-model
	 * @param {Object} fpItem 
	 * @param {Object} options 
	 */
	datePostSetup(fpItem, options = {}) {
		if (typeof options.date === 'object') {
			fpItem.setDate(options.date.iso);
		} else {
			fpItem.setDate(options.date);
		}
	}

	handleAttachment() {
		if (this.attachment) {
			this.$log.debug(this.attachment)
		} else {
			this.$log.error('Invalid file', this.invalidAttachment)
			this.error_msg = 'Invalid file';
			return false;
		}
	}

	removeAttachment() {
		this.removeAttachments.push(this.existingAttachment);
		this.existingAttachment = null;
		this.existingServiceUpdate.unset('document');
		this.showUploadControl = true;
	}

	async processAttachment() {

		this.error_msg = '';

		if (this.attachment) {

			this.attachment.startedUploading = true;
			this.$timeout();

			try {

				// Get a file
				let file = this.attachment;

				// Sanitize the filename
				let filename = file.name.split('.');
				let ext = filename.pop();
				filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
				filename = `${filename}.${ext}`;

				// Create a new "Document"
				let document = new this.ProductServiceUpdateDocument({
					label: filename,
					original_name: file.name,
					size: file.size,
					type: file.type
				});
				await document.save();

				this.attachment.uploaded = false;
				this.attachment.uploading = true;
				this.attachment.animateUpload = false;
				this.attachment.progressPercentage = 0;

				return this.uploadFile(file, document);

			} catch (err) {
				this.$log.error(err)
				this.error_msg = err.toString().replace('ParseError: 141', '').trim();
				this.$timeout();
				return false;
			}

		}

	}

	uploadFile(file, document) {
		this.$log.debug('Upload file', file)
		let update = this.existingServiceUpdate || this.serviceUpdate;
		return this.Upload.upload({
			url: this.FILE_UPLOAD_URL,
			data: {
				files: [file],
				type: 'serviceDocument',
				documentId: document.id,
				serviceUpdateId: update.id
			},
			headers: {
				'X-Parse-Session-Token': this.User.getSessionToken()
			}
		}).then(async resp => {
			this.$log.debug('Success ' + resp.config.data.files[0].name + 'uploaded. Response: ' + resp.data);
			// Uploading complete
			this.attachment.uploading = false;
			this.attachment.uploaded = true;
			this.attachment.animateUpload = false;
			this.$timeout();
			return true;
		}, error => {
			this.$log.debug('Error status: ' + error.status);
			this.error_msg = 'An error occured uploading the attachment';
			this.attachment.progressPercentage = 100;
			this.attachment.animateUpload = false;
			this.$timeout();
			throw this.error_msg;
		}, evt => {
			if (evt.config.data.files) {
				let progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				this.attachment.progressPercentage = progressPercentage;
				if (progressPercentage === 100) {
					this.attachment.uploading = false;
					this.attachment.animateUpload = true;
					this.$timeout()
				}
			}
		});
	}

	validateForm() {
		if (!this.serviceUpdate.name) {
			this.error_msg = 'Missing "Name"'
			return false;
		}
		if (!this.serviceUpdate.issuedAt) {
			this.error_msg = 'Missing "Date Issued"'
			return false;
		}

		if (!this.serviceUpdate.text) {
			this.error_msg = 'Missing "Description"'
			return false;
		}
		this.error_msg = '';
		return true;
	}

	next() {

		if (!this.validateForm()) {
			return false;
		}

		this.step2 = true;
	}

	back() {
		this.step2 = false;
	}

	async ok() {

		this.savingSelection = true;

		if (!this.validateForm()) {
			return false;
		}

		this.savingUpdate = true;

		// Update existing service update keys
		if (this.existingServiceUpdate) {
			if (typeof this.serviceUpdate.issuedAt === 'object') {
				this.serviceUpdate.issuedAt = this.serviceUpdate.issuedAt.iso;
			}
			this.existingServiceUpdate.set('issuedAt', new Date(this.serviceUpdate.issuedAt));
			this.existingServiceUpdate.set('text', this.serviceUpdate.text);
			this.existingServiceUpdate.set('name', this.serviceUpdate.name);
		} else {
			this.serviceUpdate.set('issuedAt', new Date(this.serviceUpdate.issuedAt));
			// this.serviceUpdate.set('text', this.serviceUpdate.text);
			// this.serviceUpdate.set('name', this.serviceUpdate.name);
		}

		let update = this.existingServiceUpdate || this.serviceUpdate;
		let selectedProducts = this.products.filter(p => p.selected);
		// let productsRelation = update.relation('products');

		// Find products that were previously selected and no longer are
		let productsToRemove = this.products.filter(p => !p.selected && p.wasSelected);

		// Remove all products from "products" relation
		// productsRelation.remove(productsToRemove);

		// Add selected products to relation
		// if (selectedProducts.length) {
		// 	productsRelation.add(selectedProducts)
		// }

		// Set product count
		update.set('productCount', selectedProducts.length);

		try {

			// Save the Service Update
			let _serviceUpdate = await update.save();
			this.serviceUpdate = _serviceUpdate;

			// Upload Service Document attachment
			await this.processAttachment();

			// Remove old attachments
			for (let attachment of this.removeAttachments) {
				if (!attachment.isNew()) {
					await attachment.destroy();
				}
			}

			// Remove service update from products
			if (productsToRemove.length) {
				for (let p of productsToRemove) {
					p.relation('service_updates').remove(_serviceUpdate)
				}
				await this.Parse.Object.saveAll(productsToRemove)
			}

			// Add service update to products
			if (selectedProducts.length) {
				for (let p of selectedProducts) {
					p.relation('service_updates').add(_serviceUpdate)
				}
				await this.Parse.Object.saveAll(selectedProducts)
			}

			// Fetch the updated service update object
			await _serviceUpdate.fetch();

		} catch (err) {
			this.$log.error(err)
			return false;
		}

		this.close({
			$value: {
				isNew: this.existingServiceUpdate ? false : true,
				serviceUpdate: update
			}
		});

	}

	cancel() {
		if (this.existingServiceUpdate) {
			this.existingServiceUpdate.revert()
		}
		this.dismiss({ $value: 'cancel' });
	}
}
