
/* injects from baggage-loader */

'use strict';

import AddServiceUpdateModalComponent from './addserviceupdatemodal.component';
import './addserviceupdatemodal.scss';

const AddServiceUpdateModalModule = angular.module('add-service-update-modal-module', []);

AddServiceUpdateModalModule
    .component('addServiceUpdateModal', new AddServiceUpdateModalComponent());

export default AddServiceUpdateModalModule;

