
/* injects from baggage-loader */

'use strict';

import DynamicFormComponent from './dynamicform.component';
import './dynamicform.scss';

const DynamicFormModule = angular.module('dynamic-form-module', []);

DynamicFormModule
    .component('dynamicForm', new DynamicFormComponent());

export default DynamicFormModule;

