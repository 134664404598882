
/* injects from baggage-loader */

'use strict';

import ProductCreatorInfoComponent from './product-creator.info.component';

const ProductCreatorInfoPageModule = angular.module('product-creator-info-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product-creator.info', {
        url: '/info',
        component: 'productCreatorInfo'
      });
  })
  .component('productCreatorInfo', new ProductCreatorInfoComponent());

export default ProductCreatorInfoPageModule;
