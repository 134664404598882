
/* injects from baggage-loader */

'use strict';

import ProductCreatorServiceUpdatesController from './product-creator.service-updates.controller';
import ProductCreatorServiceUpdatesTemplate from './product-creator.service-updates.html';

export default class ProductCreatorServiceUpdatesComponent {
		constructor() {
				this.templateUrl = ProductCreatorServiceUpdatesTemplate;
				this.controller = ProductCreatorServiceUpdatesController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					product: '<'
				};
		}
}
