
/* injects from baggage-loader */

'use strict';

import AccountSettingsCompanyComponent from './account.settings.company.component';

const AccountSettingsCompanyPageModule = angular.module('account-settings-company-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.company', {
        data: {
          navTitle: 'Company',
          title: 'My Account Settings: Company'
        },
        url: '/company',
        component: 'accountSettingsCompany',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          // NOTE: Include "Company" service here to allow property getter/setters to populate
          company: function(User, Company) {
            let company = User.current().get('company');
            return Company.get(company.id);
          }
        }
      });
  })
  .component('accountSettingsCompany', new AccountSettingsCompanyComponent());

export default AccountSettingsCompanyPageModule;
