
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('LoadingCover', function ($log, $q, $timeout, LoadingCoverInstances) {

		/**
		 * Register a new instance of the LoadingCover directive
		 * @param {String} id 
		 * @param {Object} options 
		 * @param {Boolean} options.fullscreen
		 */
		this.registerInstance = function (id, options) {

			// Set default style options
			options.options = options.options || {};

			if (!options.options.ring_primary_color) {
				options.options.ring_primary_color = '#f5f5f5';
			}

			LoadingCoverInstances[id] = {
				id: id,
				isVisible: options.visible !== 'true' ? false : true,
				isClosing: false,
				message: options.message || '',
				// position: options.fullscreen !== 'false' ? 'position-fixed' : 'position-absolute',
				position_fixed: options.fullscreen === 'true',
				complete: false,
				options: options.options || {}
			};
			return LoadingCoverInstances[id];
		};


		/**
		 * Retrieve instance of LoadingCover directive
		 * @param {String} id 
		 */
		this.getInstance = function (id) {
			return {
				id: id,
				waitForInstance: function (name) {
					let d = $q.defer();
					if (LoadingCoverInstances[name]) {
						d.resolve(true);
					} {
						$timeout(() => {
							d.resolve(this.waitForInstance(name));
						}, 100);
					}
					return d.promise;
				},
				isVisible: function() {
					return LoadingCoverInstances[this.id].isVisible;
				},
				show: async function(message) {

					// Wait for instance to register
					await this.waitForInstance(this.id);

					if (LoadingCoverInstances[this.id]) {
						LoadingCoverInstances[this.id].isVisible = true;
						LoadingCoverInstances[this.id].isClosing = false;
						LoadingCoverInstances[this.id].message = message;
					} else {
						$log.debug('instance not found:', this.id);
					}
				},
				hide: async function () {
					let def = $q.defer();

					// Wait for instance to register
					let ready = await this.waitForInstance(this.id);

					if (ready) {
						LoadingCoverInstances[this.id].isClosing = true;
						$timeout(() => {
							LoadingCoverInstances[this.id].isVisible = false;
							LoadingCoverInstances[this.id].isClosing = false;
							LoadingCoverInstances[this.id].message = '';
							def.resolve();
						}, 250);
					} else {
						def.reject('Loader instance not found: ' + this.id);
					}

					return def.promise;
				},
				updateMessage: function (message) {
					if (LoadingCoverInstances[this.id]) {
						if (LoadingCoverInstances[this.id].message === message) {
							return false;
						}
						LoadingCoverInstances[this.id].message = message;
						$timeout(() => { /* empty */ }, 10);
					}
				},
				complete: async function(message) {

					let d = $q.defer();

					// Wait for instance to register
					await this.waitForInstance(this.id);

					// Update the message
					this.updateMessage(message);

					// Update complete to show checkmark animation
					LoadingCoverInstances[this.id].complete = true;
					$timeout(() => { /* empty */ }, 10);

					// Wait for animation to finish before closing
					$timeout(() => {
						this.hide().then(d.resolve)
					}, 1000);

					return d.promise;

				}
			};
		};

	});
}

