
/* injects from baggage-loader */

'use strict';

export default class ProductSelectorModalController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		this.savingSelection = false;

	}

	$onInit() {
		this.products = this.resolve.products;
		this.group = this.resolve.group;
		this.$log.debug(this.products)
	}

	save() {

		this.savingSelection = true;
		let selected = this.products.filter(p => p.selected);

		let productsRelation = this.group.relation('products');

		// Remove all products from "products" relation
		productsRelation.remove(this.products.filter(p => {
			let found = selected.find(s => s.id === p.id);
			return found ? false : true;
		}));

		// Add selected products to relation
		if (selected.length) {
			productsRelation.add(selected)
		}

		this.group.save().then(() => {
			this.close({
				$value: {
					selected
				}
			});
		});

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
