
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ErrorHandler', function ($log, $state, Parse, Alerts, User) {
		return function (error, state) {

			// Handle transition redirect to single product page for "subaccount"
			if (error.subAccountRedirect) {
				const productId = User.current().get('product').id;

				return $state.go('product.overview', { id: productId });
			}

			// Handle invalid Parse session token
			if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
				/*
					Problem: The users session has expired or become invalid
					Solution: Log out the user and return them to the login page
				*/

				// Add alert to what happened to global alerts
				Alerts.add({
					type: 'info',
					msg: 'Your current session expired, please log in to continue working.',
					timeout: 60000
				});

				// Log the user out and return to the login page
				Parse.User.logOut();
				$state.go('login');

			} else if (error.code === Parse.Error.OBJECT_NOT_FOUND) {

				if (state.name === 'product') {

					// Add alert to what happened to global alerts
					Alerts.add({
						type: 'danger',
						msg: 'Product not found'
					});

					// Return to the products page
					$state.go('products');

				} else if (state.name === 'account.document-request-review') {

					// Add alert to what happened to global alerts
					Alerts.add({
						type: 'danger',
						msg: 'Document request not found'
					});

					// Return to the document requests page
					$state.go('account.document-requests', {}, {
						// Set "notify" false so the global alert sticks routing to a child state
						notify: false
					});

				} else {

					// Return to the account dashboard
					$state.go('products');
					
				}

			} else if (error.code === 100) {

				// The UI is unable to connect to the parse API

				// TODO: handle appropriatly

			}

		}
	});
}

