
/* injects from baggage-loader */

'use strict';

import ProductUsersComponent from './product.users.component';

const ProductUsersPageModule = angular.module('product-users-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product.users', {
        data: {
          title: 'Users'
        }, 
        url: '/users',
        component: 'productUsers',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          users: function(User, company) {
            return User.fetchAuthorized({
              company_id: company.get('company_id')
            });
          },
          invitations: function(AccountInvitation) {
            return AccountInvitation.findPending({ isSubAccount: true });
          }
        }
      });
  })
  .component('productUsers', new ProductUsersComponent());

export default ProductUsersPageModule;
