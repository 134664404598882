
/* injects from baggage-loader */

'use strict';

export default class ProductController {

	constructor($log, $timeout, $state, $document, $uibModal, Alerts, Parse, Product, ProductDocument, LoadingCover) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {

	}


}
