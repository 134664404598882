
/* injects from baggage-loader */

'use strict';

export default class GenerateButtonCodeModalController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {

		this.link = this.resolve.link;
		this.code = `<a href="${this.link}" style="border-radius: 6px !important; background: #2B3548 !important; color: #EEEEEE !important; font-size: 18px !important; padding: 11px 20px !important; border: 1px solid #40495a !important; text-decoration: none !important; font-family: sans-serif !important;">View Machine Documentation</a>`

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
