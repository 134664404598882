
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProductCreator', function (Product, Parse, $state) {

		this.product;
		this.productImage;
		this.deleteImages = [];
		this.documents = [];
		this.serviceUpdates = [];
		this.removeServiceUpdates = [];


		/**
		 * Create and return a new product. Reset the product creator state.
		 */
		this.newProduct = (data = null) => {
			this.product = new Product(data);
			this.productImage = null;
			this.deleteImages = [];
			this.serviceUpdates = [];
			this.removeServiceUpdates = [];
			return this.product;
		}


		/**
		 * Set the product image
		 */
		this.setProductImage = file => {

			// Sanitize the filename
			let filename = file.name.split('.');
			let ext = filename.pop();
			filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();

			this.productImage = new Parse.File(`${filename}.${ext}`, file, file.type);

		}


		/**
		 * Removes the current product image from the preview window 
		 * and adds the file name to the deletion list processed on "Save changes"
		 */
		this.removeProductImage = () => {

			// Mark the image to be deleted
			this.deleteImages.push(this.productImage.name());

			// Unset the product image and image preview
			this.productImage = null;

			// Remove product image
			this.product.unset('image');

		}


		/**
		 * Save the product
		 */
		this.save = async (data = null) => {

			if (data instanceof Parse.Object) {
				this.product = data;
				data = null;
			}

			// Save the current product image
			if (this.productImage && this.product.isNew()) {
				await this.productImage.save();
				this.product.set('image', this.productImage);
			}

			// Delete any old product images
			if (this.deleteImages.length) {
				await this.Parse.Cloud.run('removeFiles', {
					files: this.deleteImages
				});
			}

			// Save the product
			return this.product.save(data);
		}


		/**
		 * Saves the current product then quits and returns to the product list
		 */
		this.saveAndQuit = async (data = null) => this.save(data)
			.then(product => {
				this.product = null;
				$state.go('products');
			})


	});
}

