
/* injects from baggage-loader */

'use strict';

import LoadingCoverController from './loadingcover.controller';
import LoadingCoverTemplate from './loadingcover.html';

export default class LoadingCoverComponent {
	constructor() {
		this.templateUrl = LoadingCoverTemplate;
		this.controller = LoadingCoverController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			instance: '@',
			fullscreen: '@',
			visible: '@',
			message: '@',
			options: '<'
		};
	}
}
