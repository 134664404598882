
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('AccessToken', function (Parse) {
		let AccessToken = Parse.Object.extend('AccessToken', {
      // Instance methods
    }, {
      // Class methods

      query: function() {
        return (new Parse.Query(this)).find();
      },

      create: function(data) {
        return (new AccessToken(data)).save();
      },

      delete: function(token) {
        return token.destroy();
      }

    });


    // Define object properties
    [
      'name',
      'token'
    ].forEach(function(field) {
      Object.defineProperty(AccessToken.prototype, field, {
        get: function() {
          return this.get(field);
        }
      });
    });

    return AccessToken;
	});
}

