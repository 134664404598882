
/* injects from baggage-loader */

'use strict';

import ProductCreatorComponent from './product-creator.component';
import ProductCreatorInfoComponent from './info/product-creator.info.module';
import ProductCreatorVideosComponent from './videos/product-creator.videos.module';
import ProductCreatorServiceUpdatesComponent from './service-updates/product-creator.service-updates.module';

const ProductCreatorPageModule = angular.module('product-creator-module', [
  'ui.router',
  ProductCreatorInfoComponent.name,
  ProductCreatorVideosComponent.name,
  ProductCreatorServiceUpdatesComponent.name
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product-creator', {
        url: '/product-creator',
        component: 'productCreator',
        // abstract: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          product: function (ProductCreator, $transition$) {
            return ProductCreator.newProduct();
          }
        }
      })
      .state('product-creator.documents', {
        url: '/documents',
        component: 'productDocuments',
        resolve: {
          documents: function() {
            return [];
          }
        }
      })
  })
  .run(function ($transitions, Navigation, $location) {
    // Setup navigation guard
    $transitions.onStart({ to: 'product-creator' }, transition => {
      $location.path('/product-creator/info');
      return transition.router.stateService.target('product-creator.info');
    });
  })
  .component('productCreator', new ProductCreatorComponent());

export default ProductCreatorPageModule;
