
/* injects from baggage-loader */

'use strict';

import ProductGroupOverviewController from './product-group.overview.controller';
import ProductGroupOverviewTemplate from './product-group.overview.html';

export default class ProductGroupOverviewComponent {
		constructor() {
				this.templateUrl = ProductGroupOverviewTemplate;
				this.controller = ProductGroupOverviewController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					group: '<',
					products: '<',
					users: '<'
				};
		}
}
