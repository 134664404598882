
/* injects from baggage-loader */

'use strict';

import ServiceUpdatesController from './service-updates.controller';
import ServiceUpdatesTemplate from './service-updates.html';

export default class ServiceUpdatesComponent {
	constructor() {
		this.templateUrl = ServiceUpdatesTemplate;
		this.controller = ServiceUpdatesController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			serviceUpdates: '<'
		};
	}
}
