
/* injects from baggage-loader */

'use strict';

import ProductCodeComponent from './product.code.component';

const ProductCodePageModule = angular.module('product-code-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product.code', {
        data: {
          title: 'QR Code'
        }, 
        url: '/code',
        component: 'productCode',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('productCode', new ProductCodeComponent());

export default ProductCodePageModule;
