
/* injects from baggage-loader */

'use strict';

import AccountSettingsCompanyController from './account.settings.company.controller';
import AccountSettingsCompanyTemplate from './account.settings.company.html';

export default class AccountSettingsCompanyComponent {
		constructor() {
				this.templateUrl = AccountSettingsCompanyTemplate;
				this.controller = AccountSettingsCompanyController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					company: '<'
				};
		}
}
