
/* injects from baggage-loader */

'use strict';

import AddServiceUpdateModalController from './addserviceupdatemodal.controller';
import AddServiceUpdateModalTemplate from './addserviceupdatemodal.html';

export default class AddServiceUpdateModalComponent {
		constructor() {
				this.templateUrl = AddServiceUpdateModalTemplate;
				this.controller = AddServiceUpdateModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
