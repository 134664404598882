
/* injects from baggage-loader */

'use strict';

import LogoutController from './logout.controller';
import LogoutTemplate from './logout.html';

export default class LogoutComponent {
		constructor() {
				this.templateUrl = LogoutTemplate;
				this.controller = LogoutController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
