
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Utilities', function (FILE_UPLOAD_URL) {
		

		this.uploadFile = (file, document, { type, serviceUpdate } = {}) => {
			this.$log.debug('Upload file', file)
			return this.Upload.upload({
				url: FILE_UPLOAD_URL,
				data: {
					files: [file],
					type,
					documentId: document.id,
					serviceUpdateId: serviceUpdate ? serviceUpdate.id : null
				},
				headers: {
					'X-Parse-Session-Token': this.User.getSessionToken()
				}
			}).then(async resp => {
				this.$log.debug('Success ' + resp.config.data.files[0].name + 'uploaded. Response: ' + resp.data);
				// Uploading complete
				this.file.uploading = false;
				this.file.uploaded = true;
				this.file.animateUpload = false;
				this.$timeout();
				return resp.config.data.files;
			}, error => {
				this.$log.debug('Error status: ' + error.status);
				this.error_msg = 'An error occured uploading the attachment';
				this.file.progressPercentage = 100;
				this.file.animateUpload = false;
				this.$timeout();
				throw this.error_msg;
			}, evt => {
				if (evt.config.data.files) {
					let progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
					this.file.progressPercentage = progressPercentage;
					if (progressPercentage === 100) {
						this.file.uploading = false;
						this.file.animateUpload = true;
						this.$timeout()
					}
				}
			});
		}
	});
}

