
/* injects from baggage-loader */

'use strict';

export default function (app) {
  app.service('ServerEvent', function (Parse, $q, $log) {

    let ServerEvent = Parse.Object.extend('ServerEvent', {
      // Instance methods
    }, {
        // Class methods


        /**
         * Query for server events by type
         */
        query(type, options) {
          let d = $q.defer();
          let query = new Parse.Query(this);
          query.equalTo('type', type);
          query.descending('createdAt');
          for (let key in options) {
            if (typeof options[key].key !== 'undefined' && typeof options[key].value !== 'undefined') {
              query[key](options[key].key, options[key].value);
            } else {
              query[key](options[key]);
            }
          }
          d.resolve(query.find());
          return d.promise;
        },


        /**
         * Return the login event matching the current session
         */
        getCurrentLogin() {
          $log.debug('current session:', Parse.User.current().getSessionToken());
          let d = $q.defer();
          let query = new Parse.Query(this);
          query.equalTo('type', 'login-attempt');
          query.descending('createdAt');
          query.equalTo('session', new Parse.Session({ id: Parse.User.current().getSessionToken() }));
          d.resolve(query.first());
          return d.promise;
        },

        markLoginSuccessful() {
          Parse.Cloud.run('successfulLogin', {}, angular.noop);
        },

        recordEvent(type, data) {
          let d = $q.defer();
          if (typeof type !== 'string') {
            return d.reject('invalid type param');
          }
          let event = new ServerEvent(data);
          event.set('type', type);
          d.resolve(event.save());

          return d.promise
        }

      });


    // // Define object properties
    // [].forEach(function(field) {
    //   Object.defineProperty(ServerEvent.prototype, field, {
    //     get: function() {
    //       return this.get(field);
    //     }
    //   });
    // });

    return ServerEvent;

  });
}

