
/* injects from baggage-loader */

'use strict';

import AssignDocumentGroupModalController from './assigndocumentgroupmodal.controller';
import AssignDocumentGroupModalTemplate from './assigndocumentgroupmodal.html';

export default class AssignDocumentGroupModalComponent {
	constructor() {
		this.templateUrl = AssignDocumentGroupModalTemplate;
		this.controller = AssignDocumentGroupModalController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			resolve: '<',
			close: '&',
			dismiss: '&'
		};
	}
}
