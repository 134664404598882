
/* injects from baggage-loader */

'use strict';

export default class InviteUserModalController {

	constructor($log, $timeout, Parse, Validations) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.Parse = Parse;
		this.Validations = Validations;

		this.inviteMethod = "inviteUser";

		this.user = {
			email: '',
			role: 'manager',
			isSubAccount: false
		}
		this.company = {};
		this.product = {};

		this.inviting_user = false;
		this.error_msg = '';

	}

	$onInit() {

		// Grab roles from resolved context
		this.roles = this.resolve.context.roles;
		this.group = this.resolve.context.group || {};

		this.company = this.resolve.context.company || {};
		this.product = this.resolve.context.product || {};

		this.user.isSubAccount = this.resolve.context.isSubAccount;

		if (this.user.isSubAccount) {
			this.inviteMethod = "inviteUserProduct"
		}

	}

	getInputSubtext() {
		return this.group.id
			? 'We will send an email to this address inviting the person to access this group.'
			: 'We will send an email to this address inviting the person to join the company.'
	}

	ok() {

		this.error_msg = '';

		if (!this.Validations.isEmail(this.user.email)) {
			this.error_msg = 'A valid email address is required.';
			return false;
		}

		this.inviting_user = true;

		this.Parse.Cloud.run(this.inviteMethod, {
			user: this.user,
			groupId: this.group.id,
			productId: this.product.id,
			companyId: this.company.id
		}).then(async result => {

			this.inviting_user = false;
			this.close({
				$value: {
					user: this.user
				}
			});

		})
			.catch(err => {
				this.$log.error(err)
				this.error_msg = err.toString().replace('ParseError: 141', '');
				this.inviting_user = false;
				this.$timeout();
			})

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
