
/* injects from baggage-loader */

'use strict';

import ServiceUpdatesComponent from './service-updates.component';

const ServiceUpdatesPageModule = angular.module('service-updates-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('service-updates', {
        url: '/service-updates',
        component: 'serviceUpdates',
        data: {
          title: 'Bulk Service Updates'
        },
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          serviceUpdates: function (ServiceUpdate, User) {
            return ServiceUpdate.query({
              select: ['text', 'issuedAt', 'name', 'productCount', 'document'],
              equalTo: [{
                key: 'company',
                value: User.current().get('company')
              }, {
                key: 'bulk',
                value: true
              }]
            });
          }
        },
        parentNavBar: [
          {
            title: 'Products',
            icon: 'fa-th-list',
            state: 'products'
          },
          {
            title: 'Service Updates',
            icon: 'fa-wrench',
            state: 'service-updates'
          },
          {
            title: 'Product Groups',
            icon: 'fa-th',
            state: 'product-groups'
          }
        ]
      });
  })
  .component('serviceUpdates', new ServiceUpdatesComponent());

export default ServiceUpdatesPageModule;
