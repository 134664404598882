
/* injects from baggage-loader */

'use strict';

import AccountSettingsUsersComponent from './account.settings.users.component';

const AccountSettingsUsersPageModule = angular.module('account-settings-users-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.users', {
        url: '/users',
        data: {
          title: 'My Account Settings: Users',
          navTitle: 'Users'
        },
        component: 'accountSettingsUsers',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          users: function(User) {
            const company_id = User.current().get('company_id');
            return User.fetchAuthorized({
              company_id
            });
          },
          invitations: function(AccountInvitation) {
            return AccountInvitation.findPending({ isSubAccount: false });
          }
        }
      });
  })
  .component('accountSettingsUsers', new AccountSettingsUsersComponent());

export default AccountSettingsUsersPageModule;
