
/* injects from baggage-loader */

'use strict';

import GenerateButtonCodeModalComponent from './generatebuttoncodemodal.component';
import './generatebuttoncodemodal.scss';

const GenerateButtonCodeModalModule = angular.module('generate-button-code-modal-module', []);

GenerateButtonCodeModalModule
    .component('generateButtonCodeModal', new GenerateButtonCodeModalComponent());

export default GenerateButtonCodeModalModule;

