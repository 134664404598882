
/* injects from baggage-loader */

'use strict';

export default class SignupController {

	constructor($log, $state, User) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.User = User;

		this.creating_account = false;
		this.signup_error = null;

		this.user = {};

	}

	$onInit() {

	}

	async signup() {

		this.signup_error = null;

		if (!this.user.company_name) {
			this.signup_error = 'Missing "company name"';
			return;
		}

		if (!this.user.email) {
			this.signup_error = 'Missing "email"';
			return;
		}
		if (!this.user.password || !this.user.password_verify) {
			this.signup_error = 'Missing "password"';
			return;
		}

		if (this.user.password !== this.user.password_verify) {
			this.signup_error = 'The passwords do not match';
			return;
		}

		this.creating_account = true;

		try {
			let user = await this.User.signup(this.user);
			// this.$state.go('account.dashboard');
			this.$state.go('products')
		} catch (err) {
			this.creating_account = false;
			this.signup_error = err;
		}

	}

}
