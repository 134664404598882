
/* injects from baggage-loader */

'use strict';

import ProductDocumentsController from './product.documents.controller';
import ProductDocumentsTemplate from './product.documents.html';

export default class ProductDocumentsComponent {
		constructor() {
				this.templateUrl = ProductDocumentsTemplate;
				this.controller = ProductDocumentsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					product: '<',
					documents: '<'
				};
		}
}
