
/* injects from baggage-loader */

'use strict';

import ProductActivityComponent from './product.activity.component';

const ProductActivityPageModule = angular.module('product-activity-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product.activity', {
        data: {
          title: 'Activity'
        }, 
        url: '/activity?type?document',
        component: 'productActivity',
        // Do not reload the page when updating query string
        reloadOnSearch: false,
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          events: function (Activity, Product, ProductDocument, $transition$) {
            let product = new Product({ id: $transition$.params('to').id });

            let params = ['type', 'document'];

            let query = {
              equalTo: [{
                key: 'product',
                value: product.toPointer()
              }]
            }

            for (let param of params) {
              if ($transition$.params('to')[param]) {
                let value;

                if (param === 'document') {
                  value = new ProductDocument({ id: $transition$.params('to')[param] });
                } else {
                  value = $transition$.params('to')[param];
                }

                query.equalTo.push({
                  key: param,
                  value
                })

              }
            }

            return Activity.fetchSome(query);
          },
          eventsTotal: function (Activity, Product, ProductDocument, $transition$) {
            let product = new Product({ id: $transition$.params('to').id });

            let params = ['type', 'document'];

            let query = {
              equalTo: [{
                key: 'product',
                value: product.toPointer()
              }]
            }

            for (let param of params) {
              if ($transition$.params('to')[param]) {
                let value;

                if (param === 'document') {
                  value = new ProductDocument({ id: $transition$.params('to')[param] });
                } else {
                  value = $transition$.params('to')[param];
                }

                query.equalTo.push({
                  key: param,
                  value
                })

              }
            }

            return Activity.count(query);
          },
          documents: function(product, ProductDocument) {
            if (!product.get('documents')) {
              return [];
            }
            return product.get('documents').query().select(['label']).find(); 
          }
        }
      });
  })
  .component('productActivity', new ProductActivityComponent());

export default ProductActivityPageModule;
