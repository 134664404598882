
/* injects from baggage-loader */

'use strict';

import AccountSettingsProfileComponent from './account.settings.profile.component';

const AccountSettingsProfilePageModule = angular.module('account-settings-profile-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.profile', {
        data: {
          title: 'My Account Settings: Profile',
          navTitle: 'Profile'
				},
        url: '',
        component: 'accountSettingsProfile',
        // Resolve promises into controller bindings, see component for bindings
        protected: true,
				resolve: {
					user: function (User) {
						return User.current();
					}
				}
      });
  })
  .component('accountSettingsProfile', new AccountSettingsProfileComponent());

export default AccountSettingsProfilePageModule;
