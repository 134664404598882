
/* injects from baggage-loader */

'use strict';

export default class ServiceUpdatesController {

	constructor($log, $timeout, $uibModal, Alerts, User, Product) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.modalService = $uibModal;
		this.alerts = Alerts;
		this.User = User;
		this.productService = Product;

	}

	$onInit() {

	}

	openServiceUpdateModal(serviceUpdate) {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'bulkServiceUpdateModal',
			backdrop: 'static',
			resolve: {
				context: () => ({ serviceUpdate })
			}
		});

		modalInstance.result.then(async res => {

			if (res.isNew) {
				this.serviceUpdates.push(res.serviceUpdate);
			}
			// await res.serviceUpdate.save();
			// if (res.serviceUpdate.id) {
			// 	let index = this.serviceUpdates.findIndex(update => update.id === res.serviceUpdate.id);
			// 	if (index > -1) {
			// 		this.serviceUpdates[index] = res.serviceUpdate;
			// 	}
			// } else {
			// 	this.serviceUpdates.push(res.serviceUpdate);
			// }
			// this.serviceUpdates = this.serviceUpdates.sort((a, b) => b.get('issuedAt') - a.get('issuedAt'));

		}, angular.noop);

	}

	async manageServiceUpdateProducts(serviceUpdate) {

		this.loadingProductsSelector = true;
		let products = await this.productService.query({ limit: 1000, select: ['name', 'model_num'] });
		this.loadingProductsSelector = false;

		// Set selected products
		let _products = await serviceUpdate.relation('products').query().select(['name', 'model_num']).find()
		for (let product of products) {
			let selectedProduct = _products.find(p => p.id === product.id);
			if (selectedProduct) {
				product.selected = true;
			}
		}

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'productSelectorModal',
			size: 'lg',
			resolve: {
				group: () => serviceUpdate,
				products: () => products
			}
		});

		modalInstance.result.then(async result => {
			// this.products = result.selected;
			// this.$timeout();

			// Update selected products 

		}, angular.noop);

	}

	openDeleteServiceUpdateModal(serviceUpdate, index) {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete news update?',
						body: 'Are you sure you want to delete this news update? Do you want to continue?',
						list: [serviceUpdate.issuedAt, serviceUpdate.text],
						confirmBtnText: 'Delete',
						cancelBtnText: 'Cancel'
					};
				}
			}
		});

		modalInstance.result.then(async () => {
			serviceUpdate.deleting = true;
			await serviceUpdate.destroy();
			this.serviceUpdates.splice(index, 1);
			this.alerts.add({ type: 'success', msg: 'News Update deleted successfully' });
		}, angular.noop);

	}

}
