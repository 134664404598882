
/* injects from baggage-loader */

'use strict';

const APP_ID = 'hrsc';
const SERVER_URL = 'https://api.hrsc.us/parse';
const LIVE_QUERY_URL = 'wss://livequery.hrsc.us/parse';

function runBlock($log, $rootScope, $interval, Parse, Snackbar, APP_VERSION) {
	'ngInject';

	$rootScope.appVersion = APP_VERSION;
	$log.log('App version:', APP_VERSION);

	Parse.initialize(APP_ID);
	Parse.serverURL = SERVER_URL;
	Parse.liveQueryServerURL = LIVE_QUERY_URL;
	Parse.Config.get().then(data => $rootScope.appConfig = data.attributes);	

	// Enable maxbox
	// mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

	// Main application state
	$rootScope.state = {
		transitions: {},
		newerAppAvailable: false
	};

	// Site theme
	$rootScope.theme = {
		dark: false
	}

	// Global snackbar dismissal function
	$rootScope.dismissSnackbar = event => {
		if (event.currentTarget) {
			event.currentTarget.remove();
		} else {
			event.target.remove();
		}
	}

	// Check for a newer version of the app every half hour
	$interval(() => {
		Parse.Config.get().then(data => {
			$rootScope.appConfig = data.attributes;
			// $log.debug('Local version:', $rootScope.appVersion);
			// $log.debug('Server version:', $rootScope.appConfig.appVersion);
			// $log.debug('Newer version available:', $rootScope.appConfig.appVersion > $rootScope.appVersion)
			if ($rootScope.appConfig.appVersion > $rootScope.appVersion && !$rootScope.state.newerAppAvailable) {
				Snackbar.open('A newer version of HRSC is available. Refresh your browser to load it.', {
					icon: 'fa-info-circle',
					addClass: 'bg-primary',
					duration: 0
				});
				$rootScope.state.newerAppAvailable = true;
			}
		});	
	}, 60000 * 30)

}

export default runBlock;