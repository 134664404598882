
/* injects from baggage-loader */

'use strict';

export default class ProductCreatorInfoController {


	constructor($log, $timeout, $state, $document, $uibModal, $location, Alerts, Parse, Product, LoadingCover, Upload, User, ProductCreator) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$document = $document;
		this.$uibModal = $uibModal;
		this.$location = $location;
		this.alerts = Alerts;
		this.Parse = Parse;
		this.Product = Product;
		this.LoadingCover = LoadingCover;
		this.Upload = Upload;
		this.User = User;
		this.productCreator = ProductCreator;

		// this.isCreatingProject = $location.path().includes('product-creator');
		this.savingProduct = false;

	}

	$onInit() {

		// this.$log.debug(this.product);
		// this.$log.debug(this.documents);

		// Setup loading covers
		// this.savingProductLoadingCover = this.LoadingCover.getInstance('saving-product');

		// Create an empty variable for the product image
		this.productImage;
		this.productImagePreview;

		// Setup product image preview if existing project
		if (this.product.image) {
			this.productImage = true;
			this.productImagePreview = this.product.image.url();
		}

		// Create the product form
		this.formTemplate = {

			model: this.productCreator.product,

			// Form input groups
			fields: [
				{
					type: 'text',
					label: 'Name',
					model: 'name',
					placeholder: 'Company name',
					autocomplete: '',
					describedby: 'productName',
					autofocus: true
				},
				// {
				// 	type: 'text',
				// 	label: 'Model#',
				// 	model: 'model_num',
				// 	placeholder: 'Model# (Optional)',
				// 	autocomplete: '',
				// 	describedby: 'modelNumber'
				// },
				{
					type: 'email',
					label: 'Contact Email',
					model: 'service_email',
					placeholder: '',
					autocomplete: '',
					describedby: 'Contact Email'
				},
				{
					type: 'text',
					label: 'Website',
					model: 'product_page',
					placeholder: '',
					autocomplete: '',
					describedby: 'productPage'
				}
			]

		};

	}

	$onDestroy() {

		// TODO: Unset "createNewProduct" from localstorage

	}


	/**
	 * Revert changes made to the product object and return to the product object
	 */
	async discardChanges() {

		// Revert any changes
		this.product.revert();

		if (this.product.status === 'draft') {
			await this.product.destroy();
		}

		// Return to products page
		this.$state.go('products');

	}


	/**
	 * Method called when the product image is dropped or selected
	 */
	async processProductImage(file) {

		// Create a new file reader
		let reader = new FileReader();

		reader.addEventListener('load', () => {
			this.productImagePreview = reader.result;
			this.$timeout(() => { /* empty */ }, 100);
		}, false);

		if (file) {
			reader.readAsDataURL(file);

			// Set the product image
			if (typeof this.productImage !== 'undefined') {
				this.productCreator.setProductImage(this.productImage);
			}

		}
	}


	/**
	 * Removes the current product image from the preview window 
	 * and adds the file name to the deletion list processed on "Save changes"
	 */
	removeProductImage() {

		// Unset the product image and image preview
		this.productImage = null;
		this.productImagePreview = '';
		this.productCreator.removeProductImage();

	}


	/**
	 * Save the product
	 */
	async saveProduct(quit = false) {

		// Verify required fields
		if (!this.product.name) {

			// Display error alert
			this.alerts.setLocal({
				type: 'danger',
				msg: `Missing company name, please include the name of the product and try again`
			});

			// Scroll to the top of the page
			this.$document.scrollTopAnimated(0);
			return false;

		}


		// Show "saving-product" loading cover
		// this.savingProductLoadingCover.show('Saving product');
		this.savingProduct = true;

		// Remove draft status, and set active (assigns code in CloudCode)
		// this.product.set('status', 'active');

		// Save the product
		this.productCreator.save()
			.then(product => {
				this.$state.go(quit ? 'products' : 'product-creator.documents');
			})
			.catch(err => {
				this.$log.error(err);
				// Handle error
				this.savingProduct = false;
				this.alerts.setLocal({ type: 'danger', msg: `Failed to create product. Please contact support if the problem persists.` });
			});

	}

}
