
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app
		.factory('ListService', listFactory);

	function listFactory($log, $timeout, ListManager) {
		return function (name) {

			this.items = [];
			this.selected = {};
			this.selected_all = false;
			this.selected_any = false;
			this.selected_count = 0;

			this.columns = [];
			this.current_selection = {};

			// TODO = Unimplemented features
			// selected_one = false;
			// highlighted = '';

			this.selectedArray = () => Object.keys(this.selected).filter(key => this.selected[key]);

			this.getSelectedItems = function () {
				return this.items.filter(function (item) {
					return this.selected[item.id] === true;
				}.bind(this));
			};

			this.selectAll = function () {
				if (this.selected_all) {
					for (let item of this.items) {
						this.selected[item.id] = false;
					}
				} else if (this.selected_any) {
					for (let item of this.items) {
						this.selected[item.id] = true;
					}
				} else {
					for (let item of this.items) {
						this.itemSelected(item.id);
					}
				}
				this.selected_count = this.getSelectedItems().length;
				this.selected_any = this.selected_count > 0;
				this.selected_all = this.selected_count === this.items.length;
			};

			this.selectChanged = function (id) {
				this.itemSelected(id);
			};

			this.itemSelected = function (id) {
				this.selected[id] = this.selected[id] ? false : true;
				this.selected_count = this.getSelectedItems().length;
				this.selected_any = this.selected_count > 0;
				this.selected_all = this.selected_count === this.items.length;
				// $log.debug('any', this.selected_any, 'all', this.selected_all)

				// TODO: Update select all checkbox "indeterminate"
				// let checkbox = document.getElementById("some-checkbox");
				// checkbox.indeterminate = true;
			}

			this.isAllSelected = () => this.selected_count === this.list.length;

			this.select = function (item) {
				this.current_selection = item;
			}

			this.unselect = function (campaign) {
				this.selected[campaign.id] = false;
			};

			this.unselectAll = function () {
				this.selected = {};
			};

			this.remove = function (campaign) {
				this.items = this.items.filter(function (item) {
					return item.id !== campaign;
				});
			};

			this.removeSelected = function () {
				// Remove all selected items from the list
				let selected_items = this.selectedArray();
				this.items = this.items.filter(item => {
					if (selected_items.indexOf(item.id) > -1) {
						delete this.selected[item.id];
					} else {
						return item;
					}
				})
				// Update selected
				$timeout(this.selectChanged.bind(this));
			}

			this.populateDataColumns = function (field) {
				for (let item of this.items) {
					for (let key in item[field]) {
						if (this.columns.indexOf(key) < 0) {
							this.columns.push(key);
						}
					}
				}
				return this.columns;
			}

			// Register with list manager
			if (name) {
				ListManager.register(name, this);
			}

		}
	}
}