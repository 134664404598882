
/* injects from baggage-loader */

'use strict';

export default class ProductInfoController {


	constructor($log, $timeout, $state, $document, $uibModal, $stateParams, Alerts, Parse, Product, ProductDocument, LoadingCover, Navigation, User) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$document = $document;
		this.$uibModal = $uibModal;
		this.$stateParams = $stateParams;
		this.alerts = Alerts;
		this.Parse = Parse;
		this.Product = Product;
		this.ProductDocument = ProductDocument;
		this.LoadingCover = LoadingCover;
		this.navigation = Navigation;
		this.serviceUpdates = [];
		this.removeServiceUpdates = [];
		this.unrelatedServiceUpdates = [];
		this.User = User;

		// Get the user account role
		this.accountRole = this.User.current().account_role;

		this.preventNavigation = false;

	}

	$onInit() {

		// this.$log.debug(this.product);
		// this.$log.debug(this.serviceUpdates);

		// Setup a navigation guard on the project data dirty() function
		this.navigation.setGuard({
			message: 'This product has unsaved changes, are you sure you want to abandon them?',
			fn: () => this.product.dirty() || this.serviceUpdates.filter(su => su.dirty()).length || this.removeServiceUpdates.length || this.preventNavigation
		});

		// Sort service updates
		this.serviceUpdates = this.serviceUpdates.sort((a, b) => b.get('issuedAt') - a.get('issuedAt'))

		// Set the title of the page header
		this.headerTitle = `${this.product.status === 'draft' ? 'Create' : 'Edit'} Product`;

		// Setup loading covers
		this.savingProductLoadingCover = this.LoadingCover.getInstance('saving-product');

		// Hold array of product image names to remove from storage
		this.filesToDelete = [];

		// Create an empty variable for the product image
		this.productImage;
		this.productImagePreview;

		// Setup product image preview if existing project
		if (typeof this.product.image !== 'undefined') {
			this.productImage = true;
			this.productImagePreview = this.product.image.url();
		}

		// Create the product form
		this.formTemplate = {

			model: this.product,

			// Form input groups
			fields: [
				{
					type: 'text',
					label: 'Name',
					model: 'name',
					placeholder: 'Company name',
					autocomplete: '',
					describedby: 'productName',
					autofocus: true
				},
				{
					type: 'email',
					label: 'Contact Email',
					model: 'service_email',
					placeholder: '',
					autocomplete: '',
					describedby: 'Contact Email'
				},
				{
					type: 'text',
					label: 'Website',
					model: 'product_page',
					placeholder: '',
					autocomplete: '',
					describedby: 'productPage'
				}
			]

		};

		// Create the company social media form
		this.companySocialMediaForm = {

			model: this.company,

			// Form input groups
			fields: [
				{
					type: 'text',
					label: 'Facebook',
					model: 'facebook',
					placeholder: '',
					autocomplete: '',
					describedby: 'facebookUrl',
					autofocus: true
				},
				{
					type: 'text',
					label: 'Instagram',
					model: 'instagram',
					placeholder: '',
					autocomplete: '',
					describedby: 'instagramUrl'
				},
				{
					type: 'text',
					label: 'Twitter',
					model: 'twitter',
					placeholder: '',
					autocomplete: '',
					describedby: 'twitterUrl'
				},
				{
					type: 'text',
					label: 'YouTube',
					model: 'youtube',
					placeholder: '',
					autocomplete: '',
					describedby: 'youtubeUrl'
				},
				// {
				// 	type: 'text',
				// 	label: 'Website',
				// 	model: 'product_page',
				// 	placeholder: '',
				// 	autocomplete: '',
				// 	describedby: 'productPage'
				// }
			]

		};

		// Open service updates modal
		if (this.$stateParams.action === 'service-update') {
			this.openIssueServiceUpdateModal();
			// this.$state.go('.', { action: null }, { notify: false })
		}

	}

	$onDestroy() {

		// Release the navigation guard
		this.navigation.releaseGuard();

		// Revert any changes
		this.product.revert();

		// Destory any unrelated service updates
		if (this.unrelatedServiceUpdates.length) {
			this.Parse.Object.destroyAll(this.unrelatedServiceUpdates)
		}

	}

	canUserDelete() {
		return this.accountRole === 'owner' || this.accountRole === 'admin';
	}


	/**
	 * Revert changes made to the product object and return to the product object
	 */
	async discardChanges() {

		// Release the navigation guard
		this.navigation.releaseGuard();

		// Revert any changes
		this.product.revert();

		if (this.product.status === 'draft') {
			await this.product.destroy();
		}

		// Return to products page
		this.$state.go('product.overview', { id: this.product.id });

	}


	/**
	 * Method called when the product image is dropped or selected
	 */
	async processProductImage(file) {

		// Create a new file reader
		let reader = new FileReader();

		reader.addEventListener('load', () => {
			this.productImagePreview = reader.result;
			this.$timeout(() => { /* empty */ }, 100);
		}, false);

		if (file) {
			reader.readAsDataURL(file);

			// Upload the product image
			if (typeof this.productImage !== 'undefined') {

				// Sanitize the filename
				let filename = this.productImage.name.split('.');
				let ext = filename.pop();
				filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
				filename = `${filename}.${ext}`;

				// Create a Parse File and save it
				let productImage = new this.Parse.File(filename, this.productImage, this.productImage.type);

				// Save the product image
				this.$log.debug('Uploading company image:', filename);
				this.productImage = await productImage.save();

				// Set product image and save
				this.product.set('image', this.productImage);
				this.$log.debug('Saving company:', this.product.name);
				await this.product.save();

			}

		}
	}


	/**
	 * Removes the current product image from the preview window 
	 * and adds the file name to the deletion list processed on "Save changes"
	 */
	removeProductImage() {

		// Unset the product image and image preview
		this.productImage = false;
		this.productImagePreview = '';

		// Add image name to array of 
		this.filesToDelete.push(this.product.image.name());

		// Remove product image
		this.product.unset('image');

	}


	openAddVideoModal() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'addProductVideoModal'
		});

		modalInstance.result.then(async res => {

			// Add the video to the products "videos" array
			this.product.videos.push(res.video);

		}, angular.noop);

	}


	async deleteProduct() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete product?',
						body: 'Deleting this product will remove it from our system along with any documents. The QR code associated with this product will still be active and can be linked to a new product if necessary. Do you want to continue?',
						confirmBtnText: 'Delete',
						cancelBtnText: 'Cancel'
					};
				}
			}
		});


		modalInstance.result.then(async () => {

			// Show "saving-product" loading cover
			this.savingProductLoadingCover.show('Deleting product');

			// Destory the product
			await this.product.destroy();
			this.$log.debug('Product deleted successfully');

			// Show loader completion
			this.savingProductLoadingCover.complete('Product removed')
				.then(() => {

					// Return to products page
					this.$state.go('products');

				});

		}, angular.noop);

	}

	removeVideo(video, index) {
		this.product.videos.splice(index, 1);
	}


	openIssueServiceUpdateModal(serviceUpdate) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'addServiceUpdateModal',
			backdrop: 'static',
			resolve: {
				context: () => ({ serviceUpdate })
			}
		});

		modalInstance.result.then(async res => {

			this.$log.debug(res)
			if (res.serviceUpdate.id && !res.serviceUpdate.addRelation) {
				let index = this.serviceUpdates.findIndex(update => update.id === res.serviceUpdate.id);
				if (index > -1) {
					this.serviceUpdates[index] = res.serviceUpdate;
				}
			} else {
				this.serviceUpdates.push(res.serviceUpdate);
				this.unrelatedServiceUpdates.push(res.serviceUpdate)
				this.preventNavigation = true;
				this.$timeout();
			}
			this.serviceUpdates = this.serviceUpdates.sort((a, b) => b.get('issuedAt') - a.get('issuedAt'));
			this.saveProduct(false);
			this.$timeout();
		}, angular.noop);

	}

	async deleteServiceUpdate(update) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete service update?',
						body: 'Deleting this service update will permanently remove it from this product.',
						confirmBtnText: 'Delete',
						cancelBtnText: 'Cancel'
					};
				}
			}
		});


		modalInstance.result.then(async () => {

			let index = this.serviceUpdates.indexOf(update)
			this.serviceUpdates.splice(index, 1);
			this.removeServiceUpdates.push(update);

		}, angular.noop);

	}


	/**
	 * Save the product
	 */
	async saveProduct(exitAfterUpdate = true) {

		// Verify required fields
		if (!this.product.name) {

			// Display error alert
			this.alerts.setLocal({
				type: 'danger',
				msg: `Missing company name, please include the name of the company and try again`
			});

			// Scroll to the top of the page
			this.$document.scrollTopAnimated(0);
			return false;

		}

		// Show "saving-product" loading cover
		this.savingProductLoadingCover.show('Saving company');

		try {

			// Remove deleted service updates
			while (this.removeServiceUpdates.length) {
				let update = this.removeServiceUpdates.shift();
				if (update.id) {
					let relation = this.product.relation('service_updates');
					relation.remove(update)
					if (update.get('bulk')) {
						update.decrement('productCount');
						await update.save();
					} else {
						await update.destroy();
					}
				}
			}

			// Add new service updates
			let relation = this.product.relation('service_updates');
			for (let update of this.serviceUpdates) {
				let acl = this.product.getACL();
				acl.setPublicReadAccess(true)
				if (update.isNew()) {
					update.setACL(acl)
					await update.save();
					relation.add(update);
				} else if (update.dirty()) {
					update.setACL(acl)
					await update.save();
				}
				if (update.addRelation) {
					relation.add(update);
				}
			}
			this.unrelatedServiceUpdates = [];

			// Remove draft status, and set active (assigns code in CloudCode)
			this.product.set('status', 'active');

			// Save the product
			this.$log.debug('Saving company:', this.product.name);

			// Save the company
			if (this.company.dirty()) {
				await this.company.save();
			}

			// Save the product
			await this.product.save();
			this.$log.debug('Company created successfully');

			this.preventNavigation = false;

			// Show loader completion
			this.savingProductLoadingCover.complete('Company saved')
				.then(() => {

					// Release the navigation guard
					this.navigation.releaseGuard();

					// Return to products page
					if (exitAfterUpdate) {
						this.$state.go('product.overview', { id: this.product.id });
					}

				});

		} catch (err) {
			this.$log.error(err);

			// Hide progress loader
			this.savingProductLoadingCover.hide();

			// Set local error
			this.alerts.setLocal({ type: 'danger', msg: `Failed to ${this.product.status === 'draft' ? 'create' : 'edit'} company! Please contact support if the problem persists` });

		}
	}


}
