
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('LoadingCoverInstances', function () {
		this.instances = {};
		return this.instances;
	});
}

