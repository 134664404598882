
/* injects from baggage-loader */

'use strict';

import SplitPdfModalComponent from './splitpdfmodal.component';
import './splitpdfmodal.scss';

const SplitPdfModalModule = angular.module('split-pdf-modal-module', []);

SplitPdfModalModule
    .component('splitPdfModal', new SplitPdfModalComponent());

export default SplitPdfModalModule;

