
/* injects from baggage-loader */

'use strict';

export default class ProductFieldLogsController {

	constructor($log, $rootScope, $state, $timeout, $window, $stateParams, FieldLog, LoadingCover) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.$window = $window;
		this.$stateParams = $stateParams;
		this.FieldLog = FieldLog;
		this.LoadingCover = LoadingCover;

		// Define the loader cover style
		this.loader_style = {
			no_background: true,
			ring_primary_color: '#585858'
		}

		// Update state params
		this.$state.go('.', this.$stateParams, {
			notify: false
		});

		this.fieldLogs = [];
		this.totalItemCount = 0;

		// Set the default pagination settings
		this.pagination = {
			currentPage: 1,
			pageSize: 10,
			maxSize: 10
		};

		this.filter = {};

	}

	async $onInit() {

		// Setup loading cover
		this.loadingRequestsLoadingCover = this.LoadingCover.getInstance('product-fieldlog-loader');

		// Override with config pagination settings if available
		this.pagination = this.config.pagination || this.pagination;

		this.filterQuery = {
			equalTo: [{
				key: 'product',
				value: this.product
			}],
			skip: 0
		}

		// Set the seletec field log from querystring
		if (this.$stateParams.selected) {
			this.filter.selected = this.$stateParams.selected;
			this.selectFieldLog(this.selectedFieldLog);
		}

	}

	updateQuerystring() {
		this.$state.go('.', this.filter, {
			notify: false
		});
	}


	/**
	 * Load the current page of events matching the query
	 */
	loadNextPage() {

		// Update the query
		this.filterQuery.skip = (this.pagination.currentPage * this.pagination.pageSize) - this.pagination.pageSize;
		this.$rootScope.loading_state = true;

		// Update querystring
		this.filter.page = this.pagination.currentPage;
		this.updateQuerystring()

		// Load some more events
		this.FieldLog.query(this.filterQuery).then(results => {

			this.$rootScope.loading_state = false;
			this.fieldLogs = results;

			// Update the view
			this.$timeout(() => {

				// Scroll to the bottom of the navbar
				this.$window.scrollTo({
					top: 148,
					left: 0,
					behavior: 'smooth'
				});

			}, 100);

		});

	}

	async selectFieldLog(fieldLog) {
		// Update querystring
		this.filter.selected = fieldLog.id;
		this.updateQuerystring();

		this.selectedFieldLog = fieldLog;
		this.attachmentsMessage = "Loading ...";
		this.selectedFieldLog.attachments = await fieldLog.relation('documents').query().find();
		if (!this.selectedFieldLog.attachments.length) {
			this.attachmentsMessage = "No attachments";
		}
		this.$log.debug('field log attachments', this.selectedFieldLog.attachments);
		this.$timeout();
	}

	clearSelectedFieldLog() {
		this.selectedFieldLog = null;
		
		// Update querystring
		this.filter.selected = null;
		this.updateQuerystring();
	}

	getAttachmentUrl(file) {
		return file.get('url');
	}

}
