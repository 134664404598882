
/* injects from baggage-loader */

'use strict';

import ProductGroupComponent from './product-group.component';

import ProductGroupOverviewComponent from './overview/product-group.overview.module';
// import ProductGroupProductsComponent from './products/product-group.products.module';
// import ProductGroupUsersComponent from './users/product-group.users.module';
import ProductGroupSettingsComponent from './settings/product-group.settings.module';

const ProductGroupPageModule = angular.module('product-group-module', [
    'ui.router',
    ProductGroupOverviewComponent.name,
    // ProductGroupProductsComponent.name,
    // ProductGroupUsersComponent.name,
    ProductGroupSettingsComponent.name,
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product-group', {
        url: '/groups/{id}',
        component: 'productGroup',
        abstract: true,
        data: {
          title: 'Product Group'
        },
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          group: function(ProductGroup, $transition$) {
            return ProductGroup.get($transition$.params('to').id);
          },
          users: function(group, User) {
            return group.relation('users').query().find();
          }
        },
        parentNavBar: [
          {
            title: 'Summary',
            icon: 'fa-th-large',
            state: 'product-group.overview'
          },
          {
            title: 'Settings',
            icon: 'fa-cog',
            state: 'product-group.settings'
          }
        ]
      });
  })
  .component('productGroup', new ProductGroupComponent());

export default ProductGroupPageModule;
