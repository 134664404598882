
/* injects from baggage-loader */

'use strict';

import SelectUserModalController from './selectusermodal.controller';
import SelectUserModalTemplate from './selectusermodal.html';

export default class SelectUserModalComponent {
		constructor() {
				this.templateUrl = SelectUserModalTemplate;
				this.controller = SelectUserModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
