
/* injects from baggage-loader */

'use strict';

import AccountSettingsPasswordController from './account.settings.password.controller';
import AccountSettingsPasswordTemplate from './account.settings.password.html';

export default class AccountSettingsPasswordComponent {
		constructor() {
				this.templateUrl = AccountSettingsPasswordTemplate;
				this.controller = AccountSettingsPasswordController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					user: '<'
				};
		}
}
