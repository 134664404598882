
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.directive('snackbar', SnackbarDirective);

	function SnackbarDirective($rootScope, $compile, $timeout) {
		'ngInject';

		return {
			restrict: 'AE',
			link: linkFn
		};

		function linkFn(scope, element, attrs) {

			let snackbarContainer = angular.element(element);
			let snackbarBackground = attrs.snackbarBackground || 'bg-primary';
			let snackbarDuration = attrs.snackbarDuration || 3000;
			let snackbarRemoveDelay = attrs.snackbarRemoveDelay || 200;

			$rootScope.$on('createSnackbar', function (event, received) {

				let { content } = received;
				let { addClass, duration, icon } = received.options;

				let containerClass = addClass || snackbarBackground;
				let template = `
				<div class="snackbar snackbar-opened ${containerClass}" ng-click="dismissSnackbar($event)">
					${icon ? `<i class="fa ${icon} mr-2" aria-hidden="true"></i>` : ''}
					<span class="snackbar-content">${content}</span>
				</div>
				`;

				let snackbar = angular.element($compile(template)(scope));
				snackbarContainer.append(snackbar);

				if (duration !== 0) {
					$timeout(() => {
						snackbar.removeClass("snackbar-opened");
						$timeout(() => { snackbar.remove(); }, snackbarRemoveDelay, false);
					}, duration || snackbarDuration, false);
				}

			});

		}

	}
}