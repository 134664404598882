
/* injects from baggage-loader */

'use strict';

import ProductGroupOverviewComponent from './product-group.overview.component';

const ProductGroupOverviewPageModule = angular.module('product-group-overview-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product-group.overview', {
        url: '/overview',
        component: 'productGroupOverview',
        data: {
          title: 'Overview'
        }, 
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          products: function(group, Product) {
            return group.relation('products').query().find()
          },
          users: function(group, User, AccountInvitation) {
            return group.relation('users').query().find()
              .then(async users => {
                let pending = await AccountInvitation.findPending({ group });
                return [...users, ...pending]
              })
          }
        }
      });
  })
  .component('productGroupOverview', new ProductGroupOverviewComponent());

export default ProductGroupOverviewPageModule;
