
/* injects from baggage-loader */

'use strict';

export default class ProductCreatorServiceUpdatesController {

	constructor($log, $uibModal, $state, ProductCreator) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.modalService = $uibModal;
		this.productCreator = ProductCreator;

		this.serviceUpdates = ProductCreator.serviceUpdates;
		this.removeServiceUpdates = ProductCreator.removeServiceUpdates;

		this.savingProduct = false;

	}

	$onInit() {

		// Return to first step if product is new
		if (this.product.isNew()) {
			this.$state.go('product-creator.info');
		}

		// Sort service updates
		this.serviceUpdates = this.serviceUpdates.sort((a, b) => b.get('issuedAt') - a.get('issuedAt'))

	}

	openIssueServiceUpdateModal(serviceUpdate) {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'addServiceUpdateModal',
			backdrop: 'static',
			resolve: {
				context: () => ({ serviceUpdate })
			}
		});

		modalInstance.result.then(async res => {

			if (res.serviceUpdate.id) {
				let index = this.serviceUpdates.findIndex(update => update.id === res.serviceUpdate.id);
				if (index > -1) {
					this.serviceUpdates[index] = res.serviceUpdate;
				} else {
					this.serviceUpdates.push(res.serviceUpdate);
				}
			} else {
				this.serviceUpdates.push(res.serviceUpdate);
			}
			this.serviceUpdates = this.serviceUpdates.sort((a, b) => b.get('issuedAt') - a.get('issuedAt'));

		}, angular.noop);

	}

	async deleteServiceUpdate(update) {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete service update?',
						body: 'Deleting this service update will permanently remove it from this product.',
						confirmBtnText: 'Delete',
						cancelBtnText: 'Cancel'
					};
				}
			}
		});

		modalInstance.result.then(async () => {

			let index = this.serviceUpdates.indexOf(update)
			this.serviceUpdates.splice(index, 1);
			this.removeServiceUpdates.push(update);

		}, angular.noop);

	}


	async saveAndFinish() {

		this.savingProduct = true;

		// Remove deleted service updates
		while (this.removeServiceUpdates.length) {
			let update = this.removeServiceUpdates.shift();
			if (update.id) {
				let relation = this.product.relation('service_updates');
				relation.remove(update)
				await update.destroy();
			}
		}

		// Add new service updates
		let relation = this.product.relation('service_updates');
		for (let update of this.serviceUpdates) {
			let acl = this.product.getACL();
			acl.setPublicReadAccess(true);
			update.setACL(acl)
			await update.save();
			relation.add(update);
			// if (update.isNew()) {
			// 	update.setACL(acl)
			// 	await update.save();
			// 	relation.add(update);
			// } else if (update.dirty()) {
			// 	update.setACL(acl)
			// 	await update.save();
			// }
		}

		this.productCreator.save()
			.then(product => {
				this.$state.go('product.overview', { id: product.id })
			})

	}

}
