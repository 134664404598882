
/* injects from baggage-loader */

'use strict';

import ProductOverviewController from './product.overview.controller';
import ProductOverviewTemplate from './product.overview.html';

export default class ProductOverviewComponent {
		constructor() {
				this.templateUrl = ProductOverviewTemplate;
				this.controller = ProductOverviewController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					product: '<',
					documents: '<',
					events: '<'
				};
		}
}
