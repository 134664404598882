
/* injects from baggage-loader */

'use strict';

import AssignDocumentGroupModalComponent from './assigndocumentgroupmodal.component';
import './assigndocumentgroupmodal.scss';

const AssignDocumentGroupModalModule = angular.module('assign-document-group-modal-module', []);

AssignDocumentGroupModalModule
    .component('assignDocumentGroupModal', new AssignDocumentGroupModalComponent());

export default AssignDocumentGroupModalModule;

