
/* injects from baggage-loader */

'use strict';

export default class LogoutController {

	constructor($log, $location, $state, User) {
		'ngInject';

		this.$log = $log;
		this.$location = $location;
		this.$state = $state;
		this.User = User;

	}

	async $onInit() {

		// Logout the current user
		await this.User.logout();

		// Redirect to login page
		this.$location.url('/login').replace();
		this.$state.go('login');

	}

}
