
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('StaticMap', function (MAPBOX_ACCESS_TOKEN) {

    return {
      generateURL: (o = {}) => `https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/${o.lng || 0},${o.lat || 0},${o.zoom || 9.0},${o.bearing || 0},${o.pitch || 0}/${o.width || 300}x${o.height || 300}?access_token=${MAPBOX_ACCESS_TOKEN}`
    }
		
	});
}

