
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.factory('Snackbar', function ($rootScope) {
		return {
			open: function (content, options = {}) {
				$rootScope.$broadcast('createSnackbar', { content, options });
			}
		}
	});
}

