
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Activity', function (Parse, $log) {

		// Properties
		let schema = [
			'type',
			'code',
			'product',
			'group',
			'document',
			'document_key',
			'service_request',
			'field_log',
			'geoip'
		];

		let Activity = Parse.Object.extend('Activity', {
			// Instance methods

		}, {
				// Class methods


				/**
				 * Grab some recent events for the Activity Feed
				 */
				fetchSome(options = {}) {
					let query = new Parse.Query(this);
					query.limit(10);
					query.descending('createdAt');
					query.include(['product', 'document', 'group', 'field_log']);
					query.select([
						'type',
						'geoip',
						'document.label',
						'product.name',
						'group.name',
						'field_log.name',
						'field_log.company_name'
					]);
					if (options.skip) {
						query.skip(options.skip);
					}
					if (options.equalTo) {
						for (let constraint of options.equalTo) {
							query.equalTo(constraint.key, constraint.value);
						}
					}
					return query.find();
				},


				/**
				 * Count number of matched objects
				 */
				count(options = {}) {
					let query = new Parse.Query(this);
					if (options.equalTo) {
						for (let constraint of options.equalTo) {
							query.equalTo(constraint.key, constraint.value);
						}
					}
					return query.count();
				}


			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(Activity.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return Activity;

	});
}

