
/* injects from baggage-loader */

'use strict';

export default class ActivityFeedItemController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

		/* 
				Activity
					- createdAt
						-> ts
					- product
						-> product_name
					- document
						-> document_label
					- type
						-> event_verb
					- geoip
						-> location (city, region)
							
		*/

	}

	$onInit() {
		if (typeof this.link === 'undefined') {
			this.link = true;
		}
	}

}
