
/* injects from baggage-loader */

'use strict';

import ProductCodeController from './product.code.controller';
import ProductCodeTemplate from './product.code.html';

export default class ProductCodeComponent {
		constructor() {
				this.templateUrl = ProductCodeTemplate;
				this.controller = ProductCodeController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					product: '<'
				};
		}
}
