
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('DocumentRequest', function (Parse) {

		// Properties
		let schema = [
			'status',
			'name',
			'company',
			'email',
			'message',
			'product',
			'document',
			'reason',
			'user',
			'document_key',
			'root_event',
			'event'
		];

		let DocumentRequest = Parse.Object.extend('DocumentRequest', {
			// Instance methods

		}, {
				// Class methods

				countPendingRequests() {
					let query = new Parse.Query('DocumentRequest');
					query.equalTo('status', 'pending');
					return query.count();
				},

				fetchSome(options = {}) {
					let query = new Parse.Query(this);
					query.descending('createdAt');
					query.limit(10);
					query.include([
						'product',
						'document'
					]);
					query.select([
						'status',
						'name',
						'company',
						'product.name',
						'document.label'
					]);
					if (options.limit) {
						query.limit(options.limit);
					}
					if (options.skip) {
						query.skip(options.skip);
					}
					if (options.equalTo) {
						options.equalTo.forEach(constraint => query.equalTo(constraint.key, constraint.value));
					}
					if (options.withCount) {
						query.withCount(true);
					}
					return query.find();
				},

				/**
				 * Count number of matched objects
				 */
				count(options = {}) {
					let query = new Parse.Query(this);
					if (options.equalTo) {
						options.equalTo.forEach(constraint => query.equalTo(constraint.key, constraint.value));
					}
					return query.count();
				},

				get(id) {
					let query = new Parse.Query('DocumentRequest');
					query.include([
						'product',
						'document'
					]);
					return query.get(id);
				}

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(DocumentRequest.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return DocumentRequest;

	});
}

