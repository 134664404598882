
/* injects from baggage-loader */

'use strict';

export default class AddServiceUpdateModalController {

	constructor($log, $timeout, Parse, User, Upload, ProductServiceUpdateDocument, FILE_UPLOAD_URL) {
		'ngInject';

		this.FILE_UPLOAD_URL = FILE_UPLOAD_URL;
		this.$log = $log;
		this.$timeout = $timeout;
		this.Parse = Parse;
		this.User = User;
		this.Upload = Upload;
		this.ProductServiceUpdateDocument = ProductServiceUpdateDocument;
		this.serviceUpdate = {};
		this.existingServiceUpdate;
		this.savingUpdate = false;
		this.loadingAttachment = false;
		this.showUploadControl = false

		this.attachment;
		this.invalidAttachment;
		this.existingAttachment;
		this.removeAttachments = [];

		this.dateOpts = {
			altInput: true,
			altFormat: "F j, Y",
			dateFormat: "Z"
		};

	}

	async $onInit() {

		this.context = this.resolve.context;
		if (this.context.serviceUpdate) {

			// Updating an existing service update
			this.serviceUpdate = this.context.serviceUpdate.toJSON();
			this.existingServiceUpdate = this.context.serviceUpdate;

			if (this.existingServiceUpdate.get('document')) {
				this.loadingAttachment = true;
				this.existingAttachment = await this.existingServiceUpdate.get('document').fetch();
				this.loadingAttachment = false;
				this.$timeout();
			} else {
				this.showUploadControl = true;
			}

			// Filter keys needed to edit
			this.serviceUpdate = Object.keys(this.serviceUpdate).reduce((obj, key) => {
				if (['issuedAt', 'text'].includes(key)) {
					obj[key] = this.serviceUpdate[key];
				}
				return obj;
			}, {});			

		} else {

			// Creating a new service update
			let ServiceUpdate = this.Parse.Object.extend('ProductServiceUpdates');
			this.serviceUpdate = new ServiceUpdate();
			this.showUploadControl = true;

		}

	}

	/**
	 * Called when flatpicker is initialized, sets up the date according to ng-model
	 * @param {Object} fpItem 
	 * @param {Object} options 
	 */
	datePostSetup(fpItem, options = {}) {
		if (typeof options.date === 'object') {
			fpItem.setDate(options.date.iso);
		} else {
			fpItem.setDate(options.date);
		}
	}


	handleAttachment() {
		if (this.attachment) {
			this.$log.debug(this.attachment)
		} else {
			this.$log.error('Invalid file', this.invalidAttachment)
			this.error_msg = 'Invalid file';
			return false;
		}
	}

	removeAttachment() {
		this.removeAttachments.push(this.existingAttachment);
		this.existingAttachment = null;
		this.existingServiceUpdate.unset('document');
		this.showUploadControl = true;
	}

	async processAttachment() {

		this.error_msg = '';

		if (this.attachment) {

			this.attachment.startedUploading = true;
			this.$timeout();

			try {

				// Get a file
				let file = this.attachment;

				// Sanitize the filename
				let filename = file.name.split('.');
				let ext = filename.pop();
				filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
				filename = `${filename}.${ext}`;

				// Create a new "Document"
				let document = new this.ProductServiceUpdateDocument({
					label: filename,
					original_name: file.name,
					size: file.size,
					type: file.type
				});
				await document.save();

				this.attachment.uploaded = false;
				this.attachment.uploading = true;
				this.attachment.animateUpload = false;
				this.attachment.progressPercentage = 0;

				return this.uploadFile(file, document);

			} catch (err) {
				this.$log.error(err)
				this.error_msg = err.toString().replace('ParseError: 141', '').trim();
				this.$timeout();
				return false;
			}

		}

	}

	uploadFile(file, document) {
		this.$log.debug('Upload file', file)
		let update = this.existingServiceUpdate || this.serviceUpdate;
		return this.Upload.upload({
			url: this.FILE_UPLOAD_URL,
			data: {
				files: [file],
				type: 'serviceDocument',
				documentId: document.id,
				serviceUpdateId: update.id
			},
			headers: {
				'X-Parse-Session-Token': this.User.getSessionToken()
			}
		}).then(async resp => {
			this.$log.debug('Success ' + resp.config.data.files[0].name + 'uploaded. Response: ' + resp.data);
			// Uploading complete
			this.attachment.uploading = false;
			this.attachment.uploaded = true;
			this.attachment.animateUpload = false;
			this.$timeout();
			return resp.config.data.files;
		}, error => {
			this.$log.debug('Error status: ' + error.status);
			this.error_msg = 'An error occured uploading the attachment';
			this.attachment.progressPercentage = 100;
			this.attachment.animateUpload = false;
			this.$timeout();
			throw this.error_msg;
		}, evt => {
			if (evt.config.data.files) {
				let progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				this.attachment.progressPercentage = progressPercentage;
				if (progressPercentage === 100) {
					this.attachment.uploading = false;
					this.attachment.animateUpload = true;
					this.$timeout()
				}
			}
		})
		.catch(err => {
			throw err;
		})
	}


	async ok() {

		if (!this.serviceUpdate.issuedAt) {
			this.error_msg = 'Missing "Date Issued"'
			return false;
		}

		if (!this.serviceUpdate.text) {
			this.error_msg = 'Missing "Description"'
			return false;
		}

		this.savingUpdate = true;

		// Update existing service update keys
		if (this.existingServiceUpdate) {
			if (typeof this.serviceUpdate.issuedAt === 'object') {
				this.serviceUpdate.issuedAt = this.serviceUpdate.issuedAt.iso;
			}
			this.existingServiceUpdate.set('issuedAt', new Date(this.serviceUpdate.issuedAt));
			this.existingServiceUpdate.set('text', this.serviceUpdate.text);
		} else {
			this.serviceUpdate.set('issuedAt', new Date(this.serviceUpdate.issuedAt));
			this.serviceUpdate.set('text', this.serviceUpdate.text);
		}

		let update = this.existingServiceUpdate || this.serviceUpdate;

		try {

			// Save the Service Update
			let serviceUpdate = await update.save();
			this.serviceUpdate = serviceUpdate;
			if (!this.existingServiceUpdate) {
				serviceUpdate.addRelation = true;
			}

			// Upload Service Document attachment
			await this.processAttachment();

			// Remove old attachments
			for (let attachment of this.removeAttachments) {
				if (!attachment.isNew()) {
					await attachment.destroy();
				}
			}

			// Fetch the updated service update object
			await serviceUpdate.fetch();

			this.close({
				$value: {
					serviceUpdate
				}
			});

		} catch (err) {
			this.$log.error(err)
		}

	}

	cancel() {
		if (this.existingServiceUpdate) {
			this.existingServiceUpdate.revert()
		}
		this.dismiss({ $value: 'cancel' });
	}

}
