
/* injects from baggage-loader */

'use strict';

export default class ProductActivityController {

	constructor($log, $timeout, $window, $state, $stateParams, $rootScope, Activity, ActivityFeed, LoadingCover) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$window = $window;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$rootScope = $rootScope;
		this.Activity = Activity;
		this.ActivityFeed = ActivityFeed;
		this.LoadingCover = LoadingCover;

		// Define the loader cover style
		this.loader_style = {
			no_background: true,
			ring_primary_color: '#585858'
		}

		// Update state params
		this.$state.go('.', this.$stateParams, {
			notify: false
		});

		this.filter = {
			type: undefined,
			document: undefined
		};
		this.activityFeed = [];

		this.pagination = {
			currentPage: 1,
			pageSize: 10,
			maxSize: 10
		};

		// Build event filters array
		this.eventFilters = [{
			label: 'All events',
			value: ''
		}, {
			label: 'Scans',
			value: 'product-scan'
		}, {
			label: 'Views',
			value: 'product-view'
		}, {
			label: 'Document Downloads',
			value: 'document-dl'
		}, 
		// {
		// 	label: 'Document Requests',
		// 	value: 'document-req'
		// }, {
		// 	label: 'Field Logs',
		// 	value: 'field-log-submitted'
		// }
	];

		// Initialize document filters array
		this.documentFilters = [];

	}

	async $onInit() {

		// Setup loading cover
		this.loadingRequestsLoadingCover = this.LoadingCover.getInstance('product-activity-loader');

		// Parse activity events and fill activity feed
		this.activityFeed = this.events.map(event => this.ActivityFeed.parseEvent(event));

		// Build document filters array
		this.documentFilters = [...this.documentFilters, ...this.documents.map(doc => ({ 
			label: doc.label, 
			value: doc.id 
		}))];

		// Build baseline query for this page
		this.filterQuery = {
			equalTo: [{
				key: 'product',
				value: this.product
			}]
		}

		// Set the document from querystring
		if (this.$stateParams.document) {
			this.filter.document = this.$stateParams.document;
			this.filterQuery.equalTo.push({
				key: 'document',
				value: this.getDocumentPointer(this.filter.document)
			})
		}

		// Set the event type from querystring
		if (this.$stateParams.type) {
			this.filter.type = this.$stateParams.type;
			this.filterQuery.equalTo.push({
				key: 'type',
				value: this.filter.type
			})
		}

	}

	getDocumentPointer(id) {
		return this.documents.find(doc => doc.id === id);
	}

	resetFilters() {

		// Clear filters
		this.filter = {
			type: undefined,
			document: undefined
		};

		// Reload results
		this.filterChanged();

		// Update querystring
		this.$state.go('.', this.filter, {
			notify: false
		});
	}

	filterChanged() {

		// Reset documents filter is looking at "product scan" events
		if (this.filter.type === 'product-scan') {
			this.filter.document = ''
		}

		// Update querystring
		this.$state.go('.', this.filter, {
			notify: false
		});

		this.loadingRequestsLoadingCover.show();

		// Build "equalTo" queries
		let filterQuery = Object.keys(this.filter).reduce((query, key) => {
			if (this.filter[key]) {
				let value = this.filter[key];
				if (key === 'document') {
					value = this.getDocumentPointer(value);
				}
				query.equalTo.push({
					key: key,
					value: value
				});
			}
			return query;
		}, { equalTo: [] });

		// Add the product to the query
		filterQuery.equalTo.push({
			key: 'product',
			value: this.product
		});

		// Set the current query
		this.filterQuery = filterQuery;

		// Load new events
		this.Activity.fetchSome(filterQuery).then(events => {

			// Count total matching events
			this.Activity.count(filterQuery).then(count => {

				// Reset the current page
				this.pagination.currentPage = 1;

				// Assign events and count
				this.eventsTotal = count;
				this.activityFeed = events.map(event => this.ActivityFeed.parseEvent(event));

				// Remove loading cover and update view
				this.loadingRequestsLoadingCover.hide();
				this.$timeout(angular.noop, 10);

			});

		});

	}


	/**
	 * Returns the human readable event type
	 * @param {String} type 
	 */
	getEventType(type) {
		return {
			'product-scan': 'Product Scan',
			'product-view': 'Page View',
			'document-dl': 'Downloaded',
			'document-req': 'Requested',
			'document-req-approved': 'Request Approved',
			'document-req-denied': 'Request Denied',
			'field-log-submitted': 'Field Log Submitted'
		}[type];
	}

	getEventSubtext(event) {
		if (event.type.includes('document-')) {
			return this.getDocumentLabel(event);
		} else if (event.type === 'field-log-submitted') {
			return ` - ${this.getFieldLogAuthorAndCompany(event)}`;
		} else {
			return;
		}
	}

	// TODO: there may be a logic bug here for deleted/missing documents
	getDocumentLabel(event, type) {
		if (event.document && event.document.label) {
			return `"${event.document.label}"`;
		} else if (event.document && !event.document.label) {
			return '[Deleted Document]';
		} else {
			return '';
		}
	}

	getFieldLogAuthorAndCompany(event) {
		const fieldLog = event.get('field_log');
		return `${fieldLog.get('name')} (${fieldLog.get('company_name')})`;
	}


	/**
	 * Load the current page of events matching the query
	 */
	loadNextPage() {

		// Update the query
		this.filterQuery.skip = (this.pagination.currentPage * this.pagination.pageSize) - this.pagination.pageSize;
		this.$rootScope.loading_state = true;

		// Load some more events
		this.Activity.fetchSome(this.filterQuery).then(events => {

			this.$rootScope.loading_state = false;
			this.activityFeed = events.map(event => this.ActivityFeed.parseEvent(event));

			// Update the view
			this.$timeout(() => {

				// Scroll to the bottom of the navbar
				this.$window.scrollTo({
					top: 148,
					left: 0,
					behavior: 'smooth'
				});

			}, 100);

		});

	}

}
