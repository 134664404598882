
/* injects from baggage-loader */

'use strict';

import StaticMapComponent from './staticmap.component';
import './staticmap.scss';

const StaticMapModule = angular.module('static-map-module', []);

StaticMapModule
    .component('staticMap', new StaticMapComponent());

export default StaticMapModule;

