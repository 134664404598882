
/* injects from baggage-loader */

'use strict';

import LoginComponent from './login.component';

const LoginPageModule = angular.module('login-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('login', {
        url: '/login?email',
        component: 'login',
        data: {
          title: 'Login'
        }
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .run(function ($transitions, $location, User) {

    // Handle entrance to login page when user is already logged in
    $transitions.onEnter({ to: 'login' }, transition => {

      if (User.isUserLoggedIn() === true) {
        $location.path('/companies');
        return transition.router.stateService.target('products');
      }

    });

  })
  .component('login', new LoginComponent());

export default LoginPageModule;
