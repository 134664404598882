
/* injects from baggage-loader */

'use strict';

import AccountSettingsNotificationsController from './account.settings.notifications.controller';
import AccountSettingsNotificationsTemplate from './account.settings.notifications.html';

export default class AccountSettingsNotificationsComponent {
		constructor() {
				this.templateUrl = AccountSettingsNotificationsTemplate;
				this.controller = AccountSettingsNotificationsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					documentRequestUsers: '<'
				};
		}
}
