
/* injects from baggage-loader */

'use strict';

export default class ProductsController {

	constructor($log, $timeout, $uibModal, Alerts, User, ListService, ROOT_URL) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.modalService = $uibModal;
		this.alerts = Alerts;
		this.User = User;
		this.rootUrl = ROOT_URL;

		this.products = [];

		// Set list service
		this.list = new ListService('products');
		
		this.searchTerm;

		// Get the user account role
		this.accountRole = this.User.current().account_role;


	}

	$onInit() {
		this.products = this.products.sort(this.compare);
		this.list.items = this.products;
	}

	compare(a, b) {
		// Use toUpperCase() to ignore character casing
		const productA = a.name.toUpperCase();
		const productB = b.name.toUpperCase();

		let comparison = 0;
		if (productA > productB) {
			comparison = 1;
		} else if (productA < productB) {
			comparison = -1;
		}
		return comparison;
	}

	canUserDelete() {
		return this.accountRole === 'owner' || this.accountRole === 'admin';
	}

	previewURL(product) {
		return `https://scan.${this.rootUrl}/company/${product.id}`;
	}

	searchChanged(event) {
		this.$log.debug(this.searchTerm)
	}

	getSelectedProductsMatchingSearch() {
		let items = this.list.getSelectedItems();
		let key = Object.keys(this.searchTerm || {})[0];
		items = items.filter(item => key ? item[key].toLowerCase().includes(this.searchTerm[key]) : true)
		this.$log.debug(items)
	}

	issueBulkServiceUpdate() {

	}
	
	deleteDraft(product, index) {
		this.products.splice(index, 1);
		product.destroy();
	}

	removeProduct(product, index) {

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete company?',
						body: 'Deleting this company will remove it from our system along with any documents. The QR code associated with this company will still be active and can be linked to a new company if necessary. Do you want to continue?',
						list: [product.name],
						confirmBtnText: 'Delete',
						cancelBtnText: 'Cancel'
					};
				}
			}
		});


		modalInstance.result.then(async () => {

			product.deleting = true;

			// Destory the product
			await product.destroy();
			this.products.splice(index, 1);
			this.alerts.add({ type: 'success', msg: 'Company deleted successfully' });

		}, angular.noop);

	}

}
