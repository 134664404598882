
/* injects from baggage-loader */

'use strict';

export default class CreateDocumentGroupModalController {

	constructor($log, ProductDocument) {
		'ngInject';

		this.$log = $log;
		this.ProductDocument = ProductDocument;
		this.newDocumentGroupLabel = '';

	}

	$onInit() {
		this.product = this.resolve.context.product;
	}

	async save() {

		this.creatingGroup = true;

		try {

			let documentGroup = new this.ProductDocument({
				label: this.newDocumentGroupLabel,
				documents: [],
				isGroup: true,
				active: true,
				product: this.product
			});

			await documentGroup.save();

			this.close({
				$value: {
					documentGroup
				}
			});

		} catch (err) {
			this.error_msg = err;
			this.creatingGroup = false;
		}

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
