
/* injects from baggage-loader */

'use strict';

export default class StaticMapController {

	constructor($log, StaticMap) {
		'ngInject';

		this.$log = $log;
		this.StaticMap = StaticMap;

	}

	$onInit() {
		this.mapUrl = this.StaticMap.generateURL(this.options);
	}

}
