
/* injects from baggage-loader */

'use strict';

import ActionsAcceptInviteController from './actions.accept-invite.controller';
import ActionsAcceptInviteTemplate from './actions.accept-invite.html';

export default class ActionsAcceptInviteComponent {
		constructor() {
				this.templateUrl = ActionsAcceptInviteTemplate;
				this.controller = ActionsAcceptInviteController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					invitation: '<',
					company: '<'
				};
		}
}
