
/* injects from baggage-loader */

'use strict';

import constants from './constants';
import ParseService from './services/parse.service';
import AlertsService from './services/alerts.service';
import NavigationService from './services/navigation.service';
import StaticMapService from './services/staticmap.service';
import ErrorHandlerService from './services/errorhandler.service';
import LoadingCoverService from './services/loadingcover.service';
import LoadingCoverInstances from './services/loadingcoverinstances.service';
import ActivityFeedService from './services/activityfeed.service';
import ValidationsService from './services/validations.service';
import ProductCreatorService from './services/productcreator.service';
import ListService from './services/listservice.service';
import ListManagerService from './services/listmanager.service';
import UtilitiesService from './services/utilities.service';
import ParseQueryOverrideSerivce from './services/queryoverride.service';

import ParseServerEventService from './services/parse/serverevent.service';
import ParseUserService from './services/parse/user.service';
import ParseSessionService from './services/parse/session.service';
import ParseAccessTokenService from './services/parse/accesstoken.service';
import ParseProductService from './services/parse/product.service';
import ParseProductDocumentService from './services/parse/productdocument.service';
import ParseCompanyService from './services/parse/company.service';
import ParseActivityService from './services/parse/activity.service';
import ParseCodeService from './services/parse/code.service';
import ParseDocumentRequestService from './services/parse/documentrequest.service';
import ParseAccountInvitationService from './services/parse/accountinvitation.service';
import ParseProductGroupService from './services/parse/productgroup.service';
import ParseServiceUpdateService from './services/parse/serviceupdate.service';
import ParseProductServiceUpdateDocument from './services/parse/productserviceupdatedocument.service';
import ParseProductFieldLog from './services/parse/fieldlog.service';

import FileSizeFilter from './filters/filesize.filter';
import TruncateFilter from './filters/truncate.filter';

import UsStatesFactory from './services/usstates.factory';
import SnackbarFactory from './services/snackbar.service';

import TimeBasedGreetingDirective from './directives/timeBasedGreeting/timebasedgreeting.directive';
import CircularProgressDirective from './directives/circular-progress/circular-progress.directive';
import DatePickerDirective from './directives/date-picker/date-picker.directive';
import SnackBarDirective from './directives/snackbar/snackbar.directive';


const shared = angular.module('core.shared', []);

const directives = [
  TimeBasedGreetingDirective,
  CircularProgressDirective,
  DatePickerDirective,
  SnackBarDirective,
];

const services = [
  ParseService,
  AlertsService,
  NavigationService,
  StaticMapService,
  ErrorHandlerService,
  LoadingCoverService,
  LoadingCoverInstances,
  ActivityFeedService,
  ValidationsService,
  ProductCreatorService,
  ListService,
  ListManagerService,
  UtilitiesService,
  ParseQueryOverrideSerivce,
];

const parseObjects = [
  ParseServerEventService,
  ParseUserService,
  ParseSessionService,
  ParseAccessTokenService,
  ParseProductService,
  ParseProductDocumentService,
  ParseCompanyService,
  ParseActivityService,
  ParseCodeService,
  ParseDocumentRequestService,
  ParseAccountInvitationService,
  ParseProductGroupService,
  ParseServiceUpdateService,
  ParseProductServiceUpdateDocument,
  ParseProductFieldLog,
];

const filters = [
  FileSizeFilter,
  TruncateFilter,
];

// Others
constants(shared);
UsStatesFactory(shared);
SnackbarFactory(shared);


directives.forEach(i => i(shared))
services.forEach(i => i(shared))
parseObjects.forEach(i => i(shared))
filters.forEach(i => i(shared))

export default shared;