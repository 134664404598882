
/* injects from baggage-loader */

'use strict';

import ProductsComponent from './products.component';

const ProductsPageModule = angular.module('products-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('products', {
        url: '/companies',
        component: 'products',
        data: {
          title: 'Company Manager'
        },
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          products: function ($transition$, Product, User) {
            if (User.current().get('isSubAccount')) {
              // TODO: determine how to redirect
              // Product.only()
              //   .then(product => {
              //     $transition$.
              //   })
              throw {
                subAccountRedirect: true
              }
              // return Product.query({ select: ['name', 'updatedAt', 'status'] });
            } else {
              return Product.query({ select: ['name', 'updatedAt', 'status'] });
            }
          }
        },
        parentNavBar: [
          {
            title: 'Companies',
            icon: 'fa-th-list',
            state: 'products'
          },
          // {
          //   title: 'Document Requests',
          //   icon: 'fa-file-text',
          //   state: 'account.document-requests'
          // },
          // {
          //   title: 'Service Updates',
          //   icon: 'fa-wrench',
          //   state: 'service-updates'
          // },
          // {
          //   title: 'Product Groups',
          //   icon: 'fa-th',
          //   state: 'product-groups'
          // }
        ]
      });
  })
  .component('products', new ProductsComponent());

export default ProductsPageModule;
