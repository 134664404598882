
/* injects from baggage-loader */

'use strict';

import CreateDocumentGroupModalController from './createdocumentgroupmodal.controller';
import CreateDocumentGroupModalTemplate from './createdocumentgroupmodal.html';

export default class CreateDocumentGroupModalComponent {
		constructor() {
				this.templateUrl = CreateDocumentGroupModalTemplate;
				this.controller = CreateDocumentGroupModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
