
/* injects from baggage-loader */

'use strict';

const AppTransitions = angular.module('transitions-module', [])
  .run(function ($log, $rootScope, $timeout, $window, $transitions, Alerts, User, Navigation, $location, DEFAULT_PAGE_TITLE, ErrorHandler, ENV) {

    // Transition lifecycle events
    // onBefore, onStart, onExit, onRetain, onEnter, onFinish, onSuccess, onError

    // Set UI Router error handler
    $transitions._router.stateService.defaultErrorHandler(function (error) {
      /* ignore routing errors */
      if (ENV !== 'prod') {
        $log.error(error);
      }

      if (error.detail && error.detail.message) {
        Alerts.add({
          type: 'danger',
          msg: error.detail.message,
          timeout: 10000
        });
      }

      // if (typeof error.detail !== 'undefined') {
      // 	// $log.error('Error code:', error.detail.code);
      // 	// $log.error('Error msg:', error.detail.message);

      // 	// Pass it to the error handler
      // 	ErrorHandler(error.detail);

      // }

      // // Hide loading bar
      // $timeout(() => {
      // 	$rootScope.loading_state = false;
      // }, 250);

    });


    // Called when any state transition is about to begin
    $transitions.onStart({}, transition => {

      // Clear any local alerts
      Alerts.clearLocal();
      Alerts.clearGlobal();

      // Show loading bar
      let current_state = transition._targetState._definition;
      if (current_state.resolvables.length) {
        $rootScope.loading_state = true;
      }

      // Redirect to login page if route is protected and no current user
      if (transition.to().protected === true && User.isUserLoggedIn() === false) {

        // Grab the intended path and save it
        Navigation.setIntendedPath($location.path());

        // Add alert to what happened to global alerts
        Alerts.add({
          type: 'info',
          msg: 'Please log in to perform this action.',
          timeout: 60000
        });

        $location.path('/login');
        return transition.router.stateService.target('login');
      }

      // Listen for notifying transitions
      if (transition.options().notify !== false) {

        // Check if to state is set as previous in state data
        let previous = transition.from().data ? transition.from().data.previous : '';

        // Update state transition flags
        $rootScope.state.transitions.back = transition.to().name === previous;
        $rootScope.state.transitions.toHome = (transition.to().name === 'home');

      }

    });

    // Called when any state transition is entered
    $transitions.onEnter({}, (transition, state) => {
      // Do stuff after the state is entered

      // // Get the current state definition
      // let current = transition._targetState._definition;

      // // Find state with parentNavBar
      // while (!current.self.parentNavBar) {
      // 	if (current.parent) {
      // 		current = current.parent;
      // 	} else {
      // 		break;
      // 	}
      // }

      // if (current.self.parentNavBar) {

      // 	// Setup secondary navigation menu
      // 	Navigation.setupMenu({ routes: current.self.parentNavBar, title: current.self.name });

      // } else {

      // 	// Teardown current menu
      // 	Navigation.teardownMenu();
      // }

    });


    // Called when any state transition succeeds
    $transitions.onSuccess({}, transition => {

      // Update google analytics on page change success
      // $window.ga('send', 'pageview', $location.path());

      // Scroll tot the top
      $window.scrollTo({
        top: 0,
        left: 0,
        // behavior: 'smooth'
      });

      // Get the current state definition
      let current = transition._targetState._definition;

      // Set the current state
      Navigation.setCurrentState(current);

      // Find state with parentNavBar
      while (!current.self.parentNavBar) {
        if (current.parent) {
          current = current.parent;
        } else {
          break;
        }
      }

      if (current.self.parentNavBar) {
        // Setup secondary navigation menu
        Navigation.setupMenu({
          routes: current.self.parentNavBar,
          title: current.self.data ? current.self.data.title : current.self.name
        });
      } else {
        // Teardown current menu
        Navigation.teardownMenu();
      }

      // Check for any global alerts that should be removed after a state change
      if (transition.options().notify !== false) {
        for (let alert of Alerts.collection) {
          if (alert.persistThroughStateChange !== true) {
            Alerts.close(Alerts.collection.indexOf(alert));
          }
        }
      }

      // Hide loading bar
      $timeout(() => {
        $rootScope.loading_state = false;
      }, 250);

      // Update document title
      $timeout(() => {
        if (typeof transition.to().data === 'object' && typeof transition.to().data.title === 'string') {
          // Set document title for the current page
          $window.document.title = `${DEFAULT_PAGE_TITLE} | ${transition.to().data.title}`;
        } else {
          // Set the default page title
          $window.document.title = DEFAULT_PAGE_TITLE;
        }
      }, 250);

    });

    // Called when any state transition encounters an error
    $transitions.onError({}, transition => {

      // Get the error
      let error = transition.error();

      if (typeof error.detail !== 'undefined') {
        // $log.error('Error code:', error.detail.code);
        // $log.error('Error msg:', error.detail.message);

        // Pass it to the error handler
        ErrorHandler(error.detail, transition.to());

      }

      // Hide loading bar
      $timeout(() => {
        $rootScope.loading_state = false;
      }, 250);

    });

  })

export default AppTransitions;
