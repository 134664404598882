
/* injects from baggage-loader */

'use strict';

import headerModule from './components/header/header.module';
import footerModule from './components/footer/footer.module';

import dynamicFormModule from './components/dynamicForm/dynamicform.module';
import staticMapModule from './components/staticMap/staticmap.module';
import LoadingCoverModule from './components/loadingCover/loadingcover.module';
import modalComponentModule from './components/modalComponent/modalcomponent.module';
import activityFeedItemModule from './components/activityFeedItem/activityfeeditem.module';
import DocumentUploadModalModule from './components/documentUploadModal/documentuploadmodal.module';
import AddProductDocumentModalModule from './components/addProductVideoModal/addproductvideomodal.module';
import EditDocumentModalModule from './components/editDocumentModal/editdocumentmodal.module';
import InviteUserModalModule from './components/inviteUserModal/inviteusermodal.module';
import AddServiceUpdateModalModule from './components/addServiceUpdateModal/addserviceupdatemodal.module';
import CreateDocumentGroupModalModule from './components/createDocumentGroupModal/createdocumentgroupmodal.module';
import AssignDocumentGroupModalModule from './components/assignDocumentGroupModal/assigndocumentgroupmodal.module';
import SplitPdfModalModule from './components/splitPdfModal/splitpdfmodal.module';
import ProductSelectorModalModule from './components/productSelectorModal/productselectormodal.module';
import BulkServiceUpdateModalModule from './components/bulkServiceUpdateModal/bulkserviceupdatemodal.module';
import SelectUserModalModule from './components/selectUserModal/selectusermodal.module';
import generateButtonCodeModalModule from './components/generateButtonCodeModal/generatebuttoncodemodal.module';
import AddDocumentByUrlModalModule from './components/addDocumentByUrlModal/adddocumentbyurlmodal.module';

const components = [
	headerModule,
	footerModule,
	dynamicFormModule,
	staticMapModule,
	LoadingCoverModule,
	modalComponentModule,
	activityFeedItemModule,
	DocumentUploadModalModule,
	AddProductDocumentModalModule,
	EditDocumentModalModule,
	InviteUserModalModule,
	AddServiceUpdateModalModule,
	CreateDocumentGroupModalModule,
	AssignDocumentGroupModalModule,
	SplitPdfModalModule,
	ProductSelectorModalModule,
	BulkServiceUpdateModalModule,
	SelectUserModalModule,
	generateButtonCodeModalModule,
	AddDocumentByUrlModalModule,
];

export default angular.module('index.components', components.map(c => c.name));
