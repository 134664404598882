
/* injects from baggage-loader */

'use strict';

export default class HeaderController {
    constructor($log, $rootScope, Alerts, Navigation, User, $state) {
        'ngInject';

        this.$log = $log;
        this.$state = $state;
        this.alerts = Alerts;
        this.navigation = Navigation;
        this.User = User;
        this.$rootScope = $rootScope;

        this.navCollapsed = true;
        this.$rootScope.theme.dark = localStorage.getItem('useDarkTheme');

    }

    $onInit() {
        // this.$log.debug(this.getUser())
    }

    toggleDropdown(status) {
        this.navCollapsed = typeof status !== 'undefined' ? status : !this.navCollapsed;
    }

    getUser() {
        return this.User.current();
    }

    isUserLoggedIn() {
        return this.User.isUserLoggedIn();
    }

    logOut() {
        this.$state.go('logout');
    }

    getHomePath() {
        return this.isUserLoggedIn() ? '/companies' : '/login';
    }

    toggleDarkTheme() {
        this.$rootScope.theme.dark = !this.$rootScope.theme.dark;
        if (this.$rootScope.theme.dark) {
            localStorage.setItem('useDarkTheme', 1);
        } else {
            localStorage.removeItem('useDarkTheme');
        }

    }

    routeIncludes(paths) {
        for (let path of paths) {
            if (this.User.isSubAccount()) {
                if (this.$state.current.name.includes(path)) return true;
            } else {
                if (this.$state.current.url.includes(path)) return true;
            }
        }
        return false;
    }
}