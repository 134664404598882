
/* injects from baggage-loader */

'use strict';

import InviteUserModalComponent from './inviteusermodal.component';
import './inviteusermodal.scss';

const InviteUserModalModule = angular.module('invite-user-modal-module', []);

InviteUserModalModule
    .component('inviteUserModal', new InviteUserModalComponent());

export default InviteUserModalModule;

