
/* injects from baggage-loader */

'use strict';

import InviteUserModalController from './inviteusermodal.controller';
import InviteUserModalTemplate from './inviteusermodal.html';

export default class InviteUserModalComponent {
		constructor() {
				this.templateUrl = InviteUserModalTemplate;
				this.controller = InviteUserModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
