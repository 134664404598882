
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Session', function ($q, Parse) {
		return {

      /**
       * Query for sessions
       */
      query(options) {
        let d = $q.defer();
        let query = new Parse.Query(Parse.Session);
        query.descending('createdAt');
        for (let key in options) {
          if (typeof options[key].key !== 'undefined' && typeof options[key].value !== 'undefined') {
            query[key](options[key].key, options[key].value);
          } else {
            query[key](options[key]);
          }
        }
        d.resolve(query.find());
        return d.promise;
      },


      /**
       * Return the users current session
       */
      getCurrentSession() {
        let d = $q.defer();
        let query = new Parse.Query(Parse.Session);
        query.equalTo('sessionToken', Parse.User.current().getSessionToken());
        d.resolve(query.first());
        return d.promise;
      },

      createToken(data) {
        let d = $q.defer();
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        data.expiresAt = tomorrow;
        let token = new Parse.Session(data);
        d.resolve(token.save());
        return d.promise;
      }

    }
	});
}

