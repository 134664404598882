
/* injects from baggage-loader */

'use strict';

import ProductGroupsComponent from './product-groups.component';

const ProductGroupsPageModule = angular.module('product-groups-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product-groups', {
        url: '/groups',
        component: 'productGroups',
        data: {
          title: 'Product Groups'
        },
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          groups: function(ProductGroup) {
            return ProductGroup.query();
          }
        },
        parentNavBar: [
          {
            title: 'Products',
            icon: 'fa-th-list',
            state: 'products'
          },
          {
            title: 'Service Updates',
            icon: 'fa-wrench',
            state: 'service-updates'
          },
          {
            title: 'Product Groups',
            icon: 'fa-th',
            state: 'product-groups'
          }
        ]
      });
  })
  .component('productGroups', new ProductGroupsComponent());

export default ProductGroupsPageModule;
