
/* injects from baggage-loader */

'use strict';

import CreateDocumentGroupModalComponent from './createdocumentgroupmodal.component';
import './createdocumentgroupmodal.scss';

const CreateDocumentGroupModalModule = angular.module('create-document-group-modal-module', []);

CreateDocumentGroupModalModule
    .component('createDocumentGroupModal', new CreateDocumentGroupModalComponent());

export default CreateDocumentGroupModalModule;

