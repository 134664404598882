
/* injects from baggage-loader */

'use strict';

import AddProductVideoModalComponent from './addproductvideomodal.component';
import './addproductvideomodal.scss';

const AddProductVideoModalModule = angular.module('add-product-video-modal-module', []);

AddProductVideoModalModule
    .component('addProductVideoModal', new AddProductVideoModalComponent());

export default AddProductVideoModalModule;

