
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProductGroup', function (Parse, $q) {
		

		let schema = [
			'name',
			'description',
			'scope',
			'security',
			'online',
		];

		let ProductGroup = Parse.Object.extend('ProductGroup', {
			// Instance methods

		}, {
				// Class methods

				/**
				 * Query for ProductGroups
				 * @param {Object} options 
				 */
				query(options = {}) {
					let d = $q.defer();
					let query = new Parse.Query(this);
					query.descending('createdAt');
					for (let key in options) {
						if (typeof options[key].key !== 'undefined' && typeof options[key].value !== 'undefined') {
							query[key](options[key].key, options[key].value);
						} else {
							query[key](options[key]);
						}
					}
					d.resolve(query.find());
					return d.promise;
				},


				/**
				 * Query for ProductGroups
				 * @param {Object} options 
				 */
				count(options = {}) {
					let d = $q.defer();
					let query = new Parse.Query(this);
					if (options.equalTo) {
						for (let constraint of options.equalTo) {
							query.equalTo(constraint.key, constraint.value);
						}
					}
					d.resolve(query.count());
					return d.promise;
				},


				/**
				 * Retrieve a ProductGroup by ID
				 */
				get(id, options = {}) {
					let query = new Parse.Query(this);
					if (typeof options.include !== 'undefined') {
						query.include(options.include);
					}
					return query.get(id);
				}

			});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(ProductGroup.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProductGroup;

	});

}

