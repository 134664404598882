
/* injects from baggage-loader */

'use strict';

import ProductCreatorVideosComponent from './product-creator.videos.component';

const ProductCreatorVideosPageModule = angular.module('product-creator-videos-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product-creator.videos', {
        url: '/videos',
        component: 'productCreatorVideos',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('productCreatorVideos', new ProductCreatorVideosComponent());

export default ProductCreatorVideosPageModule;
