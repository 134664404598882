
/* injects from baggage-loader */

'use strict';

import ProductOverviewComponent from './product.overview.component';

const ProductOverviewPageModule = angular.module('product-overview-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product.overview', {
        data: {
          title: 'Overview'
        }, 
        url: '/overview',
        component: 'productOverview',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          events: function(Activity, Product, $transition$) {
            let product = new Product({ id: $transition$.params('to').id });
            return Activity.fetchSome({
              equalTo: [{
                key: 'product',
                value: product.toPointer()
              }]
            });
          },
        }
      });
  })
  .component('productOverview', new ProductOverviewComponent());

export default ProductOverviewPageModule;
