
/* injects from baggage-loader */

'use strict';

export default class ProductEditController {

	constructor($log, $timeout, $state, $document, $uibModal, $location, Alerts, Parse, Product, ProductDocument, LoadingCover, Upload, User, ProjectCreator) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$document = $document;
		this.$uibModal = $uibModal;
		this.$location = $location;
		this.alerts = Alerts;
		this.Parse = Parse;
		this.Product = Product;
		this.ProductDocument = ProductDocument;
		this.LoadingCover = LoadingCover;
		this.Upload = Upload;
		this.User = User;
		this.ProjectCreator = ProjectCreator;

		this.isCreatingProject = $location.path().includes('product-creator');

	}

	$onInit() {

		// this.$log.debug(this.product);
		// this.$log.debug(this.documents);

		// Set the title of the page header
		this.headerTitle = `${this.product.status === 'draft' ? 'Create' : 'Edit'} Product`;

		// Setup loading covers
		this.savingProductLoadingCover = this.LoadingCover.getInstance('saving-product');

		// Create array to hold product documents
		this.documents = this.documents || [];

		// Create array to accept files dropped or selected
		this.pendingFiles = [];

		// Create array to hold invalid files
		this.invalidFiles = [];

		// Create array to hold unrelated documents
		this.unsavedDocuments = [];

		// Hold array of documents to delete
		this.removeDocuments = [];

		// Hold array of product image names to remove from storage
		this.filesToDelete = [];

		// Create an empty variable for the product image
		this.productImage;
		this.productImagePreview;

		// Setup product image preview if existing project
		if (typeof this.product.image !== 'undefined') {
			this.productImage = true;
			this.productImagePreview = this.product.image.url();
		}

		// Create the product form
		this.formTemplate = {

			model: this.product,

			// Form input groups
			fields: [
				{
					type: 'text',
					label: 'Name',
					model: 'name',
					placeholder: 'Company name',
					autocomplete: '',
					describedby: 'productName',
					autofocus: true
				},
				{
					type: 'text',
					label: 'Model#',
					model: 'model_num',
					placeholder: 'Model# (Optional)',
					autocomplete: '',
					describedby: 'modelNumber'
				},
				{
					type: 'email',
					label: 'Service Email',
					model: 'service_email',
					placeholder: '',
					autocomplete: '',
					describedby: 'Service Email'
				},
				{
					type: 'text',
					label: 'Product Page',
					model: 'product_page',
					placeholder: '',
					autocomplete: '',
					describedby: 'productPage'
				}
			]

		};

	}

	$onDestroy() {

		// Delete any unlinked documents
		this.Parse.Object.destroyAll(this.unsavedDocuments);
		this.Parse.Object.destroyAll(this.filesToDelete);

	}


	/**
	 * Revert changes made to the product object and return to the product object
	 */
	async discardChanges() {

		// Revert any changes
		this.product.revert();

		if (this.product.status === 'draft') {
			await this.product.destroy();
		}

		// Return to products page
		this.$state.go('products');

	}


	/**
	 * Method called when the product image is dropped or selected
	 */
	async processProductImage(file) {

		// Create a new file reader
		let reader = new FileReader();

		reader.addEventListener('load', () => {
			this.productImagePreview = reader.result;
			this.$timeout(() => { /* empty */ }, 100);
		}, false);

		if (file) {
			reader.readAsDataURL(file);

			// Upload the product image
			if (typeof this.productImage !== 'undefined') {

				// Sanitize the filename
				let filename = this.productImage.name.split('.');
				let ext = filename.pop();
				filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
				filename = `${filename}.${ext}`;

				// Create a Parse File and save it
				let productImage = new this.Parse.File(filename, this.productImage, this.productImage.type);

				// Save the product image
				this.$log.debug('Uploading product image:', filename);
				this.productImage = await productImage.save();

				// Set product image and save
				this.product.set('image', this.productImage);
				this.$log.debug('Saving product:', this.product.name);
				await this.product.save();

			}

		}
	}


	/**
	 * Removes the current product image from the preview window 
	 * and adds the file name to the deletion list processed on "Save changes"
	 */
	removeProductImage() {

		// Unset the product image and image preview
		this.productImage = false;
		this.productImagePreview = '';

		// Add image name to array of 
		this.filesToDelete.push(this.product.image.name());

		// Remove product image
		this.product.unset('image');

	}


	/**
	 * Save the product
	 */
	async saveProduct() {

		// Verify required fields
		if (!this.product.name) {

			// Display error alert
			this.alerts.setLocal({
				type: 'danger',
				msg: `Missing company name, please include the name of the company and try again`
			});

			// Scroll to the top of the page
			this.$document.scrollTopAnimated(0);
			return false;

		}


		// Show "saving-product" loading cover
		this.savingProductLoadingCover.show('Saving company');

		try {

			// Remove draft status, and set active (assigns code in CloudCode)
			this.product.set('status', 'active');

			// Clear unrelated documents
			this.unsavedDocuments = [];

			// Save the product
			this.$log.debug('Saving company:', this.product.name);
			await this.product.save();

			// Delete product image if removed
			if (this.filesToDelete.length > 0) {

				// Delete product image
				await this.Parse.Cloud.run('removeFiles', {
					files: this.filesToDelete
				});

			}

			// Show loader completion
			this.savingProductLoadingCover.complete('Company saved')
				.then(() => {

					// Go to product page
					this.$state.go('product.info', {
						id: this.product.id
					});

				});

		} catch (err) {
			this.$log.error(err);

			// Hide progress loader
			this.savingProductLoadingCover.hide();

			// Set local error
			this.alerts.setLocal({ type: 'danger', msg: `Failed to create company. Please contact support if the problem persists.` });

		}
	}

}
