
/* injects from baggage-loader */

'use strict';

export default class FooterController {
    constructor(APP_VERSION) {
        'ngInject';

        this.version = APP_VERSION || "0.0.0";
        
    }

    $onInit() {
       
    }

    getYear() {
        return (new Date()).getFullYear()
    }
}