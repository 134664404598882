
/* injects from baggage-loader */

'use strict';

export default class SelectUserModalController {

	constructor($log, $timeout, User) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.User = User;

		this.loadingUsers = false;
		this.users = [];
		this.selected = [];

	}

	async $onInit() {

		this.context = this.resolve.context;
		this.message = this.context.message;
		this.users = this.context.users;

		if (!this.users) {
			this.loadingUsers = true;
			this.users = await this.User.fetchAuthorized(this.context.query);
			this.loadingUsers = false;
			this.$timeout();
		}

	}

	selectUser(user) {
		user.selected = !user.selected;
		this.selected = this.users.filter(u => u.selected)
	}

	save() {
		this.close({
			$value: {
				selected: this.selected
			}
		});
	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
