
/* injects from baggage-loader */

'use strict';

import config from './index.config';
import run from './index.run';

import uiRouter from '@uirouter/angularjs';

import coreModule from './core/core.module';
import indexComponents from './index.components';
import indexRoutes from './index.routes';
import indexPages from './index.pages';
import indexTransitions from './index.transitions';

// UI bootstrap 4 modules
import dropdown from 'ui-bootstrap4/src/dropdown';
import alert from 'ui-bootstrap4/src/alert';
import modal from 'ui-bootstrap4/src/modal';
import collapse from 'ui-bootstrap4/src/collapse';
import pagination from 'ui-bootstrap4/src/pagination';
import tooltip from 'ui-bootstrap4/src/tooltip';

// Angular plugin modules
const plugins = [
	uiRouter,
	"ngAnimate",
	"ngCookies",
	"ngTouch",
	"ngSanitize",
	"ngMessages",
	"ngAria",
	"ngResource",
	"oc.lazyLoad",
	"ngFileUpload",
	'yaru22.angular-timeago',
	"duScroll",
	"dndLists",
];

// UI Bootstrap components
const boostrapComponents = [
	dropdown,
	alert,
	modal,
	collapse,
	pagination,
	tooltip,
];

// Setup Angular app
const App = angular.module("hrsc", [
	...plugins,
	...boostrapComponents,
	coreModule.name,
	indexComponents.name,
	indexRoutes.name,
	indexTransitions.name,
	indexPages.name
]);

App
	.config(config)
	.run(run);

export default App;