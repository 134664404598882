
/* injects from baggage-loader */

'use strict';

import BulkServiceUpdateModalComponent from './bulkserviceupdatemodal.component';
import './bulkserviceupdatemodal.scss';

const BulkServiceUpdateModalModule = angular.module('bulk-service-update-modal-module', []);

BulkServiceUpdateModalModule
    .component('bulkServiceUpdateModal', new BulkServiceUpdateModalComponent());

export default BulkServiceUpdateModalModule;

