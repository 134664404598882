
/* injects from baggage-loader */

'use strict';

export default class LoadingCoverController {

	constructor($log, LoadingCover) {
		'ngInject';

		this.$log = $log;
		this.LoadingCover = LoadingCover;

	}

	$onInit() {

		// Register a new instance of LoadingCover
		this.loader = this.LoadingCover.registerInstance(this.instance, {
			fullscreen: this.fullscreen,
			visible: this.visible,
			message: this.message,
			options: this.options
		});

		// this.$log.debug('Loading cover instance registered', this.loader)

	}

}
