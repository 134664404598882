
/* injects from baggage-loader */

'use strict';

export default class AssignDocumentGroupModalController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		this.groupId = '';
		this.movingDocument = false;

	}

	$onInit() {

		this.ctx = this.resolve.context;
		this.product = this.ctx.product;
		this.documentGroups = this.ctx.documentGroups;
		this.currentGroup = this.ctx.currentGroup;

	}

	async save() {

		this.close({
			$value: {
				documentGroup: this.documentGroups.find(group => group.id === this.groupId)
			}
		});

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
