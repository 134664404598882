
/* injects from baggage-loader */

'use strict';

import ProductSelectorModalController from './productselectormodal.controller';
import ProductSelectorModalTemplate from './productselectormodal.html';

export default class ProductSelectorModalComponent {
	constructor() {
		this.templateUrl = ProductSelectorModalTemplate;
		this.controller = ProductSelectorModalController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			resolve: '<',
			close: '&',
			dismiss: '&'
		};
	}
}
