
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('AccountInvitation', function (Parse) {

		// Properties
		let schema = [
			'email',
			'invitationCode',
			'role',
			'accepted',
			'user',
			'isSubAccount'
		];

		let AccountInvitation = Parse.Object.extend('AccountInvitation', {
			// Instance methods

		}, {
			// Class methods

			getByInvitationCode(code) {
				return Parse.Cloud.run('lookupInvitation', {
					invitationCode: code
				});
			},
		
			findPending({ group, isSubAccount = false } = {}) {
				let query = new Parse.Query(this);
				query.notEqualTo('accepted', true)
				if (group) {
					query.equalTo('group', group)
					query.equalTo('role', 'user')
				} else {
					query.notEqualTo('role', 'user')
				}
				if (!isSubAccount) {
					query.notEqualTo('isSubAccount', true)
				} else {
					query.equalTo('isSubAccount', true)
				}
				return query.find();
			},

		});

		// Define object properties
		schema.forEach(function (field) {
			Object.defineProperty(AccountInvitation.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return AccountInvitation;

	});
}

