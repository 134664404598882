
/* injects from baggage-loader */

'use strict';

export default class ProductGroupSettingsController {

	constructor($log, $window, $timeout, ROOT_URL) {
		'ngInject';

		this.$log = $log;
		this.$window = $window;
		this.$timeout = $timeout;
		this.rootUrl = ROOT_URL;

		this.savingSettings = false;

		// Create the product form
		this.formTemplate = {

			// Form input groups
			fields: [
				{
					type: 'text',
					label: 'Name',
					model: 'name',
					placeholder: 'Enter a name',
					autocomplete: '',
					describedby: 'productGroupName',
					autofocus: true
				},
				{
					type: 'textarea',
					label: 'Description',
					model: 'description',
					placeholder: 'Enter a description',
					autocomplete: '',
					describedby: 'productGroupDescription'
				}
			]

		};

	}

	$onInit() {

		// Set dynamic form model
		this.formTemplate.model = this.group;

		this.security = this.group.security === 'public'  || this.group.security === 'open' ? 'Open' : 'Protected';
		this.isPublic = this.group.security === 'public' || this.group.security === 'open';

	}

	getPermalink() {
		return `https://groups.${this.rootUrl}/p/${this.group.id}`;
	}

	getSubtitle() {
		let subtitle = [this.security];
		if (this.isPublic) {
			subtitle.push(`${this.users.length} user${this.users.length === 1 ? '' : 's'}`)
		}
		return subtitle.join(' / ');
	}

	setProductScope(scope) {
		this.group.scope = scope;
	}

	setSecurity(security) {
		this.group.security = security;
	}

	// allPublic() {
	// 	return this.accessGroup.security === 'public' && this.accessGroup.scope === 'all';
	// }

	formValid() {
		return this.group.security &&
			this.group.scope &&
			this.group.name
	}

	async saveSettings() {
		this.savingSettings = true;
		await this.group.save();
		this.$window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
		this.savingSettings = false;
		this.$timeout();
	}

}
