
/* injects from baggage-loader */

'use strict';

import ProductGroupCreatorComponent from './product-group-creator.component';

const ProductGroupCreatorPageModule = angular.module('product-group-creator-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('product-group-creator', {
        url: '/access-group/create',
        component: 'productGroupCreator',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('productGroupCreator', new ProductGroupCreatorComponent());

export default ProductGroupCreatorPageModule;
