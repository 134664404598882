
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsCompanyController {

	constructor($log, $document, Alerts, User, UsStates) {
		'ngInject';

		this.$log = $log;
		this.$document = $document;
		this.User = User;
		this.usStates = UsStates;

		// Title displayed on the simple form template
		this.formTitle = 'Company';

		// Assign alerts service
		this.alerts = Alerts;

	}

	$onInit() {

		this.$log.debug(this.company);

		// Create the profile editing form
		this.formTemplate = {

			model: this.company,

			// Form input groups
			// Currently only simple fields are supported
			// ex. text, email, number
			fields: [
				{
					type: 'text',
					label: 'Name',
					model: 'name',
					placeholder: 'Full name',
					autocomplete: 'name',
					describedby: 'fullName'
				},
				// {
				//   type: 'text',
				//   label: 'Company',
				//   model: 'company',
				//   placeholder: 'Company name',
				//   autocomplete: 'organization',
				//   describedby: 'companyName'
				// },
				{
					type: 'email',
					label: 'Email address',
					model: 'email',
					placeholder: 'Email address',
					autocomplete: 'email',
					describedby: 'emailAddress'
				},
				{
					type: 'text',
					label: 'Role',
					model: 'company_role',
					placeholder: 'Ex. Brand manager',
					autocomplete: '',
					describedby: 'companyRole'
				},
			],

			// Action button at the end of the form
			submit: {
				text: 'Save changes',
				action: () => {
					this.saveChanges();
				},
				disabled: () => !this.company.dirty()
			}

		};

	}


	$onDestroy() {

		// Clear edits
		this.company.revert();

	}

	/**
   * Saves changes to the company object
   */
	saveChanges() {

		// Clear pre-existing alerts
		this.alerts.clearLocal();

		// Save the company!
		this.company.save()
			.then(_company => {
				this.alerts.setLocal({ type: 'success', msg: 'Company updated successfully' });
				this.$document.scrollTopAnimated(0, 500)

				// Set company name on user object
				let user = this.User.current();
				user.company_name = this.company.name;
				user.save();

			})
			.catch(err => {
				this.$log.error(err);
				this.alerts.setLocal({ type: 'danger', msg: 'Failed to update company! Please contact support if the problem persists' });
			})
	}

}
